import { Injectable, Injector } from '@angular/core';
import { HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {

  constructor(private injector: Injector, private router: Router) { }

  intercept(req: HttpRequest<any>, next: HttpHandler) {

    let token = localStorage.getItem('WeCareAuthorization');
    if (token) {
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
    }

    return next.handle(req).pipe(
      tap(
        (event) => {
          if (event instanceof HttpResponse) {
            // Check if the response status indicates token expiration (e.g., 401 Unauthorized)
            if (event.status === 401) {
              // Token expired, redirect to login page
              this.router.navigate(['/login']);
            }
          }
        },
        (error) => {
          // Handle other errors if needed
        }
      )
    );
  }
}
