import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
@Injectable({
  providedIn: 'root'
})
export class CustomerPrescriptionService {


  public Data = new BehaviorSubject(null)

  constructor(private _HttpClient:HttpClient) { }
  Get():Observable<any>{
    return this._HttpClient.get(`${environment.Server_URL}/CustomerPrescription/GetAllForList`);
   }
   GetPaged(data:any):Observable<any>{
    return this._HttpClient.post(`${environment.Server_URL}/CustomerPrescription/Getlist`,data);
   }
   GetCustomerPrescriptionById(id:any):Observable<any>{
    return this._HttpClient.get(`${environment.Server_URL}/CustomerPrescription/GetById?Id=${id}`);
   }
   GetCustomerNormalDrugsById(id:any):Observable<any>{
    return this._HttpClient.get(`${environment.Server_URL}/CustomerPrescriptionDrug/GetById?Id=${id}`);
   }
   GetDrugAlmostFinished(data:any):Observable<any>{
    return this._HttpClient.post(`${environment.Server_URL}/CustomerPrescription/GetAlmostFinishedPrescription`,data);
   }
   UpdateCustomerPrescriptionDrug(data):Observable<any>{
  
    return this._HttpClient.post(`${environment.Server_URL}/CustomerPrescriptionDrug/Update`, data  );
   }
   NotifyCustomerDrugAlmostFinished(id:any):Observable<any>{
    return this._HttpClient.get(`${environment.Server_URL}/CustomerPrescription/NotifyAlmostFinished?customerPrescriptionId=${id}`);
   }
   GetCustomerNormalDrugs(data:any):Observable<any>{
    return this._HttpClient.post(`${environment.Server_URL}/CustomerPrescriptionDrug/GetCustomerNormalDrugs`,data);
   }
   GetExpectedStartDate(data:any):Observable<any>{
    return this._HttpClient.post(`${environment.Server_URL}/CustomerPrescription/GetExpectedStartDate`,data);
   }
   CreateCustomerPrescriptionDrug(data):Observable<any>{
    return this._HttpClient.post(`${environment.Server_URL}/CustomerPrescriptionDrug/Create`, data  );
   }
   DismissNotifyFinished(id:any):Observable<any>{
    return this._HttpClient.get(`${environment.Server_URL}/CustomerPrescription/DismissNotifyFinished?customerPrescriptionId=${id}`);
   }
   RenewPrescription(id:any):Observable<any>{
    return this._HttpClient.get(`${environment.Server_URL}/CustomerPrescription/RenewPrescription?customerPrescriptionId=${id}`);
   }

   Create(data):Observable<any>{
  
   return this._HttpClient.post(`${environment.Server_URL}/CustomerPrescription/Create`, data  );
  }

  Update(data):Observable<any>{
  
   return this._HttpClient.post(`${environment.Server_URL}/CustomerPrescription/Update`, data  );
  }

  Delete(id):Observable<any>{
   
   return this._HttpClient.get(`${environment.Server_URL}/CustomerPrescription/Delete?id=${id}` ); 
 }
 DeleteNormalCustomerPrescription(id):Observable<any>{
   
  return this._HttpClient.get(`${environment.Server_URL}/CustomerPrescription/DeleteNormalCustomerPrescription?id=${id}` ); 
}
}
