import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DistrictService } from 'src/app/shared/API-Service/services/district.service';
import Swal from 'sweetalert2';
import {Districts} from "../../../../shared/Models/districts";
@Component({
  selector: 'app-insert-district',
  templateUrl: './insert-district.component.html',
  styleUrls: ['./insert-district.component.css']
})
export class InsertDistrictComponent implements OnInit {
  id: string;
  update: boolean;
  insertForm: FormGroup;
  District = new Districts();
  Districts:any;
  disaple: boolean;

  constructor(private DistrictService:DistrictService,private _formBuilder: FormBuilder,private router: Router,private route: ActivatedRoute) { 
  }
  ngOnInit(): void {
    if (this.route.snapshot.paramMap.get("id")) {
      console.log(this.route.snapshot.paramMap.get("id"))
      this.id =this.route.snapshot.paramMap.get("id")
      this.DistrictService.GetDistrictById(this.id).subscribe(async (res:any)=>{
        console.log(res.data);
      this.Districts=  await res.data;
        this.initForm(this.Districts);
        
      })
      this.update = true;
    } else {
      this.initForm()
      this.update = false;
    }
  }
  initForm(Districts?: Districts) {
    this.insertForm = this._formBuilder.group({
      title: [Districts?.title || '', Validators.required],
      order: [Districts?.order || '', Validators.required],
    });
  }

  get fc() {
    return this.insertForm.controls;
  }

  // GetDistrict(){
  //   this.DistrictService.Get().subscribe(res=>{
  //     this.Districts=res.data;
  //   })
  // }
  Submit(){
    if (this.insertForm.status == "VALID") {
      this.disaple=true
      if (this.update == true) {
        this.UpdateDistrict();
      } else {
        this.InsertDistrict();
      }
    }
     else {
      this.insertForm.markAllAsTouched();
    }
  

  }
  UpdateDistrict(){
    const data ={
      "id": this.id,
      "title":this.insertForm.get('title').value,
      "order": this.insertForm.get('order').value,
    }

    this.DistrictService.Update(data).subscribe(
      (response) => {
      Swal.fire({
      icon: "success",
      title: "تم التعديل بنجاح",
      showConfirmButton: false,
      timer: 1500,
      });
      this.router.navigateByUrl("content/admin/ListDistricts");
      this.disaple=false

    },
      (err) => {
        this.disaple=false

        Swal.fire({
          icon: "error",
          title:err.error.message
          });
      }
  
      );
  }
  InsertDistrict(){
      const data ={
        "title":this.insertForm.get('title').value,
        "order": this.insertForm.get('order').value,
      }
    this.DistrictService.Create(data).subscribe(
      (response) => {
      Swal.fire({
      icon: "success",
      title: "تم الحفظ بنجاح",
      showConfirmButton: false,
      timer: 1500,
      });
      this.router.navigateByUrl("content/admin/ListDistricts");
      this.disaple=false


    },
      (err) => {
        this.disaple=false

        Swal.fire({
          icon: "error",
          title:err.error.message
          });
      }
            );
   
 
  }

}
