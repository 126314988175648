import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RegisterRequestService } from 'src/app/shared/API-Service/services/register-request.service';
import { DistrictService } from 'src/app/shared/API-Service/services/district.service';
import Swal from 'sweetalert2';
import {RegisterRequest} from "../../../../shared/Models/RegisterRequest";
@Component({
  selector: 'app-insert-register-request',
  templateUrl: './insert-register-request.component.html',
  styleUrls: ['./insert-register-request.component.css']
})
export class InsertRegisterRequestComponent implements OnInit {
  id: string;
  update: boolean;
  insertForm: FormGroup;
  RegisterRequestModal = new RegisterRequest();
  Districts:any[]=[];
  Customer: any;
  Gender=[{
    id:1,
    title:"ذكر",
  },
  {
    id:2,
    title:"انثى",
  }

]
  registerRequestId: any;
  disaple: boolean;

  constructor(private RegisterRequestService:RegisterRequestService,private DistrictService:DistrictService,private _formBuilder: FormBuilder,private router: Router,private route: ActivatedRoute) { 
    this.GetDistrict();
    this.initForm()
  }

  ngOnInit(): void {
    
    if (this.route.snapshot.paramMap.get("id")) {
      this.id =this.route.snapshot.paramMap.get("id")
      this.RegisterRequestService.GetRegisterRequestById(this.id).subscribe(async (res:any)=>{
      this.Customer=  await res.data;
        this.initForm(this.Customer);
        
      })
      this.update = true;
    } else {
      this.initForm()
      this.update = false;
    }
  }

  initForm(RegisterRequest?: RegisterRequest) {
    this.insertForm = this._formBuilder.group({
      name: [RegisterRequest?.name || '', Validators.required],
      mobile: [RegisterRequest?.mobile || '', Validators.required],
      genderId: [RegisterRequest?.genderId || '', Validators.required],
      districtId: [RegisterRequest?.districtId || '', Validators.required],
      pharmacyCode: [RegisterRequest?.pharmacyCode || ''],
    });
  }

  get fc() {
    return this.insertForm.controls;
  }

  GetDistrict(){
    this.DistrictService.Get().subscribe(res=>{
      this.Districts=res.data;
    })
  }
  Submit(){
    if (this.insertForm.status == "VALID") {
      this.disaple=true
      if (this.update == true) {
        this.UpdateRegisterRequest();
      } else {
        this.InsertRegisterRequest();
      }
    }
     else {
      this.insertForm.markAllAsTouched();
    }
  

  }
  UpdateRegisterRequest(){
    const data ={
      "id": this.id,
      "name":this.insertForm.get('name').value,
      "mobile": this.insertForm.get('mobile').value,
      "genderId": Number(this.insertForm.get('genderId').value),
      "districtId": Number(this.insertForm.get('districtId').value),
      "pharmacyCode": this.insertForm.get('pharmacyCode').value,
    }

    this.RegisterRequestService.Update(data).subscribe(
      (response) => {
      Swal.fire({
      icon: "success",
      title: "تم التعديل بنجاح",
      showConfirmButton: false,
      timer: 1500,
      });
      this.router.navigateByUrl("content/admin/ListRegisterRequest");
      this.disaple=false

    },
      (err) => {
        this.disaple=false

        Swal.fire({
          icon: "error",
          title:err.error.message
          });
      }
  
      );
  }
  InsertRegisterRequest(){
      const data ={
        "name":this.insertForm.get('name').value,
        "mobile": this.insertForm.get('mobile').value,
        "genderId": Number(this.insertForm.get('genderId').value),
        "districtId": Number(this.insertForm.get('districtId').value),
        "pharmacyCode": this.insertForm.get('pharmacyCode').value,
      }
    this.RegisterRequestService.Create(data).subscribe(
      (response) => {
      Swal.fire({
      icon: "success",
      title: "تم الحفظ بنجاح",
      showConfirmButton: false,
      timer: 1500,
      });
      this.router.navigateByUrl("content/admin/ListRegisterRequest");
      this.disaple=false

    },
      (err) => {
        this.disaple=false
        Swal.fire({
          icon: "error",
          title:err.error.message
          });
      }
            );
   
 
  }


}
