import { Component, OnInit } from '@angular/core';
import { NgbModal,ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { map } from 'rxjs/internal/operators/map';
import { environment } from 'src/environments/environment.prod';
import Swal from 'sweetalert2';
import {CustomerPrescriptionCommentService} from '../../../shared/API-Service/services/customer-prescription-comment.service'
@Component({
  selector: 'app-comment-modal',
  templateUrl: './comment-modal.component.html',
  styleUrls: ['./comment-modal.component.css']
})
export class CommentModalComponent implements OnInit {
  closeResult = '';
  getDonation:any
  customerPrescriptionId: any;
  AllComments: any[]=[]
  form = {
    comment: "",
    CommentImage:""
  };
  AllCommentsfilter: any;
  currentUser: any;
  Image: any;
  imageLogo: string;
  CommentForm=new FormData();
  Uploaded: boolean;
  viewPdf: boolean;
  Server_URL:any=environment.ServerImages_URL+'/'
  PdfUrl: string;

  constructor(private modalService: NgbModal,private CustomerPrescriptionCommentService :CustomerPrescriptionCommentService) {
    this.currentUser=JSON.parse(localStorage.getItem("WeCareCurrentUser"))
    this.customerPrescriptionId=localStorage.getItem("ModalData")
this.GetcustomerPrescriptionComment(this.customerPrescriptionId);
   }
  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

  
  }
  openPdf(item?:any){
    if(item){
            window.open(item, "_blank");

    }

    
  }
  getLogoUrl(event: any) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.Image = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.imageLogo = reader.result as string;
          this.Uploaded=true
          if(this.Image.type=="application/pdf"){
            this.viewPdf=true;
            const pdfData = reader.result as ArrayBuffer;
            const blob = new Blob([pdfData], { type: 'application/pdf' });
             this.PdfUrl = URL.createObjectURL(blob);
          }
          else{
            this.viewPdf=false;
          }
        // this.logoForm?.append("image", this.Image);
      };
      reader.onloadend = (_event) => {
        event.target.value = null; // Reset the value of the file input element
      };
    }
  }
GetcustomerPrescriptionComment(customerPrescriptionId){
  this.CustomerPrescriptionCommentService.Get(customerPrescriptionId).subscribe((res:any)=>{
    this.AllComments=res.data
   
    this.AllComments=[...this.AllComments];
    this.AllCommentsfilter=res.data
    this.AllComments.forEach(element => {
      if(element.image){
        element.image=environment.ServerImages_URL+'/'+element.image
      }
    });
  })
}
OpenImage(imagesView){
  Swal.fire({
    imageUrl: imagesView,
    imageHeight: 400,
    imageAlt: 'A tall image',
    confirmButtonText:"الغاء"
  })
}
applyFilter(event) {
  this.AllComments = this.AllCommentsfilter.filter((ele: any) => {
    return ele.donationId.toString().toLowerCase().includes(event.toLowerCase())
    || ele.createdByName.toLowerCase().includes(event.toLowerCase())
    || ele.creationDate.toLowerCase().includes(event.toLowerCase())
    || ele.comment.toLowerCase().includes(event.toLowerCase())
  });
}

InsertComment(){
    this.CommentForm.set("CommentImage", this.Image);
  this.CommentForm.set("comment", this.form.comment);
  this.CommentForm.set("CustomerPrescriptionId", this.customerPrescriptionId);
// let data=  {
//     "comment": this.form.comment,
//     "donationId": this.customerPrescriptionId
//   }
  this.CustomerPrescriptionCommentService.Create(this.CommentForm).subscribe(res=>{
    const comment={
      createdByName:this.currentUser.name,
      creationDate:new Date().toISOString(),
      comment:this.CommentForm.get('comment'),
      image:this.Server_URL+ res.data.image
    }
    this.AllComments.unshift(comment);
    this.AllComments=[...this.AllComments];
    this.form.comment="";
    this.Image="";
    this.imageLogo="";
   this.Uploaded=false;
  //  this.GetDonationComment(this.donationId);
  })

}
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  ngOnInit(): void {
  }

}
