import { ThrowStmt } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { StatsDashboardService } from 'src/app/shared/API-Service/services/stats-dashboard.service';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-all-unverified-drugs',
  templateUrl: './all-unverified-drugs.component.html',
  styleUrls: ['./all-unverified-drugs.component.css']
})
export class AllUnverifiedDrugsComponent extends BaseComponent implements OnInit {
  AllUnverifiedDrugsList: any;

  constructor(private StatsDashboardService:StatsDashboardService) { super()}

  ngOnInit(): void {
    this.GetAllUnverifiedDrugs();
  }
  GetAllUnverifiedDrugs(){
    this.StatsDashboardService.GetAllUnverifiedDrugs(this.pager).subscribe(res=>{
  this.AllUnverifiedDrugsList=res.data.items;
  this.totalCount=res.data.totalCount
    })
  }
  pageChanged(event: any){
    this.pageNumber = event.page;// -1 * pageSize;
    this.pager.skipCount = (this.pageNumber - 1) * this.pager.maxResultCount;
    this.GetAllUnverifiedDrugs()
  }
  OpenImage(image)
{
  Swal.fire({
    imageUrl: image,
  });
}
}
