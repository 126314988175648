<!-- footer start-->
<div class="container-fluid">
  <div class="row">
    <div div class="col-md-12 footer-copyright text-center">
      <!-- <p class="mb-0"> Collaborate 2022 All Rights Reserved, Developed & Powered by wecancity.com © </p> -->
    <!-- <p class="mb-0">  {{'© We Care.App 2022 All Rights Reserved Developed & Powered by wecancity.com'|translate}} </p> -->
    <p class="mb-0"  class="copyRight" >   جميع الحقوق محفوظة لصيدليات الندى {{ today | date:'y'}} © 
      <br>
      تم التطوير بواسطة <a href="https://wecancity.com/" class="copyRight" target="_blank">wecancity</a>  </p>
  
   
    <!-- <p class="mb-0">   By <span class="color-primary "></span></p> -->
    </div>
  </div>
</div>

 