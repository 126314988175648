import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
@Injectable({
  providedIn: 'root'
})
export class DrugsService {

  public Data = new BehaviorSubject(null)

  constructor(private _HttpClient:HttpClient) { }
  
  Get(id?:any):Observable<any>{
    if(id){
          return this._HttpClient.get(`${environment.Server_URL}/Drug/Get?drugGroupId=${id}`);

    }
    else{
      return this._HttpClient.get(`${environment.Server_URL}/Drug/Get`);

    }
   }
   GetPaged(data:any):Observable<any>{
    return this._HttpClient.post(`${environment.Server_URL}/Drug/Getlist`,data);
   }
   GetDrugsById(id:any):Observable<any>{
    return this._HttpClient.get(`${environment.Server_URL}/Drug/GetById?Id=${id}`);
   }
   GetDrugsByGroupId(id:any):Observable<any>{
    return this._HttpClient.get(`${environment.Server_URL}/Drug/GetById?Id=${id}`);
   }
   Create(data):Observable<any>{
  
   return this._HttpClient.post(`${environment.Server_URL}/Drug/Create`, data  );
  }
  Verify(data):Observable<any>{
  
    return this._HttpClient.post(`${environment.Server_URL}/Drug/Verify`, data  );
   }
  Update(data):Observable<any>{
  
   return this._HttpClient.post(`${environment.Server_URL}/Drug/Update`, data  );
  }

  Delete(id):Observable<any>{
   
   return this._HttpClient.get(`${environment.Server_URL}/Drug/Delete?id=${id}` ); 
 }
}
