<app-breadcrumb
  [title]="'القائمه الرئيسية للصلاحيات'"
  [active_item]="'Form Wizard'"
  ></app-breadcrumb>

<div class="container-fluid">
  
  <div class="row">
    <div class="col-12">
      <a  routerLink="/content/admin/AddPermissions" class="d-block">
        <button appActionNew class="btn btn-success pull-right" type="button" >
          <span class="m-2">جديد</span> <i class="fa fa-plus"></i>
        </button>
      </a>
      
    </div>
    <div class="col-sm-12 ">
      <div class="card">
        <div class="table-responsive ">
          <table class="table w-100  table-responsive-sm"  >
            <thead>
              <tr class=" text-left" >
                <th scope="col" class="text-light">#</th>
                <th  scope="col" class="text-light">الصلاحيه</th>
                <th scope="col" class="text-light">  </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of response; let i = index; " class="text-left">
           
                <td  class="font-style">{{i+1  }}</td>
                <td  class="font-style">{{ item.name }}</td>
                <td class="font-style  " style="width: 6% ; text-align: end;" >

                  <button
                  appActionDelete
                  class="btn  pull-right"
                  (click)="Delete(item.id)"
                  style="color: red; padding: 7px"

                
                  >
                  <i class="fa fa-trash"></i>

                </button>
                <button
                class="btn  pull-right"
                
                  style="color: rgba(35, 118, 241, 0.667); padding: 7px"
                  (click)="Update(item)"
                >
                  <i class="fa fa-pencil"></i>
                </button>
           
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>


