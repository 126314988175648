import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appNoSpace]',
})
export class NoSpaceDirective {
  constructor(private ngControl: NgControl) {}

  @HostListener('input', ['$event']) onInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    const value = input.value;

    // Remove spaces from the input value
    const newValue = value.replace(/\s/g, '');
    
    // Update the form control value with the value without spaces
    this.ngControl.control?.setValue(newValue);
  }
}
