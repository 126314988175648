import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StatsDashboardService } from 'src/app/shared/API-Service/services/stats-dashboard.service';
import { environment } from 'src/environments/environment.prod';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-customer-medical-measurement-details',
  templateUrl: './customer-medical-measurement-details.component.html',
  styleUrls: ['./customer-medical-measurement-details.component.css']
})
export class CustomerMedicalMeasurementDetailsComponent implements OnInit {
  id: string;
  CustomerMedicalMeasurement: any;
  ImgUrl= environment.ServerImages_URL+'/'

  constructor(private datePipe: DatePipe,private StatsDashboardService:StatsDashboardService,private route: ActivatedRoute) { }

  ngOnInit(): void {
    if (this.route.snapshot.paramMap.get("id")) {
      this.id =this.route.snapshot.paramMap.get("id")
      this.StatsDashboardService.GetCustomerMedicalMeasurementById(this.id).subscribe(res=>{
        this.CustomerMedicalMeasurement=res.data
      })
  }
  }
  formatDateTimeWithAMPM(isoDateTime: string): string {
    const formattedDateTime = this.datePipe.transform(isoDateTime, 'dd/MM/yyyy , h:mm a', 'ar');
    return formattedDateTime || '';
  }
  OpenImage(image)
{
  Swal.fire({
    imageUrl: image,
  });
}
}
