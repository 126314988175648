
<app-breadcrumb class="mt-5" [title]="'تغيير كلمة السر'" [active_item]="'Form Wizard'"></app-breadcrumb>
<div class="text-hieght ">
    <div class="card">
    <div class="card-body">
        <form class="needs-validation" [formGroup]="ChangePassForm" (ngSubmit)="submit()" novalidate
        [ngClass]="{'was-validated': validated}">
    <div class="row">
        <div class="col-12">
            <!-- <label for=""></label> -->
            <div class="" style="display: flex; justify-content: start;"><label for=""> كلمة السر الحاليه</label></div>
            <input type="password" appNoSpace id="validationCustom01" formControlName="oldPassword" required=""  class="form-control" >
        </div>
        <div class="col-12 mt-3">
            <!-- <label for="">رقم السر الجديده</label> -->
           
            <div class="form-group" >
                 <div class="" style="display: flex; justify-content: start;"><label for=""> كلمة السر الجديده</label></div>
                
                <input class="form-control" appNoSpace id="validationCustom01" formControlName="newPassword" required="" type="password"   >
              </div>
        </div>
    </div>

    <div class="d-flex justify-content-end align-items-center">
        <!-- <button (click)="back()" style="    margin-left: 10px;
        padding: 9px 24px;
        font-size: 14px;
        background: red;
        margin-top: 20px;
        color: white;">رجوع</button> -->
        <button style="    color: #fff;
        background: #0e0d64;
        padding: 7px 17px;
        margin-top: 21px;
        font-size: 16px;" type="submit">تعديل</button>
       
    </div>
        </form>
</div> 
</div>
</div>
