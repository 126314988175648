import { AbstractControl, ValidationErrors } from '@angular/forms';

export function AngularValidteFun(control: AbstractControl): ValidationErrors | any {
  // Implement your custom validation logic here
  // Return an object with validation errors if validation fails, otherwise return null

  if(control && control.value !== null){
    var controlVAlue = control.value.replace(/<[^>]*>/g, '');
    var newcontrolVAlue = controlVAlue.replace(/&#160;/g, "");
    newcontrolVAlue=newcontrolVAlue.replace(/\s/g, "");  
    if (newcontrolVAlue.length==0) {
      return true;
    }
    return false;

  }

  
}