
<app-breadcrumb class="mt-5" [title]="'بيانات العميل وعمليه الادوية العادية'" [active_item]="'Form Wizard'"></app-breadcrumb>

<div class="container-fluid">
<div class="row mt-5">
   <div class="col-6">
       <div class="card h-100">
           <div class="card-header"><h5 class="f-w-900">بيانات العميل</h5></div>
           <div class="card-body">
            <div class="mb-5">
                <div class="mb-5">
                    <h5 class="f-w-900 mb-3">كود العميل</h5>
                    <h6 class="f-w-900">{{CustomerPrescriptionDetails.customerCode}}</h6>
                </div>
                <h5 class="f-w-900 mb-3">الاسم</h5>
                <h6 class="f-w-900"> {{CustomerPrescriptionDetails.customerName}}</h6>

            </div>
            <div class="mb-5">
                <h5 class="f-w-900 mb-3">النوع</h5>
                <h6 class="f-w-900">{{CustomerPrescriptionDetails.customerGender}}</h6>
            </div>
            <div class="mb-5">
                <h5  class="f-w-900 mb-3">الموبايل</h5>
                <h6 class="f-w-900">{{CustomerPrescriptionDetails.customerMobile}}</h6>

            </div>
            <div class="mb-5">
                <h5 class="f-w-900 mb-3">العنوان</h5>
                <h6 class="f-w-900">{{CustomerPrescriptionDetails.customerAddress}}</h6>

            </div>
        </div>
       </div>
   </div>
   <div class="col-6">
       <div class="card h-100">
           <div class="card-header"><h5 class="f-w-900">بيانات عمليه الادوية العادية</h5></div>
           <div class="card-body">
            <!-- <div class="mb-5">
                <h5 class="f-w-900 mb-3">الاسم</h5>
                <h6 class="f-w-900"> {{CustomerPrescriptionDetails.customerPrescriptionName}}</h6>
            </div> -->
            <div class="mb-5">
                <h5  class="f-w-900 mb-3">اسم الدواء</h5>
                <h6 class="f-w-900">{{CustomerPrescriptionDetails.drugName}}</h6>
            </div>
            <div class="mb-5">
                <h5  class="f-w-900 mb-3">كمية الجرعة</h5>
                <h6 class="f-w-900">{{CustomerPrescriptionDetails.doseQuantityTitle}}</h6>
            </div>
            <div class="mb-5">
                <h5 class="f-w-900 mb-3">الدواء كل</h5>
                <h6 class="f-w-900">{{CustomerPrescriptionDetails.count+' '+CustomerPrescriptionDetails.doseTimeTitle}}</h6>
            </div>
            <div class="mb-5">
                <h5 class="f-w-900 mb-3">مدة الجرعة</h5>
                <h6 class="f-w-900">{{CustomerPrescriptionDetails.days+' '+'يوم'}}</h6>
            </div>
            <div class="mb-5">
                <h5 class="f-w-900 mb-3">من تاريخ</h5>
                 <h6 class="f-w-900 dateFormat" >{{formatDateTimeWithAMPM(CustomerPrescriptionDetails.startDate)}}</h6>
            </div>
            <div class="mb-5">
                <h5 class="f-w-900 mb-3">الى تاريخ</h5>
                <h6 class="f-w-900 dateFormat" >{{formatDateTimeWithAMPM(CustomerPrescriptionDetails.endDate)}}</h6>
            </div>
            <div class="mb-5">
                <h5 class="f-w-900 mb-3">تعليق الصيدلي</h5>
                <h6 class="f-w-900">{{CustomerPrescriptionDetails.pharmacistComment}}</h6>
            </div>
           </div>
       </div>
   </div>

</div>
</div>