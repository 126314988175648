
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <form [formGroup]="insertForm" (ngSubmit)="Submit()">
            <div class="card">
                <div class="card-header" style="padding-bottom: 5px !important">
                    <div >
                        <h5 *ngIf="!update" class="pb-2">اضافة روشته للعميل</h5>
                        <h5 *ngIf="update" class="pb-2"> تعديل روشته للعميل</h5>
                       <span> بالرجاء ملئ جميع الحقول </span>
                    </div>
                    <ng-template >
                        
                    </ng-template>
                </div>
           
                <div class="card-body">
                    <div class="row m-0 mb-2">
                        <div class="col-md-4">
                            <label for=""> الاسم
                            </label>
                              <div class="form-group">
                                 <input type="text" formControlName="title" class="form-control" 
                                 [tooltip]="fc.title.errors?.required ? ' الاسم مطلوب' : null" required
                                 [isDisabled]="!((fc.title.errors?.required && fc.title.touched))"
                                 containerClass="" [class.is-valid]="fc.title.valid && (fc.title.touched)"
                                 [class.is-invalid]="fc.title.invalid && (fc.title.touched)"
                                 >
                              </div>
                          </div>
                          <div class="col-md-4 text-center">
                              <div class="form-group">
                                <label >العميل </label>
                             <h5 >{{CustomerName}}</h5>
                              </div>
                          </div>
                          
                          <div class="col-md-4">
                            <label for=""> عدد ايام التكرار
                            </label>
                              <div class="form-group">
                                 <input type="number" [min]="1" onlyNumbers formControlName="repDays" class="form-control"  [(ngModel)]="PresciptionForm.repDays"
                                 [tooltip]="fc.repDays.errors?.required ? '  مطلوب' : null" required
                                 [isDisabled]="!((fc.repDays.errors?.required && fc.repDays.touched))"
                                 containerClass="" [class.is-valid]="fc.repDays.valid && (fc.repDays.touched)"
                                 [class.is-invalid]="fc.repDays.invalid && (fc.repDays.touched)"
                                 >
                              </div>
                          </div>
                        <!-- <div class="col-12 col-md-12">
                            <div *ngIf="update; else elseBlock">
                                <button class="btn btn-primary pull-right" type="submit" >
                                  تعديل
                                </button>
                              </div>
                  
                              <ng-template #elseBlock>
                                <button class="btn btn-primary pull-right" type="submit">
                                  إضافة
                                </button>
                              </ng-template>
                </div> -->
                </div>
    </div>
</div>
<div class="card">
    <div class="card-body">
        <div class="row m-0 mb-2">
            <div class="col-md-6">
                <div class="form-group">
                  <label>أختر الدواء </label>
                  <ng-select class="js-example-basic-single" formControlName="drugId"  [(ngModel)]="PresciptionForm.drugId"
                    aria-label="Default select example"  
                    [items]="Drugs" bindLabel="title" bindValue="id">
                  </ng-select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>أختر كمية الجرعه </label>
                  <ng-select class="js-example-basic-single"  formControlName="doseQuantityId"  [(ngModel)]="PresciptionForm.doseQuantityId"
                    aria-label="Default select example" 
                    [items]="DoseQuantity" bindLabel="title" bindValue="id">
                  </ng-select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>أختر وقت الجرعه </label>
                  <ng-select class="js-example-basic-single" formControlName="doseTimeId"  [(ngModel)]="PresciptionForm.doseTimeId"
                    aria-label="Default select example" 
                    [items]="DoseTimes" bindLabel="title" bindValue="id">
                  </ng-select>
                </div>
              </div>
              <div class="col-md-6">
                <label for=""> عدد الساعات \ الايام
                </label>
                  <div class="form-group">
                     <input type="number" [min]="1" onlyNumbers class="form-control"  formControlName="count"  [(ngModel)]="PresciptionForm.count"
                     >

                  </div>
              </div>
              <div class="col-md-6">
                <label for=""> بداية جدول الادوية
                </label>
                  <div class="form-group">
                     <input type="datetime-local"  [min]="getMinDate()" class="form-control"  formControlName="startDate"   [(ngModel)]="PresciptionForm.startDate"
                     >

                  </div>
              </div>
          
              <!-- <div class="col-md-6">
                <label for=""> الايام
                </label>
                  <div class="form-group">
                     <input type="number" [min]="1" onlyNumbers class="form-control"   formControlName="days"  [(ngModel)]="PresciptionForm.days"
                     >

                  </div>
              </div> -->
              <!-- <div class="col-md-6">
                <label for=""> عدد الدواء بالواحده
                </label>
                  <div class="form-group">
                     <input type="text"  class="form-control"   formControlName="drugItemCount"  [(ngModel)]="PresciptionForm.drugItemCount"

                     >

                  </div>
              </div>
              <div class="col-md-6">
                <label for=""> الكمية للواحده
                </label>
                  <div class="form-group">
                     <input type="text"  class="form-control"   formControlName="quantityPerItem"  [(ngModel)]="PresciptionForm.quantityPerItem"

                     >

                  </div>
              </div> -->
              <div class="col-md-6">
                <label for=""> تعليق الصيدلي
                </label>
                  <div class="form-group">
                     <textarea type="text" class="form-control"    formControlName="pharmacistComment"   [(ngModel)]="PresciptionForm.pharmacistComment"
                     >
                     </textarea>
                  </div>
              </div>
              <div class="col-md-6">
                <label for=""> اشعارات
                </label>
                  <div class="form-group">
                     <input type="checkbox" [checked]="NotificationState" class="form-control"     formControlName="notification"  (change)="checknotification($event)"   [(ngModel)]="NotificationState"
                     >

                  </div>
              </div>
              <div class="col-md-12 mb-2">
              <button *ngIf="!UpdateTable" class="btn btn-success pull-left" (click)="Add()" type="button"> إضافة</button>
              <button *ngIf="UpdateTable" class="btn btn-success pull-left" (click)="Edit()" type="button"> تعديل</button>

              </div>
              <div class="col-sm-12 col-lg-12 col-xl-12">
                <div class="table-responsive">
                    <table class="table table-responsive-sm text-center">
                        <thead>
                            <tr class=" ">
                                <th scope="col"></th>
                                <th scope="col">اسم الدواء</th>
                                <th scope="col">وقت الجرعه</th>
                                <th scope="col">عدد الأيام/الساعات</th>
                                <th scope="col">الجرعه</th>
                                <th scope="col">البدايه</th>
                                <!-- <th scope="col">الايام</th> -->
                                <th scope="col">تعليق الصيدلي</th>
                                <th scope="col">التنبيهات</th>

                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let view of customerPrescriptionDrugsForTable;let x = index" class=" ">
                                <td scope="row" class="p-2">{{x+1}}</td>
                                <td class="p-2">{{view.drugName || view.drugTitle}}</td>
                                <td class="p-2">{{view.doseTimeName|| view.doseTimeTitle}}</td>
                                <td class="p-2">{{view.count}}</td>
                                <td class="p-2">{{view.doseQuantityName || view.doseQuantityTitle}}</td>
                                <td class="p-2">{{view.startDate}}</td>   
                                <!-- <td class="p-2">{{view.days}}</td>    -->
                                <td class="p-2">{{view.pharmacistComment}}</td>
                                <td class="p-2">{{view.notification}}</td>   
                                <td class="p-2">
                                  <button class="btn pull-right" type="button" 
                                  style="color: rgba(35, 118, 241, 0.667); padding: 7px"
                                  (click)="updateFromTable(view,x)">
                                  <i class="fa fa-pencil"></i>
                              </button>
                                     <button type="button" class="btn  pull-right"  style="color: red; padding: 7px"
                                     (click)="deleteFromTable(view,x)">
                                      <i class="fa fa-trash"></i>
                                         </button>
                                </td>   

                                </tr>
                        </tbody>
                    </table>
                 
                </div>
            </div>
            <div class="col-12 col-md-12 mt-3">
                <div *ngIf="update; else elseBlock">
                    <button [disabled]="disaple" class="btn btn-success pull-right btn-block btn-lg" type="submit" >
                      تعديل
                    </button>
                  </div>
      
                  <ng-template #elseBlock>
                    <button [disabled]="disaple" class="btn btn-success pull-right btn-block btn-lg" type="submit">
                      حفظ
                    </button>
                  </ng-template>
    </div>
    </div>
</div>
</div>
</form>
        </div>
    </div>
</div>