import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { VideoAlbumService } from 'src/app/shared/API-Service/services/video-album.service';
import Swal from 'sweetalert2';
import {Drugs} from "../../../../shared/Models/drugs";
import { DrugsService } from '../../../../shared/API-Service/services/drugs.service';
import { DrugGroupsService } from '../../../../shared/API-Service/services/drug-groups.service';
import { environment } from 'src/environments/environment.prod';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-insert-drug',
  templateUrl: './insert-drug.component.html',
  styleUrls: ['./insert-drug.component.css']
})
export class InsertDrugComponent implements OnInit {
  id: string;
  update: boolean;
  insertForm: FormGroup;
  DrugsModal = new Drugs();
  VideoAlbums:any;
  dropdownSettings: any = {};
  Drug: any;
  DrugGroups: any;
  selectedItems:any
  DrugGroupIds: any[]=[];
  DrugGroupsForEdit: any[]=[];
  DrugForm=new FormData();
  Image: File;
  imageLogo: any;
  drugid: string;
  disaple: boolean;
  config:AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    enableToolbar: true,
      showToolbar: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Cairo',
    fonts: [
      {class: 'cairo', name: 'Cairo'},
      {class: 'arial', name: 'Arial'},
      {class: 'times-new-roman', name: 'Times New Roman'},
      {class: 'calibri', name: 'Calibri'},
      {class: 'comic-sans-ms', name: 'Comic Sans MS'}
    ],
    toolbarHiddenButtons: [
      // ['bold']
      // ['toggleEditorMode']
      ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  }
  constructor(private DrugGroupsService : DrugGroupsService,private DrugsService :DrugsService,private VideoAlbumService:VideoAlbumService,private _formBuilder: FormBuilder,private router: Router,private route: ActivatedRoute) { 
  }

  
  ngOnInit(): void {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'title',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
     
  };
  this.GetDrugGroups()
  if(this.route.snapshot.paramMap.get("drugid")){
    this.drugid =this.route.snapshot.paramMap.get("drugid")
    this.GetById(this.drugid)
    console.log(this.update)
  } 
  else  if (this.route.snapshot.paramMap.get("id")) {
      console.log(this.route.snapshot.paramMap.get("id"))
      this.id =this.route.snapshot.paramMap.get("id")
      this.DrugsService.GetDrugsById(this.id).subscribe(async (res:any)=>{
        console.log(res.data);
      this.Drug=  await res.data;
      this.imageLogo=environment.ServerImages_URL+'/'+this.Drug.image
        this.initForm(this.Drug);
        
      })
      this.update = true;
    } else {
      this.initForm()
      this.update = false;
    }
  }
  GetById(id){
    this.DrugsService.GetDrugsById(id).subscribe(async (res:any)=>{
      console.log(res.data);
    this.Drug=  await res.data;
    // if(!this.drugid){
     this.imageLogo=environment.ServerImages_URL+'/'+this.Drug.image
    // }
      this.initForm(this.Drug);
      
    })
    this.update = true;
  }
  getLogoUrl(event: any) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.Image = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.imageLogo = reader.result as string;
          console.log(this.Image)
        // this.logoForm?.append("image", this.Image);
      };
    }
  }
  GetDrugGroups(){
    this.DrugGroupsService.Get().subscribe(res=>{
      this.DrugGroups=res.data
    })
  }
  noLeadingSpaceValidator(control: FormControl) {
    const value = control.value;
    // Check if the value starts with a space
    if (value && value.trimLeft() !== value) {
      return { leadingSpace: true };
    }
    return null;
  }
  initForm(Drugs?: Drugs) {
    this.insertForm = this._formBuilder.group({
      title: [Drugs?.title || '', [Validators.required,this.noLeadingSpaceValidator]],
      drugImage: [Drugs?.image || '', Validators.required],
      order: [Drugs?.order || '', Validators.required],
      patientcounseling: [Drugs?.patientCounseling || '', [Validators.required,this.noLeadingSpaceValidator]],
      drugGroupIds: [Drugs?.drugGroupId || '', Validators.required],
    });
  }

  get fc() {
    return this.insertForm.controls;
  }

  Submit(){
    if (this.insertForm.status == "VALID") {
      this.disaple=true
      if (this.update == true) {
        if(this.drugid){
          this.VerifyDrug()
        }
        else{
          this.UpdateDrug();
        }
      } else {
        this.InsertDrug();
      }
    }
     else {
      if(!this.Image&&!this.update){
        Swal.fire({
          icon: "error",
          title:'من فضلك اختار صورة'
          });
      }
if(!this.Image && this.drugid){
  Swal.fire({
    icon: "error",
    title:'من فضلك اختار صورة'
    });
}
      this.insertForm.markAllAsTouched();
    }
  

  }
  UpdateDrug(){
    this.DrugForm.set("Id", this.id);
    this.DrugForm.set("DrugImage", this.Image);
    this.DrugForm.append("Title", this.insertForm.get("title").value);
    this.DrugForm.set("Order", this.insertForm.get("order").value);
    this.DrugForm.set("PatientCounseling", this.insertForm.get("patientcounseling").value);
    this.DrugForm.set("DrugGroupId", this.insertForm.get("drugGroupIds").value);

    this.DrugsService.Update(this.DrugForm).subscribe(
      (response) => {
      Swal.fire({
      icon: "success",
      title: "تم التعديل بنجاح",
      showConfirmButton: false,
      timer: 1500,
      });
      this.router.navigateByUrl("content/admin/ListDrugs");
      this.disaple=false

    },
      (err) => {
        this.disaple=false
        Swal.fire({
          icon: "error",
          title:err.error.message
          });
      }
  
      );
  }
  InsertDrug(){
    console.log(this.insertForm.value)
    this.DrugForm.set("DrugImage", this.Image);
    this.DrugForm.set("Title", this.insertForm.get("title").value);
    this.DrugForm.set("Order", this.insertForm.get("order").value);
    this.DrugForm.set("PatientCounseling", this.insertForm.get("patientcounseling").value);
    this.DrugForm.set("DrugGroupId", this.insertForm.get("drugGroupIds").value);

    this.DrugsService.Create(this.DrugForm).subscribe(
      (response) => {
      Swal.fire({
      icon: "success",
      title: "تم الحفظ بنجاح",
      showConfirmButton: false,
      timer: 1500,
      });
      this.router.navigateByUrl("content/admin/ListDrugs");
      this.disaple=false

    },
      (err) => {
        this.disaple=false
        Swal.fire({
          icon: "error",
          title:err.error.message
          });
      }
            );
   
 
  }

  VerifyDrug(){
    this.DrugForm.set("Id", this.drugid);
    this.DrugForm.set("DrugImage", this.Image);
    this.DrugForm.set("Title", this.insertForm.get("title").value);
    this.DrugForm.set("Order", this.insertForm.get("order").value);
    this.DrugForm.set("PatientCounseling", this.insertForm.get("patientcounseling").value);
    this.DrugForm.set("DrugGroupId", this.insertForm.get("drugGroupIds").value);

    this.DrugsService.Verify(this.DrugForm).subscribe(
      (response) => {
      Swal.fire({
      icon: "success",
      title: "تم التعديل بنجاح",
      showConfirmButton: false,
      timer: 1500,
      });
      this.router.navigateByUrl("content/admin/ListDrugs");
      this.disaple=false

    },
      (err) => {
        this.disaple=false
        Swal.fire({
          icon: "error",
          title:err.error.message
          });
      }
  
      );
  }
}
