
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header" style="padding-bottom: 5px !important">
                    <div >
                        <h5 *ngIf="!update"  class="pb-2">اضافة نصيحة</h5>
                        <h5  *ngIf="update"  class="pb-2">تعديل نصيحة</h5>

                       <span> بالرجاء ملئ جميع الحقول </span>
                    </div>
                    <ng-template >
                        
                    </ng-template>
                </div>
                <div class="card-body">
                    <form [formGroup]="insertForm" (ngSubmit)="Submit()">

                    <div class="row m-0 mb-2">
                        <div class="col-md-6">
                          <label for=""> الاسم
                          </label>
                            <div class="form-group">
                               <input type="text"  class="form-control" formControlName="Title" 
                               [tooltip]="fc.Title.errors?.required ? ' الاسم مطلوب' : null" required
                               [isDisabled]="!((fc.Title.errors?.required && fc.Title.touched))"
                               containerClass="" [class.is-valid]="fc.Title.valid && (fc.Title.touched)"
                               [class.is-invalid]="fc.Title.invalid && (fc.Title.touched)"
                               >
                            </div>
                        </div>
                       
                          <div class="col-md-6">
                            <label for=""> التاريخ
                            </label>
                              <div class="form-group">
                                 <input type="datetime-local" formControlName="Date" class="form-control" 
                                 [tooltip]="fc.Date.errors?.required ? ' التاريخ مطلوب' : null" required
                                 [isDisabled]="!((fc.Date.errors?.required && fc.Date.touched))"
                                 containerClass="" [class.is-valid]="fc.Date.valid && (fc.Date.touched)"
                                 [class.is-invalid]="fc.Date.invalid && (fc.Date.touched)"
                                 >

                              </div>
                          </div>
                          <div class="col-md-6">
                              <div class="form-group">
                                <label >أختر تصنيف النصائح </label>
                                <ng-select class="js-example-basic-single"
                                        formControlName="TipCategoryId"
                                        aria-label="Default select example"
                                        (change)="SelectTipCategory($event.id)"
                                        required="required"
                                        [items]="TipCategory"
                                        bindLabel="title"
                                        bindValue="id"
                                        [style.border]="fc.TipCategoryId.touched ? (fc.TipCategoryId.invalid ? '1px solid #dc3545 !important' : (fc.TipCategoryId.valid && fc.TipCategoryId.touched ? '1px solid #28a745' : 'none')) : 'none'"
 
                                        >
          
                                </ng-select>
                              </div>
                          </div>
                          <div class="col-6 col-md-6">
                              <label >أختر مجموعة الادوية </label>
                              <ng-multiselect-dropdown
                              formControlName="DrugGroupIds"
                              id="validationCustom02=3"
                                placeholder="أختر مجموعة الادوية أو أكتر من القائمة"
                                [settings]="dropdownSettings"
                                [(ngModel)]="selectedItems"
                                [data]="DrugGroups"                                             
                                required="required"
                              [style.border]="fc.DrugGroupIds.touched ? (fc.DrugGroupIds.invalid ? '1px solid #dc3545 !important' : (fc.DrugGroupIds.valid && fc.DrugGroupIds.touched ? '1px solid #28a745' : 'none')) : 'none'"

                                >
                           
                              </ng-multiselect-dropdown>
                        </div>
                        <div class="col-6 my-3 " >
                            <img *ngIf="imageLogo" src="{{ imageLogo }}" style="width: 60%; height: 220px;" class="image-style p-2" />
                           <input type="file" formControlName="TipImage" accept="image/*" #file id="getLogo"
                            style="display: none" (change)="getLogoUrl($event)" />
                        <button type="button" onclick="document.getElementById('getLogo').click()"
                            class="btn btn-success  d-block"  style="width: 60%;">
                            ارفاق صورة
                        </button>    
                                    </div>
                                    <div class="col-md-6">
                                      <label for=""> الوصف
                                      </label>
                                        <div class="form-group">
                                          <angular-editor id="editor1" formControlName="Description" [config]="editorConfig">
                                            
                                          </angular-editor>
                                          <!-- <angular-editor [placeholder]="'بالرجاء كتابة المحتوى هنا.....'" [config]="editorConfig" 
                                          formControlName="Description" 
                                          [tooltip]="fc.Description.errors?.required ? ' الوصف مطلوب' : null" required
                                          [isDisabled]="!((fc.Description.errors?.required && fc.Description.touched))"
                                          containerClass="" [class.is-valid]="fc.Description.valid && (fc.Description.touched)"
                                          [class.is-invalid]="fc.Description.invalid && (fc.Description.touched)"></angular-editor> -->
                                        </div>
                                    </div>
                        <div class="col-12 col-md-12">
                            <div *ngIf="update; else elseBlock">
                                <button [disabled]="disaple" class="btn btn-success pull-right" type="submit" >
                                  تعديل
                                </button>
                              </div>
                  
                              <ng-template #elseBlock>
                                <button [disabled]="disaple" class="btn btn-success pull-right" type="submit">
                                  إضافة
                                </button>
                              </ng-template>
                </div>
                </div>
                    </form>
    </div>
</div>
        </div>
    </div>
</div>