import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerPrescriptionService } from '../../../../shared/API-Service/services/customer-prescription.service';
import Swal from 'sweetalert2';
import {BaseComponent } from '../../../../shared/components/base/base.component'
import { CommentModalComponent } from '../../comment-modal/comment-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-list-drug-almost-finished',
  templateUrl: './list-drug-almost-finished.component.html',
  styleUrls: ['./list-drug-almost-finished.component.css']
})
export class ListDrugAlmostFinishedComponent  extends BaseComponent  implements OnInit {
  DrugAlmostFinished:any
  customerId: any;
  details: boolean;
  customerPrescriptionDrugsIds: any[]=[];
  customerPrescriptionDrugsData: any[]=[];
  SendDisable:boolean
  constructor(public modalService: NgbModal,private CustomerPrescriptionService:CustomerPrescriptionService,private _Router:Router,private route: ActivatedRoute) { super()}

  ngOnInit(): void {
    this.getDrugAlmostFinished();
    if (this.route.snapshot.paramMap.get("id")) {
      console.log(this.route.snapshot.paramMap.get("id"))
      this.customerId =this.route.snapshot.paramMap.get("id")
    }
  }
  pageChanged(event: any){
    this.pageNumber = event.page;// -1 * pageSize;
    this.pager.skipCount = (this.pageNumber - 1) * this.pager.maxResultCount;
    this.CustomerPrescriptionService.GetDrugAlmostFinished(this.pager).subscribe((res) => {
      this.DrugAlmostFinished = res.data.items
      this.totalCount=res.data.totalCount;
    console.log(this.DrugAlmostFinished);
    
    },(err)=>{
      console.log('their is a problem');
    },() =>{
      console.log('Completed');
    });

  }

  OpenDetailes(index){
     console.log(this.DrugAlmostFinished[index])
    if(this.DrugAlmostFinished[index]["ShowDetails"]){
      this.details=false;
      this.DrugAlmostFinished[index]["ShowDetails"]=false;
   // this.DontationType= this.AllDontationType 
    }
    else{
      this.details=true;
        this.DrugAlmostFinished[index]["ShowDetails"]=true;
       // this.DontationType= this.AllDontationType;
    }
  }
  NotifyCustomerDrugAlmostFinished(id){
    console.log(this.customerPrescriptionDrugsData)
    this.CustomerPrescriptionService.NotifyCustomerDrugAlmostFinished(id).subscribe(
      (response) => {
        Swal.fire({
        icon: "success",
        title: "تم الارسال بنجاح",
        showConfirmButton: false,
        timer: 1500,
        });
        this.getDrugAlmostFinished();
      },
        (err) => {
          Swal.fire({
            icon: "error",
            title:err.error.message
            });
        })
  }
  getDrugAlmostFinished(){
    const data={
      "maxResultCount": this.pager.maxResultCount,
      "skipCount": this.pager.skipCount,
    }
    this.CustomerPrescriptionService.GetDrugAlmostFinished(data).subscribe((res) => {
      // res = {
      //   "message": "string",
      //   "messageCode": 0,
      //   "data": {
      //     "items": [
      //       {
      //         "customer": {
      //           "name": "string",
      //           "mobile": "string",
      //           "genderId": 0,
      //           "districtId": 0,
      //           "address": "string",
      //           "id": 1,
      //           "code": "string",
      //           "genderTitle": "string"
      //         },
      //         "almostFinishedDrugs": [
      //           {
      //             "drugId": 0,
      //             "doseTimeId": 0,
      //             "count": 0,
      //             "startDate": "2023-01-24T12:44:28.620Z",
      //             "days": 0,
      //             "drugItemCount": 0,
      //             "quantityPerItem": 0,
      //             "doseQuantityId": 0,
      //             "pharmacistComment": "string",
      //             "notification": true,
      //             "id": 6,
      //             "customerPrescriptionId": 0,
      //             "doseTimeTitle": "string",
      //             "doseQuantityTitle": "string",
      //             "doseQuantityValue": 0,
      //             "endDate": "2023-01-24T12:44:28.620Z",
      //             "drugTitle": "string",
      //             "notifyFinished": true,
      //             "notified": true,
      //             "lastNotifyDate": "2023-01-25T11:00:59.832Z",
      //             "notifiedById": 0,
      //             "notifiedByName": "string"
      //           },
      //           {
      //             "drugId": 0,
      //             "doseTimeId": 0,
      //             "count": 0,
      //             "startDate": "2023-01-24T12:44:28.620Z",
      //             "days": 0,
      //             "drugItemCount": 0,
      //             "quantityPerItem": 0,
      //             "doseQuantityId": 0,
      //             "pharmacistComment": "string",
      //             "notification": true,
      //             "id": 9,
      //             "customerPrescriptionId": 0,
      //             "doseTimeTitle": "string",
      //             "doseQuantityTitle": "string",
      //             "doseQuantityValue": 0,
      //             "endDate": "2023-01-24T12:44:28.620Z",
      //             "drugTitle": "string",
      //             "notifyFinished": true,
      //             "notified": true,
      //             "lastNotifyDate": "2023-01-25T11:00:59.832Z",
      //             "notifiedById": 0,
      //             "notifiedByName": "string"
      //           }
      //         ]
      //       },
      //       {
      //         "customer": {
      //           "name": "string",
      //           "mobile": "string",
      //           "genderId": 0,
      //           "districtId": 0,
      //           "address": "string",
      //           "id":2,
      //           "code": "string",
      //           "genderTitle": "string"
      //         },
      //         "almostFinishedDrugs": [
      //           {
      //             "drugId": 0,
      //             "doseTimeId": 0,
      //             "count": 0,
      //             "startDate": "2023-01-24T12:44:28.620Z",
      //             "days": 0,
      //             "drugItemCount": 0,
      //             "quantityPerItem": 0,
      //             "doseQuantityId": 0,
      //             "pharmacistComment": "string",
      //             "notification": true,
      //             "id": 4,
      //             "customerPrescriptionId": 0,
      //             "doseTimeTitle": "string",
      //             "doseQuantityTitle": "string",
      //             "doseQuantityValue": 0,
      //             "endDate": "2023-01-24T12:44:28.620Z",
      //             "drugTitle": "string",
      //             "notifyFinished": true,
      //             "notified": true,
      //             "lastNotifyDate": "2023-01-25T11:00:59.832Z",
      //             "notifiedById": 0,
      //             "notifiedByName": "string"
      //           },
      //           {
      //             "drugId": 0,
      //             "doseTimeId": 0,
      //             "count": 0,
      //             "startDate": "2023-01-24T12:44:28.620Z",
      //             "days": 0,
      //             "drugItemCount": 0,
      //             "quantityPerItem": 0,
      //             "doseQuantityId": 0,
      //             "pharmacistComment": "string",
      //             "notification": true,
      //             "id": 5,
      //             "customerPrescriptionId": 0,
      //             "doseTimeTitle": "string",
      //             "doseQuantityTitle": "string",
      //             "doseQuantityValue": 0,
      //             "endDate": "2023-01-24T12:44:28.620Z",
      //             "drugTitle": "string",
      //             "notifyFinished": true,
      //             "notified": true,
      //             "lastNotifyDate": "2023-01-25T11:00:59.832Z",
      //             "notifiedById": 0,
      //             "notifiedByName": "string"
      //           }
      //         ]
      //       }
      //     ],
      //     "totalCount": 0
      //   },
      //   "success": true
      // }
      this.DrugAlmostFinished = res.data.items
      this.totalCount=res.data.totalCount;
    console.log(this.DrugAlmostFinished.length);
    if(this.DrugAlmostFinished.length==0){
      this.SendDisable=true;
    }
    },(err)=>{
      console.log('their is a problem');
    },() =>{
      console.log('Completed');
    });
  }
  checkValue(event,id,customerId,almostFinishedDrugsindex,DrugAlmostFinished){
console.log(id);
console.log(customerId);
if(event.target.checked){
  DrugAlmostFinished.almostFinishedDrugs[almostFinishedDrugsindex]["check"]=true;
  const checkId = this.customerPrescriptionDrugsIds.filter(x=>x==id);
  const customerIdfilter= this.customerPrescriptionDrugsData.filter(x=>x.customerId==customerId)
  const customerIdIndex= this.customerPrescriptionDrugsData.findIndex(x=>x.customerId==customerId)

  console.log(checkId)
  
if(customerIdfilter.length==0){
  this.customerPrescriptionDrugsIds=[];
  if(checkId.length==0){
    this.customerPrescriptionDrugsIds.push(id)
  }
  const data =
    {
  "customerId": customerId,
  "customerPrescriptionDrugsIds":this.customerPrescriptionDrugsIds
  }

  this.customerPrescriptionDrugsData.push(data);
}
else{
  if(checkId.length==0){
    this.customerPrescriptionDrugsIds.push(id)
  }
  this.customerPrescriptionDrugsData[customerIdIndex].customerPrescriptionDrugsIds=this.customerPrescriptionDrugsIds}
    console.log(DrugAlmostFinished)
}
else{
  const customerIdIndex= this.customerPrescriptionDrugsData.findIndex(x=>x.customerId==customerId)

  DrugAlmostFinished.almostFinishedDrugs[almostFinishedDrugsindex]["check"]=false;
 const customerPrescriptionDrugsIdsIndex= this.customerPrescriptionDrugsIds.findIndex(x=>x==id)
 this.customerPrescriptionDrugsIds=  this.customerPrescriptionDrugsIds.filter(x=>x!=this.customerPrescriptionDrugsIds[customerPrescriptionDrugsIdsIndex])
 this.customerPrescriptionDrugsData[customerIdIndex].customerPrescriptionDrugsIds=this.customerPrescriptionDrugsIds

 console.log(this.customerPrescriptionDrugsIds)

}
  }
  update(id){
    console.log(id);
    this._Router.navigate(['/content/admin/update-CustomerPrescription',id,this.customerId]);
    }
    AddCustomerPrescription(){
      this._Router.navigate(['/content/admin/insert-CustomerPrescription',this.customerId]);

    }
    openModal(customerPrescriptionId) {
      //ModalComponent is component name where modal is declare
      localStorage.removeItem("ModalData");
      localStorage.setItem("ModalData",customerPrescriptionId);
      const modalRef = this.modalService.open(CommentModalComponent);
      modalRef.result.then((result) => {
        console.log(result);
      }).catch((error) => {
        console.log(error);
      });
    }
    RenewPrescription(id){
      Swal.fire({
        title: 'هل تريد صرف الدواء ؟',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'الغاء',
        confirmButtonText: 'صرف !'
      }).then((result) => {
        if (result.isConfirmed) {
          this.CustomerPrescriptionService.RenewPrescription(id).subscribe(
            (response) => {
              Swal.fire({
              icon: "success",
              title: "تم الصرف بنجاح",
              showConfirmButton: false,
              timer: 1500,
              });
              this.getDrugAlmostFinished();
            },
              (err) => {
                Swal.fire({
                  icon: "error",
                  title:err.error.message
                  });
              })
        }
      }) 
   
    }
    DismissNotifyFinished(id : number){
      Swal.fire({
        title: 'هل تريد اخفاء العنصر ؟',
        text: "لن يكون لك صلاحية إعادته مره اخرى",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'الغاء',
        confirmButtonText: 'اخفاء العنصر !'
      }).then((result) => {
        if (result.isConfirmed) {
          this.CustomerPrescriptionService.DismissNotifyFinished(id).subscribe((res) => {
            Swal.fire({
              icon: "success",
              title: "تم اخفاء بنجاح",
              showConfirmButton: false,
              timer: 1500,
            });
         this.getDrugAlmostFinished();
          },(err) => {
            Swal.fire({
              icon: 'error',
              title: 'خطأ',
              text:err.error.message    
            })
          },() => {
            console.log("completed");
          })
        }
      }) 
    }

}
