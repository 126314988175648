import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { VideoAlbumService } from 'src/app/shared/API-Service/services/video-album.service';
import Swal from 'sweetalert2';
import {VideoAlbum} from "../../../../shared/Models/VideoAlbum";
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-insert-video-album',
  templateUrl: './insert-video-album.component.html',
  styleUrls: ['./insert-video-album.component.css']
})
export class InsertVideoAlbumComponent implements OnInit {
  id: string;
  update: boolean;
  insertForm: FormGroup;
  VideoAlbumModal = new VideoAlbum();
  VideoAlbums:any;
  Image: File;
  imageLogo: string="";
  VideoAlbumForm=new FormData();
  disaple: boolean;

  constructor(private VideoAlbumService:VideoAlbumService,private _formBuilder: FormBuilder,private router: Router,private route: ActivatedRoute) { 
  }
  ngOnInit(): void {
    if (this.route.snapshot.paramMap.get("id")) {
      this.id =this.route.snapshot.paramMap.get("id")
      this.VideoAlbumService.GetVideoAlbumById(this.id).subscribe(async (res:any)=>{
      this.VideoAlbums=  await res.data;
      this.imageLogo=environment.ServerImages_URL+'/'+this.VideoAlbums.image

        this.initForm(this.VideoAlbums);
        
      })
      this.update = true;
    } else {
      this.initForm()
      this.update = false;
    }
  }
  noLeadingSpaceValidator(control: FormControl) {
    const value = control.value;
    // Check if the value starts with a space
    if (value && value.trimLeft() !== value) {
      return { leadingSpace: true };
    }
    return null;
  }

  initForm(VideoAlbum?: VideoAlbum) {
    this.insertForm = this._formBuilder.group({
      title: [VideoAlbum?.title || '',[Validators.required,this.noLeadingSpaceValidator]],
      image: [VideoAlbum?.image || '', Validators.required],
      order: [VideoAlbum?.order || '', Validators.required],
    });
  }
  getLogoUrl(event: any) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.Image = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.imageLogo = reader.result as string;
        // this.logoForm?.append("image", this.Image);
      };
    }
  }
  get fc() {
    return this.insertForm.controls;
  }

  Submit(){
    if (this.insertForm.status == "VALID") {
      this.disaple=true
      if (this.update == true) {
        this.UpdateVideoAlbum();
      } else {
        this.InsertVideoAlbum();
      }
    }
     else {
      this.insertForm.markAllAsTouched();
      if(!this.Image&&!this.update){
        Swal.fire({
          icon: "error",
          title:"من فضلك اختار صورة"
          }); 
      }
    }
  

  }
  UpdateVideoAlbum(){
    this.VideoAlbumForm.set("Id", this.id);
    this.VideoAlbumForm.set("Image", this.Image);
    this.VideoAlbumForm.set("Title", this.insertForm.get("title").value);
    this.VideoAlbumForm.set("Order", this.insertForm.get("order").value);
    this.VideoAlbumService.Update(this.VideoAlbumForm).subscribe(
      (response) => {
      Swal.fire({
      icon: "success",
      title: "تم التعديل بنجاح",
      showConfirmButton: false,
      timer: 1500,
      });
      this.router.navigateByUrl("content/admin/ListVideoAlbum");
      this.disaple=false

    },
      (err) => {
        this.disaple=false

        Swal.fire({
          icon: "error",
          title:err.error.message
          });
      }
  
      );
  }
  InsertVideoAlbum(){
    this.VideoAlbumForm.set("Image", this.Image);
    this.VideoAlbumForm.set("Title", this.insertForm.get("title").value);
    this.VideoAlbumForm.set("Order", this.insertForm.get("order").value);
    this.VideoAlbumService.Create(this.VideoAlbumForm).subscribe(
      (response) => {
      Swal.fire({
      icon: "success",
      title: "تم الحفظ بنجاح",
      showConfirmButton: false,
      timer: 1500,
      });
      this.router.navigateByUrl("content/admin/ListVideoAlbum");

      this.disaple=false
    },
      (err) => {
        this.disaple=false

        Swal.fire({
          icon: "error",
          title:err.error.message
          });
      }
            );
   
 
  }


}
