import { Component, OnInit } from '@angular/core';
import { StatsDashboardService } from 'src/app/shared/API-Service/services/stats-dashboard.service';
import { BaseComponent } from 'src/app/shared/components/base/base.component';

@Component({
  selector: 'app-customer-without-token',
  templateUrl: './customer-without-token.component.html',
  styleUrls: ['./customer-without-token.component.css']
})
export class CustomerWithoutTokenComponent extends BaseComponent implements OnInit  {
  InactiveCustomersList: any;

  constructor(private StatsDashboardService:StatsDashboardService) {super(); }

  ngOnInit(): void {
this.GetInactiveCustomers();
  }
GetInactiveCustomers(){
  this.StatsDashboardService.InactiveCustomers(this.pager).subscribe(res=>{
this.InactiveCustomersList=res.data.items;
this.totalCount=res.data.totalCount
  })
}
pageChanged(event: any){
  this.pageNumber = event.page;// -1 * pageSize;
  this.pager.skipCount = (this.pageNumber - 1) * this.pager.maxResultCount;
  this.GetInactiveCustomers();
}
}
