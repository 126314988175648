
 
 

import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';


import { CommonModule } from '@angular/common';
import { AdminRoutingModule } from './admin-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { ArchwizardModule } from 'angular-archwizard';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
 
import { RouterModule } from '@angular/router';
 
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgxPrintElementModule } from 'ngx-print-element';
 
import { NgApexchartsModule } from 'ng-apexcharts';
 

 

// **  
import {NgxPaginationModule} from 'ngx-pagination';

// cookie 
import { CookieService } from 'ngx-cookie-service'


import { DxReportViewerModule } from 'devexpress-reporting-angular';
import { LoginComponent } from './User/login/login.component';
import { ListCustomerComponent } from './Customers/list-customer/list-customer.component';
import { InsertCustomerComponent } from './Customers/insert-customer/insert-customer.component';
import { ListDistrictsComponent } from './Districts/list-districts/list-districts.component';
import { InsertDistrictComponent } from './Districts/insert-district/insert-district.component';
import { ListDrugGroupComponent } from './DrugGroup/list-drug-group/list-drug-group.component';
import { InsertDrugGroupComponent } from './DrugGroup/insert-drug-group/insert-drug-group.component';
import { ListTipsComponent } from './Tip/list-tips/list-tips.component';
import { InsertTipsComponent } from './Tip/insert-tips/insert-tips.component';
import { ListTipCategoryComponent } from './TipCategory/list-tip-category/list-tip-category.component';
import { InsertTipCategoryComponent } from './TipCategory/insert-tip-category/insert-tip-category.component';
import { InsertCustomerinbodytestComponent } from './CustomerInBodyTest/insert-customerinbodytest/insert-customerinbodytest.component';
import { ListCustomerinbodytestComponent } from './CustomerInBodyTest/list-customerinbodytest/list-customerinbodytest.component';
import { ListVideoAlbumComponent } from './VideoAlbum/list-video-album/list-video-album.component';
import { InsertVideoAlbumComponent } from './VideoAlbum/insert-video-album/insert-video-album.component';
import { ListVideosComponent } from './Videos/list-videos/list-videos.component';
import { InsertVideoComponent } from './Videos/insert-video/insert-video.component';
import { ListUsersComponent } from './Users/list-users/list-users.component';
import { InsertUserComponent } from './Users/insert-user/insert-user.component';
import { ListDrugsComponent } from './Drug/list-drugs/list-drugs.component';
import { InsertDrugComponent } from './Drug/insert-drug/insert-drug.component';
import { ListMedicalMeasurementComponent } from './MedicalMeasurement/list-medical-measurement/list-medical-measurement.component';
import { InsertMedicalMeasurementComponent } from './MedicalMeasurement/insert-medical-measurement/insert-medical-measurement.component';
import { InputDynamicComponent } from 'src/app/shared/components/control-base/input-dynamic/input-dynamic.component';
import { ListCustomerMedicalMeasurementComponent } from './CustomerMedicalMeasurement/list-customer-medical-measurement/list-customer-medical-measurement.component';
import { InsertCustomerMedicalMeasurementComponent } from './CustomerMedicalMeasurement/insert-customer-medical-measurement/insert-customer-medical-measurement.component';
import { InsertCustomerPrescriptionComponent } from './CustomerPrescription/insert-customer-prescription/insert-customer-prescription.component';
import { ListCustomerPrescriptionComponent } from './CustomerPrescription/list-customer-prescription/list-customer-prescription.component';
import { CommentModalComponent } from './comment-modal/comment-modal.component';
import { ListRegisterRequestComponent } from './RegisterRequest/list-register-request/list-register-request.component';
import { InsertRegisterRequestComponent } from './RegisterRequest/insert-register-request/insert-register-request.component';
import { InsertCustomerNormalDrugPresciptionComponent } from './CustomerPrescription/insert-customer-normal-drug-presciption/insert-customer-normal-drug-presciption.component';
import { ListDrugAlmostFinishedComponent } from './DrugAlmostFinished/list-drug-almost-finished/list-drug-almost-finished.component'
import { AngularEditorModule } from '@kolkov/angular-editor';
import { AllPermissionComponent } from './Permissions/all-permission/all-permission.component';
import { AddPermissionComponent } from './Permissions/add-permission/add-permission.component';
import {ChangePasswordComponent} from './User/change-password/change-password.component';
import { ListHelpComponent } from './Help/list-help/list-help.component';
import { InsertHelpComponent } from './Help/insert-help/insert-help.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CustomerWithoutTokenComponent } from './Customers/customer-without-token/customer-without-token.component';
import { CustomerPrescriptionDetailsComponent } from './CustomerPrescription/customer-prescription-details/customer-prescription-details.component';
import { CustomerMedicalMeasurementDetailsComponent } from './CustomerMedicalMeasurement/customer-medical-measurement-details/customer-medical-measurement-details.component';
import { AllUnverifiedDrugsComponent } from './DrugAlmostFinished/list-drug-almost-finished/all-unverified-drugs/all-unverified-drugs.component'
 @NgModule({
  declarations: [
    LoginComponent,
    ListCustomerComponent,
    InsertCustomerComponent,
    ListDistrictsComponent,
    InsertDistrictComponent,
    ListDrugGroupComponent,
    InsertDrugGroupComponent,
    ListTipsComponent,
    InsertTipsComponent,
    ListTipCategoryComponent,
    InsertTipCategoryComponent,
    InsertCustomerinbodytestComponent,
    ListCustomerinbodytestComponent,
    ListVideoAlbumComponent,
    InsertVideoAlbumComponent,
    ListVideosComponent,
    InsertVideoComponent,
    ListUsersComponent,
    InsertUserComponent,
    ListDrugsComponent,
    InsertDrugComponent,
    ListMedicalMeasurementComponent,
    InsertMedicalMeasurementComponent,
    InputDynamicComponent,
    ListCustomerMedicalMeasurementComponent,
    InsertCustomerMedicalMeasurementComponent,
    InsertCustomerPrescriptionComponent,
    ListCustomerPrescriptionComponent,
    CommentModalComponent,
    ListRegisterRequestComponent,
    InsertRegisterRequestComponent,
    InsertCustomerNormalDrugPresciptionComponent,
    ListDrugAlmostFinishedComponent,
    AllPermissionComponent,
    AddPermissionComponent,
    ChangePasswordComponent,
    ListHelpComponent,
    InsertHelpComponent,
    DashboardComponent,
    CustomerWithoutTokenComponent,
    CustomerPrescriptionDetailsComponent,
    CustomerMedicalMeasurementDetailsComponent,
    AllUnverifiedDrugsComponent
  ],
  imports: [
    AngularEditorModule,
    DxReportViewerModule,
    CommonModule,
    AdminRoutingModule,
    SharedModule,
    ArchwizardModule,
    SweetAlert2Module,
    NgxPaginationModule,
    RouterModule,
    NgApexchartsModule,
    NgxPrintElementModule,
    NgMultiSelectDropDownModule.forRoot()
  ],
  providers: [
   CookieService
  ],
  
  exports:[ ]
})
export class AdminModule { }
