import { Injectable, NgZone } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';
import {PermissionsApiService } from '../API-Service/services/Permissions-api.service';
import {Location } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardGuard implements CanActivate {
  err: boolean;
  menuActions: any[]=[];
  Menus: any[]=[];
  menuRolus: any[]=[];
  MenusForRole: any[]=[];
  childern:any[]=[];
  constructor( private router: Router,private ngzone:NgZone,private PermissionsApiService :PermissionsApiService,private location: Location){

  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      const currentUser = JSON.parse(localStorage.getItem("WeCareCurrentUser"));
      var res;
      
      if(currentUser){  this.err=false;
  this.menuRolus = JSON.parse(localStorage.getItem('WeCaremenuRolus'));
  this.menuRolus?.forEach(element => {
    if(element.children){
   this.childern= element.children;
   this.childern.forEach(childernelement => {
      if(childernelement.path!=null){
      const lastWord = childernelement.path.split('/').pop();
    this.MenusForRole.push({name:lastWord}); 
     }
   });
    }
   
       
      if(next.url.length>=1){
       this.MenusForRole=this.MenusForRole.filter(x=>x.name==next.url[0].path)
      }
    
 
   
  } ); //check if the route is retricted by role
  if(this.MenusForRole.length==0){
    //role not authorized
    this.ngzone.run(() => {
      Swal.fire({
        icon: "error",
        title: "خطأ",
        text: "غير مصرح لك الدخول الى هذه الصفحة",
        timer: 2500
      }).then(res=>{
        //this.location.back();
      });
      //this.router.navigateByUrl('/login');
      this.err=false;
    });
  }else{
   this.err= true;
  };
 return this.err;
}
else {
  this.ngzone.run(() => {
    this.router.navigateByUrl('/login');
  });
}
    
    }
    
}
