import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import {Help} from "../../../../shared/Models/Help";
import { HelpService } from '../../../../shared/API-Service/services/help.service';

@Component({
  selector: 'app-insert-help',
  templateUrl: './insert-help.component.html',
  styleUrls: ['./insert-help.component.css']
})
export class InsertHelpComponent implements OnInit {
  id: string;
  update: boolean;
  insertForm: FormGroup;
  HelpModal = new Help();
  Help: any;
  disaple: boolean;

 
  constructor(private HelpService :HelpService,private _formBuilder: FormBuilder,private router: Router,private route: ActivatedRoute) { 
  }

  ngOnInit(): void {
    if (this.route.snapshot.paramMap.get("id")) {
      console.log(this.route.snapshot.paramMap.get("id"))
      this.id =this.route.snapshot.paramMap.get("id")
      this.HelpService.GetHelpById(this.id).subscribe(async (res:any)=>{
        console.log(res.data);
      this.Help=  await res.data;
        this.initForm(this.Help);
        
      })
      this.update = true;
    } else {
      this.initForm()
      this.update = false;
    }
  }

  noLeadingSpaceValidator(control: FormControl) {
    const value = control.value;
    // Check if the value starts with a space
    if (value && value.trimLeft() !== value) {
      return { leadingSpace: true };
    }
    return null;
  }
  initForm(Help?: Help) {
    this.insertForm = this._formBuilder.group({
      title: [Help?.title || '', [Validators.required,this.noLeadingSpaceValidator]],
      videoUrl: [Help?.videoUrl|| '', [Validators.required,Validators.pattern('^(https?|ftp)://[^\s/$.?#].[^\s]*$|^www.[^\s/$.?#].[^\s]*$')]],
    });
  }

  get fc() {
    return this.insertForm.controls;
  }

  Submit(){
 
    if (this.insertForm.status == "VALID") {
      this.disaple=true

      if (this.update == true) {
        this.UpdateHelp();
      } else {
        this.InsertHelp();
      }
    }
     else {
      this.insertForm.markAllAsTouched();
    }
  

  }
  UpdateHelp(){
    const data={
      "title": this.insertForm.get("title").value,
      "VideoUrl": this.insertForm.get("videoUrl").value,
      "id":this.id
    }
    this.HelpService.Update(data).subscribe(
      (response) => {
      Swal.fire({
      icon: "success",
      title: "تم التعديل بنجاح",
      showConfirmButton: false,
      timer: 1500,
      });
      this.router.navigateByUrl("content/admin/ListHelp");
      this.disaple=false
    },
      (err) => {
        this.disaple=false
        Swal.fire({
          icon: "error",
          title:err.error.message
          });
      }
  
      );
  }
  InsertHelp(){
  
    const data={
      "title": this.insertForm.get("title").value,
      "VideoUrl": this.insertForm.get("videoUrl").value,
    }
    this.HelpService.Create(data).subscribe(
      (response) => {
      Swal.fire({
      icon: "success",
      title: "تم الحفظ بنجاح",
      showConfirmButton: false,
      timer: 1500,
      });
      this.router.navigateByUrl("content/admin/ListHelp");
      this.disaple=false
    },
      (err) => {
        this.disaple=false
        Swal.fire({
          icon: "error",
          title:err.error.message
          });
      }
            );
   
 
  }


}
