import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerPrescriptionService } from 'src/app/shared/API-Service/services/customer-prescription.service';
import Swal from 'sweetalert2';
import {CustomerService} from "src/app/shared/API-Service/services/Customer.service"
import {BaseComponent } from '../../../../shared/components/base/base.component'
import { CommentModalComponent } from '../../comment-modal/comment-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SearchDataService } from 'src/app/shared/services/SearchData.service';
@Component({
  selector: 'app-list-customer-prescription',
  templateUrl: './list-customer-prescription.component.html',
  styleUrls: ['./list-customer-prescription.component.css']
})
export class ListCustomerPrescriptionComponent extends BaseComponent implements OnInit {
  CustomerPrescription:any
  customerId: any;
  details: boolean;
  public active3:any=1;
  CustomerPrescriptionSchedule: any;
  ScheduletotalCount: any;
  SchedulepageNumber:number=1;
  ScheduleskipCount: number=0;
  SchedulemaxResultCount: number=25;
  CustomerName:any
  CustomerInfo: any;
  constructor(private searchDataService: SearchDataService,private CustomerService:CustomerService,private router: Router,public modalService: NgbModal,private CustomerPrescriptionService:CustomerPrescriptionService,private _Router:Router,private route: ActivatedRoute) { super()
    if(this.router.getCurrentNavigation()?.extras.state){
      const param = this.router.getCurrentNavigation().extras.state;
this.active3=param.activeId
    }
  }
  ngOnInit(): void {
 

    if (this.route.snapshot.paramMap.get("id")) {
      this.customerId =this.route.snapshot.paramMap.get("id")
      this.CustomerService.GetCustomerById(this.customerId).subscribe(res=>{
        this.CustomerInfo=res.data
      })
      this.GetCustomerNormalDrugs();
      this.GetCustomerScheduleDrugs();
    }
    
  }
  onNavChange1(e){
console.log(e)
  }
  pageChanged(event: any){
    this.pageNumber = event.page;// -1 * pageSize;
    this.pager.skipCount = (this.pageNumber - 1) * this.pager.maxResultCount;
    this.pager.customerId=this.customerId
    this.GetCustomerNormalDrugs()

  }
  back(){
    this._Router.navigateByUrl('/content/admin/ListCustomers',{ state: { wantSearchData: true } });
  }
  
  SchedulepageChanged(event: any){
    this.SchedulepageNumber = event.page;// -1 * pageSize;
    this.ScheduleskipCount = (this.SchedulepageNumber - 1) * this.SchedulemaxResultCount;
    //this.pager.customerId=this.customerId
   this.GetCustomerScheduleDrugs()

  }
  OpenDetailes(index){
    if(this.CustomerPrescriptionSchedule[index]["ShowDetails"]){
      this.details=false;
      this.CustomerPrescriptionSchedule[index]["ShowDetails"]=false;
   // this.DontationType= this.AllDontationType 
    }
    else{
      this.details=true;
        this.CustomerPrescriptionSchedule[index]["ShowDetails"]=true;
       // this.DontationType= this.AllDontationType;
    }
  }

  GetCustomerNormalDrugs(){
    this.pager.customerId=Number(this.customerId)
    this.CustomerPrescriptionService.GetCustomerNormalDrugs(this.pager).subscribe((res) => {
      this.CustomerPrescription = res.data.items
      //this.CustomerPrescription = res.data.items.filter(x=>x.doseTypeId==1)

      this.totalCount=res.data.totalCount;
    
    },(err)=>{
      console.log('their is a problem');
    },() =>{
      console.log('Completed');
    });
  }

  GetCustomerScheduleDrugs(){
    // const data={
    //   "maxResultCount": this.SchedulemaxResultCount,
    //   "skipCount": this.ScheduleskipCount,
    //   "customerId":Number(this.customerId)
    // }
    this.pager.maxResultCount=this.SchedulemaxResultCount
    this.pager.skipCount=this.ScheduleskipCount
    this.pager.customerId=Number(this.customerId)
    this.CustomerPrescriptionService.GetPaged(this.pager).subscribe((res) => {
      this.CustomerPrescriptionSchedule = res.data.items
      //this.CustomerPrescription = res.data.items.filter(x=>x.doseTypeId==1)

      this.ScheduletotalCount=res.data.totalCount;
    
    },(err)=>{
      console.log('their is a problem');
    },() =>{
      console.log('Completed');
    });
  }
  update(id){
    this._Router.navigate(['/content/admin/update-Customernormaldrug',id,this.customerId]);
    }
    updateSchedule(id){
      this._Router.navigate(['/content/admin/update-CustomerPrescription',id,this.customerId]);
      }
    AddCustomerPrescriptionSchedule(){
      this._Router.navigate(['/content/admin/insert-CustomerPrescriptionschedule',this.customerId]);
    }
    AddCustomerPrescription(){
      this._Router.navigate(['/content/admin/insert-Customernormaldrug',this.customerId]);
    }
    openModal(customerPrescriptionId) {
      //ModalComponent is component name where modal is declare
      localStorage.removeItem("ModalData");
      localStorage.setItem("ModalData",customerPrescriptionId);
      const modalRef = this.modalService.open(CommentModalComponent);
      modalRef.result.then((result) => {
        console.log(result);
      }).catch((error) => {
        console.log(error);
      });
    }

    delete(id : number){
      Swal.fire({
        title: 'هل تريد مسح العنصر ؟',
        text: "لن يكون لك صلاحية إعادته مره اخرى",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'الغاء',
        confirmButtonText: 'امسح العنصر !'
      }).then((result) => {
        if (result.isConfirmed) {
          this.CustomerPrescriptionService.Delete(id).subscribe((res) => {
            Swal.fire({
              icon: "success",
              title: "تم المسح بنجاح",
              showConfirmButton: false,
              timer: 1500,
            });
            this.pageNumber=1;
    this.SchedulepageNumber = 1;
            this.GetCustomerScheduleDrugs();
        //  this.GetCustomerNormalDrugs();
         
          },(err) => {
            Swal.fire({
              icon: 'error',
              title: 'خطأ',
              text:err.error.message    
            })
          },() => {
            console.log("completed");
          })
        }
      }) 
    }
    DeleteNormalCustomerPrescription(id : number){
      Swal.fire({
        title: 'هل تريد مسح العنصر ؟',
        text: "لن يكون لك صلاحية إعادته مره اخرى",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'الغاء',
        confirmButtonText: 'امسح العنصر !'
      }).then((result) => {
        if (result.isConfirmed) {
          this.CustomerPrescriptionService.DeleteNormalCustomerPrescription(id).subscribe((res) => {
            Swal.fire({
              icon: "success",
              title: "تم المسح بنجاح",
              showConfirmButton: false,
              timer: 1500,
            });
            this.pageNumber=1;
            this.SchedulepageNumber = 1;
            // this.CustomerPrescription=this.CustomerPrescription.filter(x=>x.id!=id)
        //     this.GetCustomerScheduleDrugs();
         this.GetCustomerNormalDrugs();
          },(err) => {
            Swal.fire({
              icon: 'error',
              title: 'خطأ',
              text:err.error.message    
            })
          },() => {
            console.log("completed");
          })
        }
      }) 
    }

}
