<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card-body ">
                <div class="col-sm-12 mt-5 text-center">
                    <div class="row">
                        <div  class="col-12">
                            <h3 class="text-center Fbolder"> الأدوية الشهرية – المطلوب تجديدها</h3>
                            <a routerLink="/content/admin/ListDrugAlmostFinishedComponent" class="d-block">
                                <button  class="btn btn-primary pull-right" type="button">
                                    <span class="m-2">الأدوية الشهرية المنتهية</span>
                                </button>
                            </a>
                        </div>
                       
                    </div>
                    <div class="card mt-4">
                        <div class="table-responsive">
                            <table class="table table-responsive-sm  ">
                                <thead>
                                    <tr style="text-align: start !important;">
                                        <th scope="col"> </th>
                                        <th scope="col">إسم الروشته</th>
                                        <th scope="col">كود العميل</th>
                                        <th scope="col">اسم العميل</th>
                                        <th scope="col">موبايل العميل</th>
                                        <th scope="col">الصيدلي المتابع</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of AddictiveDrugsList; let y = index" style="text-align: start !important;">
                                        <td scope="row" class="p-2 trTableH">{{y + 1}}</td>
                                        <td class="p-2 trTableH" >{{item.title}}</td>
                                        <td class="p-2 trTableH" >{{item.customerCode}}</td>
                                        <td class="p-2 trTableH" >{{item.customerName}}</td>
                                        <td class="p-2 trTableH" >{{item.customerMobile}}</td>
                                        <td class="p-2 trTableH" >{{item.pharmacistName}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    </div>
                <h3 class="mt-4 Fbolder text-center">العملاء</h3>
                <div class="row mt-4">
                    <div class="col-6">
                        <div class="card widget-1 cardH">
                            <div class="card-header card-no-border">
                                <div>
                                    <h6 class="font-weight-bolder">إجمالي عدد العملاء</h6>
                                </div>
                            </div>
                            <div class="card-body pt-0 d-inline-block">
                                <ul class="user-list mt-4">
                                    <li>
                                        <div class="user-icon  maleBorder">
                                            <div class="user-box bg-light-light">
                                                <!-- <app-feather-icons class="maleColor" [icon]="'user-plus'"></app-feather-icons> -->
                                                <span class="ml-1 maleColor">ذكور</span>
                                            </div>
                                        </div>
                                        <div>

                                            <span class=" d-flex align-items-center">
                                                <h4 >{{Stats?.maleCustomers}}</h4></span>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="user-icon  femaleBorder">
                                            <div class="user-box bg-light-light">
                                                <!-- <app-feather-icons class="femaleColor" [icon]="'user-minus'"></app-feather-icons> -->
                                                <span class="ml-1 femaleColor">إناث</span>
                                            </div>
                                        </div>
                                        <div>
                                            <span class="d-flex align-items-center">
                                                <h4 >{{Stats?.femaleCustomers}}</h4></span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="card widget-1 cardH">
                            <div class="card-header card-no-border">
                                <div>
                                    <h6 class="font-weight-bolder">عدد العملاء الجدد</h6>
                                    <h6 class="text-danger">أخر 30 يوم</h6>
                                </div>
                            </div>
                            <div class="card-body pt-0 d-inline-block">
                                <ul class="user-list">
                                    <li>
                                        <div class="user-icon  maleBorder">
                                            <div class="user-box bg-light-light">
                                                <!-- <app-feather-icons class="maleColor" [icon]="'user-plus'"></app-feather-icons> -->
                                                <span class="ml-1 maleColor">ذكور</span>

                                            </div>
                                        </div>
                                        <div>

                                            <span class="d-flex align-items-center">
                                                <h4 >{{Stats?.newMaleCustomers}}</h4></span>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="user-icon  femaleBorder">
                                            <div class="user-box bg-light-light">
                                                <!-- <app-feather-icons class="femaleColor" [icon]="'user-minus'"></app-feather-icons> -->
                                                <span class="ml-1 femaleColor">إناث</span>
                                            </div>
                                        </div>
                                        <div>

                                            <span class=" d-flex align-items-center">
                                                <h4 >{{Stats?.newFemaleCustomers}}</h4></span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    </div>


                    <h3 class="mt-4 Fbolder text-center">طلبات الانضمام</h3>
                    <div class="row mt-4">
                    <div class="col-6">
                        <div class="card widget-1 cardH">
                            <div class="card-header card-no-border">
                                <div>
                                    <h6 class="font-weight-bolder">إجمالي عدد طلبات الانضمام</h6>
                                </div>
                            </div>
                            <div class="card-body pt-0 d-inline-block">
                                <ul class="user-list mt-4">
                                    <li>
                                        <div class="user-icon  maleBorder">
                                            <div class="user-box bg-light-light">
                                                <!-- <app-feather-icons class="maleColor" [icon]="'user-plus'"></app-feather-icons> -->
                                                <span class="ml-1 maleColor">ذكور</span>
                                            </div>
                                        </div>
                                        <div>

                                            <span class="d-flex align-items-center">
                                                <h4 >{{Stats?.maleRequests}}</h4></span>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="user-icon  femaleBorder">
                                            <div class="user-box bg-light-light">
                                                <!-- <app-feather-icons class="femaleColor" [icon]="'user-minus'"></app-feather-icons> -->
                                                <span class="ml-1 femaleColor">إناث</span>
                                            </div>
                                        </div>
                                        <div>

                                            <span class="d-flex align-items-center">
                                                <h4 >{{Stats?.femaleRequests}}</h4></span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="card widget-1 cardH">
                            <div class="card-header card-no-border">
                                <div>
                                    <h6 class="font-weight-bolder">إجمالي عدد طلبات الانضمام الجدد </h6>
                                    <h6 class="text-danger">أخر 30 يوم</h6>
                                </div>
                            </div>
                            <div class="card-body pt-0 d-inline-block">
                                <ul class="user-list">
                                    <li>
                                        <div class="user-icon  maleBorder">
                                            <div class="user-box bg-light-light">
                                                <!-- <app-feather-icons class="maleColor" [icon]="'user-plus'"></app-feather-icons> -->
                                                <span class="ml-1 maleColor">ذكور</span>
                                            </div>
                                        </div>
                                        <div>
                                            <span class=" d-flex align-items-center">
                                                <h4 >{{Stats?.newMaleRequests}}</h4></span>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="user-icon  femaleBorder">
                                            <div class="user-box bg-light-light">
                                                <!-- <app-feather-icons class="femaleColor" [icon]="'user-minus'"></app-feather-icons> -->
                                                <span class="ml-1 femaleColor">إناث</span>
                                            </div>
                                        </div>
                                        <div>
                                            <span class=" d-flex align-items-center">
                                                <h4 >{{Stats?.newFemaleRequests}}</h4></span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    </div>


                    <h3 class="mt-4 Fbolder text-center">الجداول الشهرية</h3>
                    <div class="row mt-4">
                    <div class="col-6">
                        <div class="card widget-1 cardH">
                            <div class="card-header card-no-border">
                                <div>
                                    <h6 class="font-weight-bolder">عدد الجداول الشهرية</h6>
                                </div>
                            </div>
                            <!-- <div class="card-body flexCutsom">
                              <div class="widget-content">
                                <div>
                                  <h4>{{Stats?.totalPrescriptions}}</h4>
                                </div>
                              </div>
                            </div> -->
                            <div class="card-body pt-0 d-inline-block">
                                <ul class="user-list">
                                    <li>
                                        <div class="user-icon  maleBorder">
                                            <div class="user-box bg-light-light">
                                                <!-- <app-feather-icons class="maleColor" [icon]="'user-plus'"></app-feather-icons> -->
                                                <span class="ml-1 maleColor">ذكور</span>
                                            </div>
                                        </div>
                                        <div>
                                            <span class=" d-flex align-items-center">
                                                <h4 >{{Stats?.totalMalePrescriptions}}</h4></span>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="user-icon  femaleBorder">
                                            <div class="user-box bg-light-light">
                                                <!-- <app-feather-icons class="femaleColor" [icon]="'user-minus'"></app-feather-icons> -->
                                                <span class="ml-1 femaleColor">إناث</span>
                                            </div>
                                        </div>
                                        <div>
                                            <span class=" d-flex align-items-center">
                                                <h4 >{{Stats?.totalFemalePrescriptions}}</h4></span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="card widget-1 cardH">
                            <div class="card-header card-no-border">
                                <div>
                                    <h6 class="font-weight-bolder">عدد الجداول الشهرية الجديدة</h6>
                                    <h6 class="text-danger">أخر 30 يوم</h6>
                                </div>
                            </div>
                            <!-- <div class="card-body flexCutsom">
                                <div class="widget-content">
                                  <div>
                                    <h4>{{Stats?.totalNewPrescriptions}}</h4>
                                  </div>
                                </div>
                              </div> -->
                              <div class="card-body pt-0 d-inline-block">
                                <ul class="user-list">
                                    <li>
                                        <div class="user-icon  maleBorder">
                                            <div class="user-box bg-light-light">
                                                <!-- <app-feather-icons class="maleColor" [icon]="'user-plus'"></app-feather-icons> -->
                                                <span class="ml-1 maleColor">ذكور</span>
                                            </div>
                                        </div>
                                        <div>
                                            <span class=" d-flex align-items-center">
                                                <h4 >{{Stats?.totalNewMalePrescriptions}}</h4></span>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="user-icon  femaleBorder">
                                            <div class="user-box bg-light-light">
                                                <!-- <app-feather-icons class="femaleColor" [icon]="'user-minus'"></app-feather-icons> -->
                                                <span class="ml-1 femaleColor">إناث</span>
                                            </div>
                                        </div>
                                        <div>
                                            <span class=" d-flex align-items-center">
                                                <h4 >{{Stats?.totalNewFemalePrescriptions}}</h4></span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    </div>


                    <h3 class="mt-4 Fbolder text-center">تنبيهات الأدوية</h3>
                    <div class="row mt-4">
                    <div class="col-6">
                        <div class="card widget-1 cardH">
                            <div class="card-header card-no-border">
                                <div>
                                    <h6 class="font-weight-bolder">عدد تنبيهات الأدوية</h6>
                                </div>
                            </div>
                            <div class="card-body pt-0 d-inline-block">
                                <ul class="user-list mt-4">
                                    <li>
                                        <div class="user-icon  maleBorder">
                                            <div class="user-box bg-light-light">
                                                <!-- <app-feather-icons class="maleColor" [icon]="'user-plus'"></app-feather-icons> -->
                                                <span class="ml-1 maleColor">ذكور</span>
                                            </div>
                                        </div>
                                        <div>

                                            <span class="d-flex align-items-center">
                                                <h4>{{Stats?.maleNotifications}}</h4></span>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="user-icon  femaleBorder">
                                            <div class="user-box bg-light-light">
                                                <!-- <app-feather-icons class="femaleColor" [icon]="'user-minus'"></app-feather-icons> -->
                                                <span class="ml-1 femaleColor">إناث</span>
                                            </div>
                                        </div>
                                        <div>

                                            <span class="d-flex align-items-center">
                                                <h4>{{Stats?.femaleNotifications}}</h4></span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="card widget-1 cardH">
                            <div class="card-header card-no-border">
                                <div>
                                    <h6 class="font-weight-bolder">عدد تنبيهات الأدوية الجديدة</h6>
                                    <h6 class="text-danger">أخر 30 يوم</h6>

                                </div>
                            </div>
                            <div class="card-body pt-0 d-inline-block">
                                <ul class="user-list">
                                    <li>
                                        <div class="user-icon  maleBorder">
                                            <div class="user-box bg-light-light">
                                                <!-- <app-feather-icons class="maleColor" [icon]="'user-plus'"></app-feather-icons> -->
                                                <span class="ml-1 maleColor">ذكور</span>
                                            </div>
                                        </div>
                                        <div>
                                            <span class="d-flex align-items-center">
                                                <h4>{{Stats?.newMaleNotifications}}</h4></span>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="user-icon  femaleBorder">
                                            <div class="user-box bg-light-light">
                                                <!-- <app-feather-icons class="femaleColor" [icon]="'user-minus'"></app-feather-icons> -->
                                                <span class="ml-1 femaleColor">إناث</span>
                                            </div>
                                        </div>
                                        <div>

                                            <span class="d-flex align-items-center">
                                                <h4>{{Stats?.newFemaleNotifications}}</h4></span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    </div>


                    <h3 class="mt-4 Fbolder text-center">قياسات الإنبودي</h3>
                    <div class="row mt-4">
                    <div class="col-6">
                        <div class="card widget-1 cardH">
                            <div class="card-header card-no-border">
                                <div>
                                    <h6 class="font-weight-bolder">عدد قياسات الإنبودي</h6>
                                </div>
                            </div>
                            <div class="card-body pt-0 d-inline-block">
                                <ul class="user-list mt-4">
                                    <li>
                                        <div class="user-icon  maleBorder">
                                            <div class="user-box bg-light-light">
                                                <!-- <app-feather-icons class="maleColor" [icon]="'user-plus'"></app-feather-icons> -->
                                                <span class="ml-1 maleColor">ذكور</span>
                                            </div>
                                        </div>
                                        <div>

                                            <span class="d-flex align-items-center">
                                                <h4>{{Stats?.maleInBodies}}</h4></span>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="user-icon  femaleBorder">
                                            <div class="user-box bg-light-light">
                                                <!-- <app-feather-icons class="femaleColor" [icon]="'user-minus'"></app-feather-icons> -->
                                                <span class="ml-1 femaleColor">إناث</span>
                                            </div>
                                        </div>
                                        <div>

                                            <span class="d-flex align-items-center">
                                                <h4>{{Stats?.femaleInBodies}}</h4></span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="card widget-1 cardH">
                            <div class="card-header card-no-border">
                                <div>
                                    <h6 class="font-weight-bolder">عدد قياسات الإنبودي الجديدة</h6>
                                    <h6 class="text-danger">أخر 30 يوم</h6>

                                </div>
                            </div>
                            <div class="card-body pt-0 d-inline-block">
                                <ul class="user-list">
                                    <li>
                                        <div class="user-icon  maleBorder">
                                            <div class="user-box bg-light-light">
                                                <!-- <app-feather-icons class="maleColor" [icon]="'user-plus'"></app-feather-icons> -->
                                                <span class="ml-1 maleColor">ذكور</span>
                                            </div>
                                        </div>
                                        <div>

                                            <span class="d-flex align-items-center">
                                                <h4 >{{Stats?.newMaleInBodies}}</h4></span>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="user-icon  femaleBorder">
                                            <div class="user-box bg-light-light">
                                                <!-- <app-feather-icons class="femaleColor" [icon]="'user-minus'"></app-feather-icons> -->
                                                <span class="ml-1 femaleColor">إناث</span>
                                            </div>
                                        </div>
                                        <div>

                                            <span class="d-flex align-items-center">
                                                <h4 >{{Stats?.newFemaleInBodies}}</h4></span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    </div>

                    <h3 class="mt-4 Fbolder text-center">الأدوية المعتمدة</h3>
                    <div class="row mt-4">
                    <div class="col-6">
                        <div class="card widget-1 cardH">
                            <div class="card-header card-no-border">
                                <div>
                                    <h6 class="font-weight-bolder">عدد الأدوية المعتمدة</h6>
                                </div>
                            </div>
                            <div class="card-body flexCutsom">
                                <div class="widget-content">
                                  <!-- <div class="widget-round ">
                                    <div class="bg-round">
                                      <div class="user-icon  ">
                                           <div class="user-box bg-light-light">
                                            <app-feather-icons class="maleColor" [icon]="'check-circle'"></app-feather-icons>
                                  </div>
                              </div>
                                    </div>
                                  </div> -->
                                  <div>
                                    <h4>{{Stats?.totalVerifiedDrugs}}</h4>
                                  </div>
                                </div>
                              </div>
                            
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="card widget-1 cardH">
                            <div class="card-header card-no-border">
                                <div>
                                    <h6 class="font-weight-bolder">عدد الأدوية المعتمدة الجديدة</h6>
                                    <h6 class="text-danger">أخر 30 يوم</h6>
                                </div>
                            </div>
                            <div class="card-body flexCutsom">
                                <div class="widget-content">
                                  <!-- <div class="widget-round ">
                                    <div class="bg-round">
                                      <div class="user-icon  ">
                                           <div class="user-box bg-light-light">
                                            <app-feather-icons class="maleColor" [icon]="'check-circle'"></app-feather-icons>
                                  </div>
                              </div>
                                    </div>
                                  </div> -->
                                  <div>
                                    <h4>{{Stats?.totalNewVerifiedDrugs}}</h4>
                                  </div>
                                </div>
                              </div>
                         
                        </div>
                    </div>
                    </div>


                    <h3 class="mt-4 Fbolder text-center">النصائح</h3>
                    <div class="row mt-4">
                    <div class="col-6">
                        <div class="card widget-1 cardH">
                            <div class="card-header card-no-border">
                                <div>
                                    <h6 class="font-weight-bolder">عدد النصائح</h6>
                                </div>
                            </div>
                            <div class="card-body flexCutsom">
                                <div class="widget-content">
                                  <!-- <div class="widget-round ">
                                    <div class="bg-round">
                                      <div class="user-icon  ">
                                           <div class="user-box bg-light-light">
                                            <app-feather-icons class="maleColor" [icon]="'info'"></app-feather-icons>
                                  </div>
                              </div>
                                    </div>
                                  </div> -->
                                  <div>
                                    <h4>{{Stats?.totalTips}}</h4>
                                  </div>
                                </div>
                              </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="card widget-1 cardH">
                            <div class="card-header card-no-border">
                                <div>
                                    <h6 class="font-weight-bolder">عدد النصائح الجديدة</h6>
                                    <h6 class="text-danger">أخر 30 يوم</h6>
                                </div>
                            </div>
                            <div class="card-body flexCutsom">
                                <div class="widget-content">
                                  <!-- <div class="widget-round ">
                                    <div class="bg-round">
                                      <div class="user-icon  ">
                                           <div class="user-box bg-light-light">
                                            <app-feather-icons class="maleColor" [icon]="'info'"></app-feather-icons>
                                  </div>
                              </div>
                                    </div>
                                  </div> -->
                                  <div>
                                    <h4>{{Stats?.totalNewTips}}</h4>
                                  </div>
                                </div>
                              </div>
                        </div>
                    </div>
                    </div>


                    <h3 class="mt-4 Fbolder text-center">القياسات الطبية</h3>
                    <div class="row mt-4">
                    <div class="col-6">
                        <div class="card widget-1 cardH">
                            <div class="card-header card-no-border">
                                <div>
                                    <h6 class="font-weight-bolder">إجمالي عدد القياسات الطبية</h6>
                                </div>
                            </div>
                            <div class="card-body pt-0 d-inline-block">
                                <ul class="user-list mt-4">
                                    <li>
                                        <div class="user-icon  maleBorder">
                                            <div class="user-box bg-light-light">
                                                <!-- <app-feather-icons class="maleColor" [icon]="'user-plus'"></app-feather-icons> -->
                                                <span class="ml-1 maleColor">ذكور</span>
                                            </div>
                                        </div>
                                        <div>

                                            <span class="d-flex align-items-center">
                                                <h4 >{{Stats?.maleMeasurements}}</h4></span>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="user-icon  femaleBorder">
                                            <div class="user-box bg-light-light">
                                                <!-- <app-feather-icons class="femaleColor" [icon]="'user-minus'"></app-feather-icons> -->
                                                <span class="ml-1 femaleColor">إناث</span>
                                            </div>
                                        </div>
                                        <div>

                                            <span class="d-flex align-items-center">
                                                <h4 >{{Stats?.femaleMeasurements}}</h4></span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="card widget-1 cardH">
                            <div class="card-header card-no-border">
                                <div>
                                    <h6 class="font-weight-bolder">إجمالي عدد القياسات الطبية الجديدة</h6>
                                    <h6 class="text-danger">أخر 30 يوم</h6>
                                </div>
                            </div>
                            <div class="card-body pt-0 d-inline-block">
                                <ul class="user-list">
                                    <li>
                                        <div class="user-icon  maleBorder">
                                            <div class="user-box bg-light-light">
                                                <!-- <app-feather-icons class="maleColor" [icon]="'user-plus'"></app-feather-icons> -->
                                                <span class="ml-1 maleColor">ذكور</span>
                                            </div>
                                        </div>
                                        <div>

                                            <span class="d-flex align-items-center">
                                                <h4>{{Stats?.newMaleMeasurements}}</h4></span>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="user-icon  femaleBorder">
                                            <div class="user-box bg-light-light">
                                                <!-- <app-feather-icons class="femaleColor" [icon]="'user-minus'"></app-feather-icons> -->
                                                <span class="ml-1 femaleColor">إناث</span>
                                            </div>
                                        </div>
                                        <div>

                                            <span class="d-flex align-items-center">
                                                <h4>{{Stats?.newFemaleMeasurements}}</h4></span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>

            </div>
          
            <div class="container mt-5">
                <div class="row ">
                    <div class="col-md-12">
                                <div class="container mt-5 d-flex justify-content-center">
            
                                    <div class="btn-group " style=" margin-left: -21px;" role="group" aria-label="Basic example">
                                        <button type="button"  (click)="getfrom('Today')"  [class.active]="activeButton === 'Today'" class="btn btn-primary d-flex bg-white" autofocus>
                                            <i data-feather="calendar"></i>
                                        <span class="ml-2">اليوم</span>    </button>
                                        <button type="button"  (click)="getfrom('Month')"   [class.active]="activeButton === 'Month'"  class="btn btn-primary d-flex">
                                            <i data-feather="calendar"></i>
                                            <span class="ml-2">الشهر</span>   </button>
                                        <button type="button" (click)="getfrom('Year')" [class.active]="activeButton === 'Year'"  class="btn btn-primary d-flex">
                                            <i data-feather="calendar"></i>
                                            <span class="ml-2">السنة</span>    </button>
                                        <button type="button" (click)="getfrom('All')"  [class.active]="activeButton === 'All'" class="btn btn-primary d-flex">
                                            <i data-feather="calendar"></i>
                                            <span class="ml-2">الكل</span>    </button>
                                    </div>
                                </div>
            

                    </div>
                </div>
            </div>
            <div class="container mt-5">
                <div class="row ">
                    <div class="col-md-12">
                        <h3 class="text-center Fbolder">العملاء في المحافظات</h3><br>
                        <div class=" " id="chartContainer1"><br>

                            <canvas id="myChart1"></canvas>
                        </div>

                    </div>
                </div>
            </div>
            <div class="container mt-5">
                <div class="row ">
                    <div class="col-md-12">
                        
                        <h3 class="text-center Fbolder">القياسات الطبية</h3><br>
                        <div class=" " id="chartContainer2"><br>

                            <canvas id="myChart2"></canvas>
                        </div>

                    </div>
                </div>
            </div>


            <div class="container mt-5">
                <div class="row ">
                    <div class="col-md-12">
                        <h3 class="text-center Fbolder">القياسات الطبية في المحافظات</h3><br>
                        <div class=" " id="chartContainer3"><br>

                            <canvas id="myChart3"></canvas>
                        </div>

                    </div>
                </div>
            </div>

            <div class="container mt-5">
                <div class="row ">
                    <div class="col-md-12">
                         
                        <h3 class="text-center Fbolder">الادوية الجدول في المحافظات</h3><br>
                        <div class=" " id="chartContainer4"><br>

                            <canvas id="myChart4"></canvas>
                        </div>

                    </div>
                </div>
            </div>

            <div class="col-sm-12 mt-5 text-center">
                <h3 class="text-center Fbolder">القياسات الطبية</h3>
                <div class="card mt-4">
                    <div class="table-responsive">
                        <table class="table table-responsive-sm  ">
                            <thead>
                                <tr style="text-align: start !important;">
                                    <th scope="col"> </th>
                                    <th scope="col">الأسم</th>
                                    <th scope="col">إجمالي</th>
                                    <th scope="col">ذكور</th>
                                    <th scope="col">إناث</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of MedicalMeasurmentsList; let y = index" style="text-align: start !important;">
                                    <td scope="row" class="p-2 trTableH">{{y + 1}}</td>
                                    <td class="p-2 trTableH" >{{item.title}}</td>
                                    <td class="p-2 trTableH">{{item.total}}</td>
                                    <td class="p-2 trTableH">{{item.totalMale}}</td>
                                    <td class="p-2 trTableH">{{item.totalFemale}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                </div>

                <div class="col-sm-12 mt-5 text-center">
                    <div class="row">
                        <div  class="col-12">
                            <h3 class="text-center Fbolder"> احدث 10 عملاء</h3>
                            <a routerLink="/content/admin/ListCustomerWithoutToken" class="d-block">
                                <button  class="btn btn-primary pull-right" type="button">
                                    <span class="m-2">العملاء الغير فعالين</span>
                                </button>
                            </a>
                        </div>
                       
                    </div>
                    <div class="card mt-4">
                        <div class="table-responsive">
                            <table class="table table-responsive-sm  ">
                                <thead>
                                    <tr style="text-align: start !important;">
                                        <th scope="col"> </th>
                                        <th scope="col">الأسم</th>
                                        <th scope="col">الموبايل</th>
                                        <th scope="col">النوع</th>
                                        <th scope="col">المحافظة</th>
                                        <th scope="col">العنوان</th>
                                        <th scope="col">الكود</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of TopNewCustomerList; let y = index" style="text-align: start !important;">
                                        <td scope="row" class="p-2 trTableH">{{y + 1}}</td>
                                        <td class="p-2 trTableH" >{{item.name}}</td>
                                        <td class="p-2 trTableH">{{item.mobile}}</td>
                                        <td class="p-2 trTableH">{{item.genderName}}</td>
                                        <td class="p-2 trTableH">{{item.districtName}}</td>
                                        <td class="p-2 trTableH">{{item.address}}</td>
                                        <td class="p-2 trTableH">{{item.code}}</td>

                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    </div>



                    



                      



                        <div class="col-sm-12 mt-5 text-center">
                            <h3 class="text-center Fbolder">اخر 10 عمليه ادوية عادية</h3>
                            <div class="card mt-4">
                                <div class="table-responsive">
                                    <table class="table table-responsive-sm  ">
                                        <thead>
                                            <tr style="text-align: start !important;">
                                                <th scope="col"> </th>
                                                <!-- <th scope="col">الاسم</th> -->
                                                <th scope="col">الدواء</th>
                                                <th scope="col">وقت الجرعة</th>
                                                <th scope="col">العدد</th>
                                                <th scope="col">من تاريخ</th>
                                                <th scope="col">الى تاريخ</th>
                                                <th scope="col">الايام</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of TopCustomerPrescriptionList; let y = index" (click)="GoToCustomerPrescriptionDetails(item.id)" style="text-align: start !important;  cursor: pointer;">
                                                <td scope="row" class="p-2 trTableH">{{y + 1}}</td>
                                                <!-- <td class="p-2 trTableH" >{{item.customerPrescriptionName}}</td> -->
                                                <td class="p-2 trTableH">{{item.drugName}}</td>
                                                <td class="p-2 trTableH">{{item.doseTimeTitle}}</td>
                                                <td class="p-2 trTableH">{{item.count}}</td>
                                                <td class="p-2 trTableH">{{item.startDate | date:"dd/MM/yyyy"}}</td>
                                                <td class="p-2 trTableH">{{item.endDate | date:"dd/MM/yyyy"}}</td>
                                                <td class="p-2 trTableH">{{item.days}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            </div>


                            <div class="col-sm-12 mt-5 text-center">
                                <h3 class="text-center Fbolder">اخر 10 عمليه قياسات طبية</h3>
                                <div class="card mt-4">
                                    <div class="table-responsive">
                                        <table class="table table-responsive-sm  ">
                                            <thead>
                                                <tr style="text-align: start !important;">
                                                    <th scope="col"> </th>
                                                    <th scope="col">اسم العميل</th>
                                                    <th scope="col">القياس الطبي</th>
                                                    <th scope="col">التاريخ</th>
                                                    <th scope="col">القيمة</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let item of TopNewCustomerMedicalMeasurementsList; let y = index" (click)="GoToCustomerMedicalMeasurementDetails(item.id)" style="text-align: start !important;  cursor: pointer;">
                                                    <td scope="row" class="p-2 trTableH">{{y + 1}}</td>
                                                    <td class="p-2 trTableH" >{{item.customerName}}</td>
                                                    <td class="p-2 trTableH" >{{item.medicalMeasurement}}</td>
                                                    <td class="p-2 trTableH">{{item.date | date:"dd/MM/yyyy"}}</td>
                                                    <td class="p-2 trTableH">{{item.value}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                </div>
    


                        <div class="col-sm-12 mt-5 text-center">
                            <div class="row">
                                <div  class="col-12">
                                    <h3 class="text-center Fbolder">اخر 10 ادوية لم يتم اعتمدها</h3>
                                    <a routerLink="/content/admin/AllUnverifiedDrugs" class="d-block">
                                        <button  class="btn btn-primary pull-right" type="button">
                                            <span class="m-2">ادوية لم يتم اعتمادها</span>
                                        </button>
                                    </a>
                                </div>
                            </div>
                           
                            <div class="card mt-4">
                                <div class="table-responsive">
                                    <table class="table table-responsive-sm  ">
                                        <thead>
                                            <tr style="text-align: start !important;">
                                                <th scope="col"> </th>
                                                <th scope="col">الاسم</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of TopUnverifiedDrugsList; let y = index" style="text-align: start !important;">
                                                <td scope="row" class="p-2 trTableH">{{y + 1}}</td>
                                                <td class="p-2 trTableH" >{{item.title}}</td>

                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            </div>



