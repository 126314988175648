import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
@Injectable({
  providedIn: 'root'
})
export class CustomerPrescriptionCommentService {


  public Data = new BehaviorSubject(null)

  constructor(private _HttpClient:HttpClient) { }
  Get(customerPrescriptionId):Observable<any>{
    return this._HttpClient.get(`${environment.Server_URL}/CustomerPrescriptionComment/Get?customerPrescriptionId=${customerPrescriptionId}`);
   }
   GetPaged(data:any):Observable<any>{
    return this._HttpClient.post(`${environment.Server_URL}/CustomerPrescriptionComment/Getlist`,data);
   }
   GetCustomerPrescriptionCommentById(id:any):Observable<any>{
    return this._HttpClient.get(`${environment.Server_URL}/CustomerPrescriptionComment/GetById?Id=${id}`);
   }
   Create(data):Observable<any>{
  
   return this._HttpClient.post(`${environment.Server_URL}/CustomerPrescriptionComment/Create`, data  );
  }

  Update(data):Observable<any>{
  
   return this._HttpClient.post(`${environment.Server_URL}/CustomerPrescriptionComment/Update`, data  );
  }

  Delete(id):Observable<any>{
   
   return this._HttpClient.get(`${environment.Server_URL}/CustomerPrescriptionComment/Delete?id=${id}` ); 
 }
}
