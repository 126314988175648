
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header" style="padding-bottom: 5px !important">
                    <div >
                        <h5 *ngIf="!update" class="pb-2">اضافة قياس بدني</h5>
                        <h5 *ngIf="update" class="pb-2">تعديل قياس بدني</h5>

                       <span> بالرجاء ملئ جميع الحقول </span>
                    </div>
                    <ng-template >
                        
                    </ng-template>
                </div>
                <div class="card-body">
                    <form [formGroup]="insertForm" (ngSubmit)="Submit()">

                    <div class="row m-0 mb-2">
                        <div class="col-md-6">
                            <label for=""> التاريخ
                            </label>
                              <div class="form-group">
                                 <input type="datetime-local" formControlName="Date" class="form-control" 
                                 [tooltip]="fc.Date.errors?.required ? ' التاريخ مطلوب' : null" required
                                 [isDisabled]="!((fc.Date.errors?.required && fc.Date.touched))"
                                 containerClass="" [class.is-valid]="fc.Date.valid && (fc.Date.touched)"
                                 [class.is-invalid]="fc.Date.invalid && (fc.Date.touched)"
                                 [max]="minDate()"
                                 >

                              </div>
                          </div>
                          <div class="col-md-6 text-center">
                              <div class="form-group">
                                <label >العميل </label>
                             <p class="mt-3">{{CustomerName}}</p>
          
                              </div>
                          </div>
                          <div class="col-md-6">
                            <label for=""> التعليق
                            </label>
                              <div class="form-group">
                                 <textarea type="text" class="form-control"    formControlName="Comment"  
                                 >
                                 </textarea>
                              </div>
                          </div>
                          <div class="col-md-6 my-3 " >
                           <input type="file" formControlName="test" accept="image/*,application/pdf" #file id="getLogo"
                            style="display: none" (change)="getLogoUrl($event)" />
                        <button type="button" onclick="document.getElementById('getLogo').click()"
                            class="btn btn-primary mt-3 d-block"  style="width: 60%;">
                            ارفاق ملف
                        </button>  
                        <img *ngIf="!viewPdf &&Uploaded " src="{{ imageLogo }}" style="width: 60%; height: 220px;" class="image-style p-2" />
                        <img   *ngIf="viewPdf &&Uploaded"  src="../../../../assets/images/pdfImage.png"  style="width: 60%; height: 200px;" (click)="openPdf()" >

                                    </div>
                                   
                        <div class="col-12 col-md-12">
                            <div *ngIf="update; else elseBlock">
                                <button [disabled]="disaple" class="btn btn-success pull-right" type="submit" >
                                  تعديل
                                </button>
                              </div>
                  
                              <ng-template #elseBlock>
                                <button [disabled]="disaple" class="btn btn-success pull-right" type="submit">
                                  إضافة
                                </button>
                              </ng-template>
                </div>
                </div>
                    </form>
    </div>
</div>
        </div>
    </div>
</div>