import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerService } from 'src/app/shared/API-Service/services/Customer.service';
import { RegisterRequestService } from 'src/app/shared/API-Service/services/register-request.service';
import { DistrictService } from 'src/app/shared/API-Service/services/district.service';
import Swal from 'sweetalert2';
import {Customer} from "../../../../shared/Models/customer";
import { UsersService } from 'src/app/shared/API-Service/services/users.service';
@Component({
  selector: 'app-insert-customer',
  templateUrl: './insert-customer.component.html',
  styleUrls: ['./insert-customer.component.css']
})
export class InsertCustomerComponent implements OnInit {
  id: string;
  update: boolean;
  insertForm: FormGroup;
  CustomerModal = new Customer();
  Districts:any[]=[];
  pharmacists:any[]=[]
  Customer: any;
  Gender=[{
    id:1,
    title:"ذكر",
  },
  {
    id:2,
    title:"انثى",
  }

]
  registerRequestId: any;
  disaple: boolean;
  constructor(private UsersService :UsersService,private RegisterRequestService:RegisterRequestService,private DistrictService:DistrictService, private CustomerService:CustomerService,private _formBuilder: FormBuilder,private router: Router,private route: ActivatedRoute) { 
    this.GetDistrict();
    this.initForm();
    this.Getpharmacist();
  }

  ngOnInit(): void {
    if(this.route.snapshot.paramMap.get("registerRequestId")){
      
     this.registerRequestId=this.route.snapshot.paramMap.get("registerRequestId");
     this.RegisterRequestService.GetRegisterRequestById(this.registerRequestId).subscribe(async res=>{
      this.Customer=  await res.data;
      this.initForm(this.Customer);
     })
    }
    if (this.route.snapshot.paramMap.get("id")) {
      this.id =this.route.snapshot.paramMap.get("id")
      this.CustomerService.GetCustomerById(this.id).subscribe(async (res:any)=>{
      this.Customer=  await res.data;
        this.initForm(this.Customer);
         this.insertForm.get('password').clearValidators();
      })
    
      this.update = true;
     
    } 
    else {
      this.initForm()
      this.update = false;
    }
  }
  Getpharmacist(){
    this.UsersService.GetPharmacists().subscribe((res)=>{
      this.pharmacists=res.data;
    })
  }
  initForm(Customer?: Customer) {
    this.insertForm = this._formBuilder.group({
      name: [Customer?.name || '', Validators.required],
      mobile: [Customer?.mobile || '', Validators.required],
      genderId: [Customer?.genderId || '', Validators.required],
      districtId: [Customer?.districtId || '', Validators.required],
      address: [Customer?.address || '', Validators.required],
      password: [Customer?.password || '', Validators.required],
      pharmacyCode: [Customer?.pharmacyCode || null],
      pharmacistId: [Customer?.pharmacistId || null],
    });
  }

  get fc() {
    return this.insertForm.controls;
  }

  GetDistrict(){
    this.DistrictService.Get().subscribe(res=>{
      this.Districts=res.data;
    })
  }
  Submit(){
    if (this.insertForm.status == "VALID") {
      this.disaple=true
      if (this.update == true) {
        this.UpdateCustomer();
      } else {
        this.InsertCustomer();
      }
    }
     else {
      this.insertForm.markAllAsTouched();
    }
  

  }
  UpdateCustomer(){
    const data ={
      "id": this.id,
      "name":this.insertForm.get('name').value,
      "mobile": this.insertForm.get('mobile').value,
      "genderId": Number(this.insertForm.get('genderId').value),
      "districtId": Number(this.insertForm.get('districtId').value),
      "address": this.insertForm.get('address').value,
      "password": this.insertForm.get('password').value,
      "pharmacyCode": this.insertForm.get('pharmacyCode').value,
      "pharmacistId": this.insertForm.get('pharmacistId').value,

    }

    this.CustomerService.Update(data).subscribe(
      (response) => {
      Swal.fire({
      icon: "success",
      title: "تم التعديل بنجاح",
      showConfirmButton: false,
      timer: 1500,
      });
      this.router.navigateByUrl("content/admin/ListCustomers");
      this.disaple=false
    },
      (err) => {
        this.disaple=false
        Swal.fire({
          icon: "error",
          title:err.error.message
          });
      }
  
      );
  }
  InsertCustomer(){
    let registerRequestId
    if(this.registerRequestId){
      registerRequestId=Number(this.registerRequestId)
    }
    else{
      registerRequestId=null
    }
      const data ={
        "name":this.insertForm.get('name').value,
        "mobile": this.insertForm.get('mobile').value,
        "genderId": Number(this.insertForm.get('genderId').value),
        "districtId": Number(this.insertForm.get('districtId').value),
        "address": this.insertForm.get('address').value,
        "password": this.insertForm.get('password').value,
        "pharmacyCode": this.insertForm.get('pharmacyCode').value,
        "registerRequestId":registerRequestId,
        "pharmacistId": this.insertForm.get('pharmacistId').value,

      }
    this.CustomerService.Create(data).subscribe(
      (response) => {
      Swal.fire({
      icon: "success",
      title: "تم الحفظ بنجاح",
      showConfirmButton: false,
      timer: 1500,
      });
      this.router.navigateByUrl("content/admin/ListCustomers");
      this.disaple=false

    },
      (err) => {
        this.disaple=false
        Swal.fire({
          icon: "error",
          title:err.error.message
          });
      }
            );
  }
  back(){
    this.router.navigateByUrl('/content/admin/ListCustomers',{ state: { wantSearchData: true } });
  }
}
