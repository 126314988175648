import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
@Injectable({
  providedIn: 'root'
})
export class LookupsService {
  public Data = new BehaviorSubject(null)

  constructor(private _HttpClient:HttpClient) { }
  
  GetRoles():Observable<any>{
    return this._HttpClient.get(`${environment.Server_URL}/Lookups/GetAllRoles`);
   }
   
   GetActions():Observable<any>{
    return this._HttpClient.get(`${environment.Server_URL}/Menu/GetActions`);
   }
   GetAllFormats():Observable<any>{
    return this._HttpClient.get(`${environment.Server_URL}/Lookups/GetAllFormats`);
   }

   GetDoseTypes():Observable<any>{
    return this._HttpClient.get(`${environment.Server_URL}/Lookups/GetDoseTypes`);
   }
   GetRegisterRequestStatus(){
    return this._HttpClient.get(`${environment.Server_URL}/Lookups/GetRegisterRequestStatus`);
   }

   GetDoseTimes():Observable<any>{
    return this._HttpClient.get(`${environment.Server_URL}/Lookups/GetDoseTimes`);
   }
   
   GetDoseQuantity():Observable<any>{
    return this._HttpClient.get(`${environment.Server_URL}/Lookups/GetDoseQuantity`);
   }
}
