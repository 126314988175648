
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <form [formGroup]="insertForm" (ngSubmit)="Submit()">
            <div class="card">
                <div class="card-header" style="padding-bottom: 5px !important">
                    <div >
                        <h5 *ngIf="!update" class="pb-2">اضافة روشته للعميل</h5>
                        <h5 *ngIf="update" class="pb-2"> تعديل روشته للعميل</h5>

                       <span> بالرجاء ملئ جميع الحقول </span>
                    </div>
                    <ng-template >
                        
                    </ng-template>
                </div>

</div>
<div class="card">
    <div class="card-body">

        <div class="row m-0 mb-2">
            <div class="col-md-6">
                <div class="form-group">
                  <label>أختر الدواء <span *ngIf="update" style="color:red"> (في حالة عدم وجود الدواء فانه يجب التحقق منه )</span></label>
                  <ng-select class="js-example-basic-single" formControlName="drugId"  [(ngModel)]="PresciptionForm.drugId"
                    aria-label="Default select example"  
                    [tooltip]="fc.drugId.errors?.required ? '  مطلوب' : null" required
                                 [isDisabled]="!((fc.drugId.errors?.required && fc.drugId.touched))"
                                 containerClass="" [class.is-valid]="fc.drugId.valid && (fc.drugId.touched)"
                                 [class.is-invalid]="fc.drugId.invalid && (fc.drugId.touched)"
                                 [style.border]="fc.drugId.touched ? (fc.drugId.invalid ? '1px solid #dc3545 !important' : (fc.drugId.valid && fc.drugId.touched ? '1px solid #28a745' : 'none')) : 'none'"

                    [items]="Drugs" bindLabel="title" bindValue="id">
                  </ng-select>
                </div>
              </div>
              <div class="col-md-6">
                <label for=""> البدايه
                </label>
                  <div class="form-group">
                     <input type="datetime-local" [min]="getMinDate()"  class="form-control"  formControlName="startDate"     [tooltip]="fc.startDate.errors?.required ? '  مطلوب' : null" required
                     [isDisabled]="!((fc.startDate.errors?.required && fc.startDate.touched))"
                     containerClass="" [class.is-valid]="fc.startDate.valid && (fc.startDate.touched)"
                     [class.is-invalid]="fc.startDate.invalid && (fc.startDate.touched)"   [(ngModel)]="PresciptionForm.startDate"
                     >

                  </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>أختر وقت الجرعه </label>
                  <ng-select class="js-example-basic-single" formControlName="doseTimeId"  [(ngModel)]="PresciptionForm.doseTimeId"
                    aria-label="Default select example" 
                    [tooltip]="fc.doseTimeId.errors?.required ? '  مطلوب' : null" required
                    [isDisabled]="!((fc.doseTimeId.errors?.required && fc.doseTimeId.touched))"
                    containerClass="" [class.is-valid]="fc.doseTimeId.valid && (fc.doseTimeId.touched)"
                    [class.is-invalid]="fc.doseTimeId.invalid && (fc.doseTimeId.touched)"
                    [style.border]="fc.doseTimeId.touched ? (fc.doseTimeId.invalid ? '1px solid #dc3545 !important' : (fc.doseTimeId.valid && fc.doseTimeId.touched ? '1px solid #28a745' : 'none')) : 'none'"
                    [items]="DoseTimes" bindLabel="title" bindValue="id">
                  </ng-select>
                </div>
              </div>
             
              <div class="col-md-6">
                <label for=""> عدد الساعات \ الايام
                </label>
                  <div class="form-group">
                     <input type="number" [min]="1" onlyNumbers  class="form-control"  formControlName="count"    [tooltip]="fc.count.errors?.required ? '  مطلوب' : null" required
                     [isDisabled]="!((fc.count.errors?.required && fc.count.touched))"
                     containerClass="" [class.is-valid]="fc.count.valid && (fc.count.touched)"
                     [class.is-invalid]="fc.count.invalid && (fc.count.touched)" [(ngModel)]="PresciptionForm.count"
                     >

                  </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>أختر كمية الجرعه </label>
                  <ng-select class="js-example-basic-single"  formControlName="doseQuantityId"  [(ngModel)]="PresciptionForm.doseQuantityId"
                    aria-label="Default select example"
                    [items]="DoseQuantity" bindLabel="title" bindValue="id">
                  </ng-select>
                </div>
              </div>
              <div class="col-md-6">
                <label for=""> عدد ايام اخذ الدواء
                </label>
                  <div class="form-group">
                     <input type="number" [min]="1" onlyNumbers  class="form-control"   formControlName="days"   
                       [tooltip]="fc.days.errors?.required ? '  مطلوب' : null" required
                     [isDisabled]="!((fc.days.errors?.required && fc.days.touched))"
                     containerClass="" [class.is-valid]="fc.days.valid && (fc.days.touched)"
                     [class.is-invalid]="fc.days.invalid && (fc.days.touched)" [(ngModel)]="PresciptionForm.days"
                     >

                  </div>
              </div>
              <div class="col-md-6">
                <label for=""> اشعارات
                </label>
                  <div class="form-group">
                     <input type="checkbox" [checked]="NotificationState" class="form-control"  (change)="checknotification($event)"   formControlName="notification"   [(ngModel)]="NotificationState"
                     >

                  </div>
              </div>
              <!-- <div class="col-md-6">
                <label for=""> عدد الدواء بالواحده
                </label>
                  <div class="form-group">
                     <input type="text"  class="form-control"   formControlName="drugItemCount"  [(ngModel)]="PresciptionForm.drugItemCount"

                     >

                  </div>
              </div>
              <div class="col-md-6">
                <label for=""> الكمية للواحده
                </label>
                  <div class="form-group">
                     <input type="text"  class="form-control"   formControlName="quantityPerItem"  [(ngModel)]="PresciptionForm.quantityPerItem"

                     >

                  </div>
              </div>
              <div class="col-md-6">
                <label for=""> ادوية اخرى
                </label>
                  <div class="form-group">
                     <input type="text"  class="form-control"   formControlName="otherDrug"  [(ngModel)]="PresciptionForm.otherDrug"

                     >

                  </div>
              </div> -->
              <div class="col-md-12">
                <label for=""> تعليق الصيدلي
                </label>
                  <div class="form-group">
                     <textarea type="text" class="form-control txtAreaH"    formControlName="pharmacistComment"   [(ngModel)]="PresciptionForm.pharmacistComment"
                     >
                     </textarea>
                  </div>
              </div>
            
            <div class="col-12 col-md-12 mt-3">
                <div *ngIf="update; else elseBlock">
                    <button [disabled]="disaple"  class="btn btn-success pull-right btn-block btn-lg" type="submit" >
                      تعديل
                    </button>
                  </div>
      
                  <ng-template #elseBlock>
                    <button [disabled]="disaple"  class="btn btn-success pull-right btn-block btn-lg" type="submit">
                      حفظ
                    </button>
                  </ng-template>
    </div>
    </div>
</div>
</div>

</form>
        </div>
    </div>
</div>