import { Component, ViewEncapsulation, HostListener, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Menu, NavService } from '../../services/nav.service';
import { LayoutService } from '../../services/layout.service';
import { PermissionsApiService } from 'src/app/shared/API-Service/services/Permissions-api.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SidebarComponent implements OnInit {
  public New: boolean;
  public iconSidebar;
  public menuItems: any[];
  public menuRolus: any[] = [];
  public donationRolus: any[];
  public url: any;
  public fileurl: any;
  // For Horizontal Menu
  public margin: any = 0;
  public width: any = window.innerWidth;
  public leftArrowNone: boolean = true;
  public rightArrowNone: boolean = false;

  Role: any;
  err: boolean;
  MenuByRoleId: any[] = [];
  Menu: any[] = [];
  MenuArry: any[] = [];
  children: any[] = [];
  actionId: any;
  MenuAction4: any[] = [];
  children2: any[] = [];
  MenuArry2: any[] = [];
  menuRolus2: any[] = [];

  constructor(private router: Router, public navServices: NavService, private PermissionsApiService: PermissionsApiService,
    public layout: LayoutService) {
   
  }
  GetMenuAndRoles(){
    this.Role = localStorage.getItem('WeCareRole');
    this.New = true;
    this.menuRolus = JSON.parse(localStorage.getItem('WeCaremenuRolus'));
    if (this.menuRolus?.length <= 1) {
      this.PermissionsApiService.GetByRoleId().subscribe({
        next: (data) => {
          var res = data;
          if (res.messageCode == 200) {
            this.err = false;
            this.MenuByRoleId = res.data.menus
            this.MenuByRoleId.forEach(element => {
              this.children = [];
              element.childMenu.forEach(child => {

                const action4 = child.actions.filter(x => x.actionId == 4)[0];
                this.children.push({ MenuAction: child.actions, actionId: action4 != undefined ? action4.actionId : null, path: child.url, title: child.name, type: "link" });
                this.children2.push({ MenuAction: child.actions, actionId: action4 != undefined ? action4.actionId : null, path: child.url, title: child.name, type: "link" });
              });


              this.MenuArry.push({ Veiw: element.Perrmission, Parentid: element.parentId, title: element.name, path: element.url, children: this.children, icon: element.icon });
            })
            localStorage.setItem("MenuByRoleId", JSON.stringify(this.children2));

            this.menuRolus.push(
              {
                headTitle1: "لوحة التحكم ",
                headTitle2: "إدارة المحتويات الموجودة",
              },
            );
            this.MenuArry.forEach(element => {

              this.menuRolus.push(
                //   {
                //   headTitle1: "لوحة التحكم ",
                //   headTitle2: "إدارة المحتويات الموجودة",
                // },
                {
                  title: element.title,
                  icon: element.icon,
                  type: "sub",
                  badgeType: "success",
                  Veiw: element.Veiw,
                  children: element.children
                },
              );

            });
            localStorage.setItem('WeCaremenuRolus', JSON.stringify(this.menuRolus))
          } else {
            this.err = true;
          }
        },
        error: (error) => {
          this.err = true;
          Swal.fire({
            icon: 'error',
            title: 'خطأ',
            text: error.error.message,
          })
        },
      }
      );
    }
    this.navServices.items.subscribe((res) => {
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          res.filter(items => {
            if (items.path === event.url) {
              this.setNavActive(items);
            }
            if (!items.children) { return false; }
            items.children.filter(subItems => {
              if (subItems.path === event.url) {
                this.setNavActive(subItems);
              }
              if (!subItems.children) { return false; }
              subItems.children.filter(subSubItems => {
                if (subSubItems.path === event.url) {
                  this.setNavActive(subSubItems);
                }
              });
            });
          });
        }
      });
    });
  }
  ngAfterViewInit() {
    // View-related operations here
  }
  ngOnInit(): void {
    this.GetMenuAndRoles();

  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.width = event.target.innerWidth - 500;
  }

  sidebarToggle() {
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar;
  }

  // Active Nave state
  setNavActive(item) {
    this.menuItems.filter(menuItem => {
      if (menuItem !== item) {
        menuItem.active = false;
      }
      if (menuItem.children && menuItem.children.includes(item)) {
        menuItem.active = true;
      }
      if (menuItem.children) {
        menuItem.children.filter(submenuItems => {
          if (submenuItems.children && submenuItems.children.includes(item)) {
            menuItem.active = true;
            submenuItems.active = true;
          }
        });
      }
    });
  }

  // Click Toggle menu
  toggletNavActive(item) {
    if (!item.active) {
      this.menuRolus.forEach(a => {
        if (this.menuRolus.includes(item)) {
          a.active = false;
        }
        if (!a.children) { return false; }
        a.children.forEach(b => {
          if (a.children.includes(item)) {
            b.active = false;
          }
        });
      });
    }
    item.active = !item.active;
  }

  // For Horizontal Menu
  scrollToLeft() {
    if (this.margin >= -this.width) {
      this.margin = 0;
      this.leftArrowNone = true;
      this.rightArrowNone = false;
    } else {
      this.margin += this.width;
      this.rightArrowNone = false;
    }
  }
  checking(menuItem: any) {
    if (menuItem?.type === 'sub' && menuItem.children.filter(x => x.actionId == 4).length>0)
      return true;
    else
      return false

  }

  scrollToRight() {
    if (this.margin <= -3051) {
      this.margin = -3464;
      this.leftArrowNone = false;
      this.rightArrowNone = true;
    } else {
      this.margin += -this.width;
      this.leftArrowNone = false;
    }
  }

  counter(i: number) {
    return new Array(i);
  }

}
