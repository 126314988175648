import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DrugsService } from '../../../../shared/API-Service/services/drugs.service';
import Swal from 'sweetalert2';
import {BaseComponent } from '../../../../shared/components/base/base.component'
import { DrugGroupsService } from '../../../../shared/API-Service/services/drug-groups.service';

@Component({
  selector: 'app-list-drugs',
  templateUrl: './list-drugs.component.html',
  styleUrls: ['./list-drugs.component.css']
})
export class ListDrugsComponent extends BaseComponent implements OnInit {
  Drugs:any;
  SearchForm={
    drugGroupId: "",
    name: "",
    StatusId:null
  }

  Status=[
    {
      id:1,
      title:'تم التحقق منه'
    },
    {
      id:2,
      title:'لم يتم التحقق منه'

  }]
  DrugGroups: any;
  constructor(private DrugGroupsService : DrugGroupsService,private DrugsService:DrugsService,private _Router:Router) { super()}

  ngOnInit(): void {
    this.getDrugs();
    this.GetDrugGroups();
    
  }
  GetDrugGroups(){
    this.DrugGroupsService.Get().subscribe(res=>{
      this.DrugGroups=res.data
    })
  }
  removeNullfromData(data: any): void {
    for (const prop in data) {
      console.log(data)
      if ( (data[prop] === null || (typeof data[prop] === 'string' && data[prop].trim() === ""))  &&   prop!='skipCount' && prop!='maxResultCount' ) {
        delete data[prop];
      }
    }
  }
  Search(){
    this.pageNumber=1;
    
    if(this.SearchForm.StatusId==1){
      this.pager.isVerified=true
    }
    else if (this.SearchForm.StatusId==2){
      this.pager.isVerified=false
    }
    else{
      this.pager.isVerified=null
    }
    this.pager.name=this.SearchForm.name,
    this.pager.drugGroupId= this.SearchForm.drugGroupId,
    this.removeNullfromData(this.pager)
    this.DrugsService.GetPaged(this.pager).subscribe((res) => {
      this.Drugs = res.data.items
      this.totalCount=res.data.totalCount;
    console.log(this.Drugs);
    
    },(err)=>{
      console.log('their is a problem');
    },() =>{
      console.log('Completed');
    });
  }
  pageChanged(event: any){
    this.pageNumber = event.page;// -1 * pageSize;
    this.pager.skipCount = (this.pageNumber - 1) * this.pager.maxResultCount;
    this.DrugsService.GetPaged(this.pager).subscribe((res) => {
      this.Drugs = res.data.items
      this.totalCount=res.data.totalCount;
    console.log(this.Drugs);
    
    },(err)=>{
      console.log('their is a problem');
    },() =>{
      console.log('Completed');
    });

  }
  getDrugs(){
    this.DrugsService.GetPaged(this.pager).subscribe((res) => {
      this.Drugs = res.data.items
      this.totalCount=res.data.totalCount;
    console.log(this.Drugs);
    
    },(err)=>{
      console.log('their is a problem');
    },() =>{
      console.log('Completed');
    });
  }
  update(id){
    console.log(id);
    this._Router.navigate(['/content/admin/update-Drug',id]);
    }
    verify(id){
      this._Router.navigate(['/content/admin/verify-Drug',id]);
      }
    delete(id : number){
      Swal.fire({
        title: 'هل تريد مسح العنصر ؟',
        text: "لن يكون لك صلاحية إعادته مره اخرى",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'الغاء',
        confirmButtonText: 'امسح العنصر !'
      }).then((result) => {
        if (result.isConfirmed) {
          this.DrugsService.Delete(id).subscribe((res) => {
            Swal.fire({
              icon: "success",
              title: "تم المسح بنجاح",
              showConfirmButton: false,
              timer: 1500,
            });
         this.getDrugs();
          },(err) => {
            Swal.fire({
              icon: 'error',
              title: 'خطأ',
              text:err.error.message    
            })
          },() => {
            console.log("completed");
          })
        }
      }) 
    }



}
