import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerInBodyTestService } from '../../../../shared/API-Service/services/customer-in-body-test.service';
import Swal from 'sweetalert2';
import {BaseComponent } from '../../../../shared/components/base/base.component'
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-list-customerinbodytest',
  templateUrl: './list-customerinbodytest.component.html',
  styleUrls: ['./list-customerinbodytest.component.css']
})
export class ListCustomerinbodytestComponent  extends BaseComponent  implements OnInit {

  Customerinbodytest:any
  CustomerId: any;
  constructor(private CustomerInBodyTestService:CustomerInBodyTestService,private route: ActivatedRoute,private _Router:Router) { super()}

  ngOnInit(): void {
  if(this.route.snapshot.paramMap.get("id")){
    this.CustomerId=this.route.snapshot.paramMap.get("id")
  }
    this.getCustomerInBodyTest();
    
  }
  pageChanged(event: any){
    this.pager.customerId=this.CustomerId
    this.pageNumber = event.page;// -1 * pageSize;
    this.pager.skipCount = (this.pageNumber - 1) * this.pager.maxResultCount;
    this.CustomerInBodyTestService.GetPaged(this.pager).subscribe((res) => {
      this.Customerinbodytest = res.data.items
      this.Customerinbodytest.forEach(element => {
        element.test=environment.ServerImages_URL+'/'+element.test
        element.ex=element.test.split('.').pop();
      });
      this.totalCount=res.data.totalCount;
    
    },(err)=>{
      console.log('their is a problem');
    },() =>{
      console.log('Completed');
    });

  }
  back(){
    this._Router.navigateByUrl('/content/admin/ListCustomers',{ state: { wantSearchData: true } });
  }
  getCustomerInBodyTest(){
    const data={
      "maxResultCount": this.pager.maxResultCount,
      "skipCount": this.pager.skipCount,
      "customerId":this.CustomerId
    }
    this.CustomerInBodyTestService.GetPaged(data).subscribe((res) => {
      this.Customerinbodytest = res.data.items
      this.Customerinbodytest.forEach(element => {
        element.test=environment.ServerImages_URL+'/'+element.test
        element.ex=element.test.split('.').pop();
      });
      this.totalCount=res.data.totalCount;
    
    },(err)=>{
      console.log('their is a problem');
    },() =>{
      console.log('Completed');
    });
  }
  OpenPdf(imagesView){
    window.open(imagesView, "_blank");
 }
 OpenImage(imagesView){
  Swal.fire({
    imageUrl: imagesView,
    imageHeight: 400,
    imageAlt: 'A tall image',
    confirmButtonText:"الغاء"
  })
}
  update(id){
    this._Router.navigate(['/content/admin/update-customerinbodytest',id,this.CustomerId]);
    }
New(){
  this._Router.navigate(['/content/admin/insert-customerinbodytest',this.CustomerId]);
}
    delete(id : number){
      Swal.fire({
        title: 'هل تريد مسح العنصر ؟',
        text: "لن يكون لك صلاحية إعادته مره اخرى",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'الغاء',
        confirmButtonText: 'امسح العنصر !'
      }).then((result) => {
        if (result.isConfirmed) {
          this.CustomerInBodyTestService.Delete(id).subscribe((res) => {
            Swal.fire({
              icon: "success",
              title: "تم المسح بنجاح",
              showConfirmButton: false,
              timer: 1500,
            });
         this.getCustomerInBodyTest();
          },(err) => {
            Swal.fire({
              icon: 'error',
              title: 'خطأ',
              text:err.error.message    
            })
          },() => {
            console.log("completed");
          })
        }
      }) 
    }

}
