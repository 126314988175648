import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
@Injectable({
  providedIn: 'root'
})
export class UsersService {
  public Data = new BehaviorSubject(null)
  constructor(private _HttpClient:HttpClient) { }
  GetPharmacists():Observable<any>{
    return this._HttpClient.get(`${environment.Server_URL}/User/PharmacistsForList`);
   }
  Get():Observable<any>{
    return this._HttpClient.get(`${environment.Server_URL}/User/Get`);
   }
   GetPaged(data:any):Observable<any>{
    return this._HttpClient.post(`${environment.Server_URL}/User/GetAllPaged`,data);
   }
   GetUserById(id:any):Observable<any>{
    return this._HttpClient.get(`${environment.Server_URL}/User/UserProfile?id=${id}`);
   }
   Create(data):Observable<any>{
  
   return this._HttpClient.post(`${environment.Server_URL}/User/Create`, data  );
  }
  ChangePassword(data):Observable<any>{
  
    return this._HttpClient.post(`${environment.Server_URL}/User/ChangePassword`, data  );
   }
  
  Update(data):Observable<any>{
  
   return this._HttpClient.post(`${environment.Server_URL}/User/Update`, data  );
  }

  Delete(id):Observable<any>{
   
   return this._HttpClient.get(`${environment.Server_URL}/User/Delete?id=${id}` ); 
 }
}
