
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header " style="padding-bottom: 5px !important">
                  <div class="row">
                    <div class="col-6">
                      <h5 *ngIf="!update" class="pb-2">اضافة عميل</h5>
                      <h5 *ngIf="update" class="pb-2">تعديل عميل</h5>

                     <span> بالرجاء ملئ جميع الحقول </span>
                  </div>
                  <div class="col-6">
                    <button class="back-button pull-right mb-4" (click)="back()">
                        <span>BACK</span>
                        <div class="arrow-container">
                          <div class="arrow"></div>
                        </div>
                        </button>
                </div>
                  </div>
                    
                </div>
                <div class="card-body">
                    <form [formGroup]="insertForm" (ngSubmit)="Submit()">

                    <div class="row m-0 mb-2">
                        <div class="col-md-6">
                          <label for=""> الاسم
                          </label>
                            <div class="form-group">
                               <input type="text"  class="form-control" formControlName="name" 
                               [tooltip]="fc.name.errors?.required ? ' الاسم مطلوب' : null" required
                               [isDisabled]="!((fc.name.errors?.required && fc.name.touched))"
                               containerClass="" [class.is-valid]="fc.name.valid && (fc.name.touched)"
                               [class.is-invalid]="fc.name.invalid && (fc.name.touched)"
                               >
                            </div>
                        </div>
                        <div class="col-md-6">
                            <label for=""> موبايل
                            </label>
                              <div class="form-group">
                                 <input type="tel" onlyNumbers [maxlength]="11" [minlength]="11"  class="form-control" formControlName="mobile" 
                                 [tooltip]="fc.mobile.errors?.required ? ' موبايل مطلوب' : null" required
                                 [isDisabled]="!((fc.mobile.errors?.required && fc.mobile.touched))"
                                 containerClass="" [class.is-valid]="fc.mobile.valid && (fc.mobile.touched)"
                                 [class.is-invalid]="fc.mobile.invalid && (fc.mobile.touched)"
                                 >
                              </div>
                          </div>
                          <div class="col-md-6">
                            <label for=""> النوع
                            </label>
                              <div class="form-group">
                                <ng-select
                                class="js-example-basic-single p-0"
                                name="Gender"
                                placeholder="إختر النوع"
                                formControlName="genderId"
                                required=""
                                [items]="Gender"
                                bindLabel="title"
                                bindValue="id"
                                [style.border]="fc.genderId.touched ? (fc.genderId.invalid ? '1px solid #dc3545 !important' : (fc.genderId.valid && fc.genderId.touched ? '1px solid #28a745' : 'none')) : 'none'"

                                >
                                </ng-select>
                              </div>
                          </div>
                        <div class="col-md-6">
                          <label for="">المحافظة</label>
                          <div class="form-group">
                                <ng-select
                                class="js-example-basic-single p-0"
                                name="District"
                                placeholder="إختر المحافظة"
                                formControlName="districtId"
                                required=""
                                [items]="Districts"
                                bindLabel="title"
                                bindValue="id"
                                [style.border]="fc.districtId.touched ? (fc.districtId.invalid ? '1px solid #dc3545 !important' : (fc.districtId.valid && fc.districtId.touched ? '1px solid #28a745' : 'none')) : 'none'"

                                >
                                </ng-select>
                            </div>
                        </div>
                 
                        <div class="col-md-6">
                            <label for=""> العنوان
                            </label>
                              <div class="form-group">
                                 <input type="text"  class="form-control" formControlName="address" 
                                 [tooltip]="fc.address.errors?.required ? ' العنوان مطلوب' : null" required
                                 [isDisabled]="!((fc.address.errors?.required && fc.address.touched))"
                                 containerClass="" [class.is-valid]="fc.address.valid && (fc.address.touched)"
                                 [class.is-invalid]="fc.address.invalid && (fc.address.touched)"
                                 >
                              </div>
                          </div>
                          <div class="col-md-6">
                            <label for=""> كود الصيدلية
                            </label>
                              <div class="form-group">
                                 <input type="text"  class="form-control" formControlName="pharmacyCode" 
                                 >
                              </div>
                          </div>
                          <div class="col-md-6">
                            <label for="">الصيدلي</label>
                            <div class="form-group">
                                  <ng-select
                                  class="js-example-basic-single p-0"
                                  name="District"
                                  placeholder="إختر الصيدلي"
                                  formControlName="pharmacistId"           
                                  [items]="pharmacists"
                                  bindLabel="name"
                                  bindValue="id"
                                  >
                                  </ng-select>
                              </div>
                          </div>
                          <div *ngIf="!update" class="col-md-6">
                            <label for=""> كلمة السر
                            </label>
                              <div class="form-group">
                                 <input type="password"  class="form-control" formControlName="password" 
                                 [tooltip]="fc.password.errors?.required ? ' كلمة السر مطلوب' : null" required
                                 [isDisabled]="!((fc.password.errors?.required && fc.password.touched))"
                                 containerClass="" [class.is-valid]="fc.password.valid && (fc.password.touched)"
                                 [class.is-invalid]="fc.password.invalid && (fc.password.touched)"
                                 >
                              </div>
                          </div>
                        <div class="col-12 col-md-12">
                            <div *ngIf="update; else elseBlock">
                                <button [disabled]="disaple" class="btn btn-success pull-right" type="submit" >
                                  تعديل
                                </button>
                              </div>
                  
                              <ng-template #elseBlock>
                                <button [disabled]="disaple" class="btn btn-success pull-right" type="submit">
                                  إضافة
                                </button>
                              </ng-template>
                </div>
                </div>
                    </form>
    </div>
</div>
        </div>
    </div>
</div>