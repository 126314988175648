<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header" style="padding-bottom: 5px !important">
                    <div class="row mb-2">
                        <div class="col-6">
                            <h5 class="pb-2">بحث</h5>
                        </div>
                    </div>
                </div>
                <div class="card-block ">
                    <br>
                    <div class="row m-0 mb-2 ">
                        <div class="col-md-4">
                            <label for=""> الاسم
                            </label>
                              <div class="form-group">
                                 <input type="text"  class="form-control"       (keyup.enter)="Search()" [(ngModel)]="SearchForm.name"
                                 >
                              </div>
                          </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label >أختر مجموعة الادوية </label>
                            <ng-select
                            class="js-example-basic-single p-0"
                            name="videoAlbum"
                            placeholder="إختر مجموعة الادوية"
                            [(ngModel)]="SearchForm.drugGroupId"
                            required=""
                            [items]="DrugGroups"
                            bindLabel="title"
                            bindValue="id"
                            (keyup.enter)="Search()"
                            >
                            </ng-select>
                      
                          </div>
                        </div>
                      
                          <div class="col-md-4 ">
                            <label for="">الحالة</label>
                            <div class="form-group">
                                  <ng-select
                                  class="js-example-basic-single p-0"
                                  name="District"
                                  placeholder="إختر الحالة"
                                  required=""
                                  [(ngModel)]="SearchForm.StatusId"
                                  [items]="Status"
                                  bindLabel="title"
                                  bindValue="id"
                                  (keyup.enter)="Search()"
                                  >
                                  </ng-select>
                              </div>
                        </div>
                          <div class="col-md-12">
                            <button class="btn btn-primary pull-right mb-2" (click)="Search()" type="submit">
                                بحث
                              </button>
                </div>
                </div>
                </div>
               
            </div>
            <div class="card">
                <div class="card-header" style="padding-bottom: 5px !important">
                    <div class="row">
                        <div class="col-6">
                            <h5 class="pb-2">قائمة الادوية</h5>
                        </div>
                        <div class="col-6 header-titles">
                            <a routerLink="/content/admin/insert-Drug" class="d-block">
                                <button appActionNew class="btn btn-success pull-right" type="button">
                                    <span class="m-2">إدخال  دواء</span>
                                    <i class="fa fa-plus"></i>
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="card-block row">
                    <div class="col-sm-12 col-lg-12 col-xl-12">
                        <div class="table-responsive">
                            <table class="table table-responsive-sm ">
                                <thead>
                                    <tr class=" " style="text-align: start !important;">
                                        <th scope="col"></th>
                                        <th scope="col">الأسم</th>
                                        <th scope="col">مجموعة الادوية</th>
                                        <th scope="col">الحالة</th>
                                        <!-- <th scope="col">التاريخ</th> -->
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let view of Drugs;let x = index" class=" " style="text-align: start !important;">
                                        <td scope="row" class="p-2">{{x+1}}</td>
                                        <td class="p-2">{{view.title}}</td>
                                        <td class="p-2">{{view.drugGroupTitle}}</td>
                                        <td class="p-2" *ngIf="view.isVerified">تم التحقق منه</td>
                                        <td class="p-2" *ngIf="!view.isVerified">لم يتم التحقق منه</td>
                                        <!-- <td class="p-2">{{view.date | date:"dd/MM/yyyy"}}</td> -->
                                            <td class="font-style chose">
                                                <button appActionVerifyDrug  *ngIf="!view.isVerified" class="btn pull-right"
                                                style="color: rgba(35, 118, 241, 0.667); padding: 7px"
                                                (click)="verify(view.id)">
                                                <i class="fa fa-check-square-o"></i>
                                            </button>
                                                <button appActionNew *ngIf="view.isVerified" class="btn pull-right"
                                                style="color: rgba(35, 118, 241, 0.667); padding: 7px"
                                                (click)="update(view.id)">
                                                <i class="fa fa-pencil"></i>
                                            </button>
                                                   <button appActionNew class="btn  pull-right"  style="color: red; padding: 7px"
                                                   (click)="delete(view.id)">
                                                    <i class="fa fa-trash"></i>
                                                       </button>
                                               
                                            
                                            </td>
                                        </tr>
                                </tbody>
                            </table>
                            <div class="d-flex justify-content-center">
                                <pagination [boundaryLinks]="true" [maxSize]="20" [totalItems]="totalCount" previousText="&lsaquo;" nextText="&rsaquo;"
                                  firstText="&laquo;" lastText="&raquo;" [itemsPerPage]="pager.maxResultCount" [(ngModel)]="pageNumber"
                                  (pageChanged)="pageChanged($event)">
                                </pagination>
                              </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>




