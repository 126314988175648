
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header" style="padding-bottom: 5px !important">
                    <div >
                        <h5  *ngIf="!update" class="pb-2">اضافة مستخدم</h5>
                        <h5  *ngIf="update" class="pb-2">تعديل مستخدم</h5>

                       <span> بالرجاء ملئ جميع الحقول </span>
                    </div>
                    <ng-template >
                        
                    </ng-template>
                </div>
                <div class="card-body">
                    <form [formGroup]="insertForm" (ngSubmit)="Submit()">

                    <div class="row m-0 mb-2">                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          
                        <div class="col-md-6">
                          <label for=""> الاسم
                          </label>
                            <div class="form-group">
                               <input type="text"  class="form-control" formControlName="name" 
                               [tooltip]="fc.name.errors?.required ? ' الاسم مطلوب' : null" required
                               [isDisabled]="!((fc.name.errors?.required && fc.name.touched))"
                               containerClass="" [class.is-valid]="fc.name.valid && (fc.name.touched)"
                               [class.is-invalid]="fc.name.invalid && (fc.name.touched)"
                               >
                            </div>
                        </div>
                        <div class="col-md-6">
                            <label for=""> الصلاحية
                            </label>
                              <div class="form-group">
                                <ng-select
                                class="js-example-basic-single p-0"
                                name="Roles"
                                placeholder="إختر الصلاحية"
                                formControlName="roleId"
                                required=""
                                [items]="Roles"
                                bindLabel="title"
                                bindValue="id"
                                [tooltip]="fc.roleId.errors?.required ? '  مطلوب' : null" required
                               [isDisabled]="!((fc.roleId.errors?.required && fc.roleId.touched))"
                               containerClass="" [class.is-valid]="fc.roleId.valid && (fc.roleId.touched)"
                               [class.is-invalid]="fc.roleId.invalid && (fc.roleId.touched)"
                               [style.border]="fc.roleId.touched ? (fc.roleId.invalid ? '1px solid #dc3545 !important' : (fc.roleId.valid && fc.roleId.touched ? '1px solid #28a745' : 'none')) : 'none'"
                                >
                                </ng-select>
                              </div>
                          </div>
                        <div class="col-md-6">
                            <label for=""> موبايل
                            </label>
                              <div class="form-group">
                                 <input type="tel" onlyNumbers [maxlength]="11" [minlength]="11"  class="form-control" formControlName="mobile" 
                                 [tooltip]="fc.mobile.errors?.required ? ' موبايل مطلوب' : null" required
                                 [isDisabled]="!((fc.mobile.errors?.required && fc.mobile.touched))"
                                 containerClass="" [class.is-valid]="fc.mobile.valid && (fc.mobile.touched)"
                                 [class.is-invalid]="fc.mobile.invalid && (fc.mobile.touched)"
                                 >
                              </div>
                          </div>
                
                          <div class="col-md-6" *ngIf="!update">
                            <label for=""> كلمة السر
                            </label>
                              <div class="form-group">
                                 <input type="password"  class="form-control" formControlName="password" 
                                 [tooltip]="fc.password.errors?.required ? ' كلمة السر مطلوب' : null" required
                                 [isDisabled]="!((fc.password.errors?.required && fc.password.touched))"
                                 containerClass="" [class.is-valid]="fc.password.valid && (fc.password.touched)"
                                 [class.is-invalid]="fc.password.invalid && (fc.password.touched)"
                                 >
                              </div>
                          </div>
                        <div class="col-12 col-md-12">
                            <div *ngIf="update; else elseBlock">
                                <button [disabled]="disaple" class="btn btn-success pull-right" type="submit" >
                                  تعديل
                                </button>
                              </div>
                  
                              <ng-template #elseBlock>
                                <button [disabled]="disaple" class="btn btn-success pull-right" type="submit">
                                  إضافة
                                </button>
                              </ng-template>
                </div>
                </div>
                    </form>
    </div>
</div>
        </div>
    </div>
</div>