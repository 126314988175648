
 <app-breadcrumb class="mt-5" [title]="'بيانات العميل وعمليه القياس الطبي'" [active_item]="'Form Wizard'"></app-breadcrumb>

 <div class="container-fluid">
<div class="row mt-5">
    <div class="col-6">
        <div class="card h-100">
            <div class="card-header"><h5 class="f-w-900">بيانات العميل</h5></div>
            <div class="card-body">
                <div class="mb-5">
                    <div class="mb-5">
                        <h5 class="f-w-900 mb-3">كود العميل</h5>
                        <h6 class="f-w-900">{{CustomerMedicalMeasurement.customerCode}}</h6>
                    </div>
                    <h5 class="f-w-900 mb-3">الاسم</h5>
                    <h6 class="f-w-900"> {{CustomerMedicalMeasurement.customerName}}</h6>

                </div>
                <div class="mb-5">
                    <h5 class="f-w-900 mb-3">النوع</h5>
                    <h6 class="f-w-900">{{CustomerMedicalMeasurement.customerGender}}</h6>
                </div>
                <div class="mb-5">
                    <h5  class="f-w-900 mb-3">الموبايل</h5>
                    <h6 class="f-w-900">{{CustomerMedicalMeasurement.customerMobile}}</h6>

                </div>
                <div class="mb-5">
                    <h5 class="f-w-900 mb-3">العنوان</h5>
                    <h6 class="f-w-900">{{CustomerMedicalMeasurement.customerAddress}}</h6>

                </div>
            </div>
        </div>
    </div>
    <div class="col-6">
        <div class="card h-100">
            <div class="card-header"><h5 class="f-w-900">بيانات عمليه القياس الطبي</h5></div>
            <div class="card-body">
                <div class="row ">
                    <div class="col-6 alignMeasurementData" >
                        <h5 class="f-w-900">{{CustomerMedicalMeasurement.medicalMeasurementTitle}} </h5>
                    </div>
                    <div class="col-6 text-center ">
                        <img src="{{ImgUrl+CustomerMedicalMeasurement.medicalMeasurementImage}}">
                    </div>

                </div>

                <div class="row ">
                    <div class="col-12 mb-5 ">
                        <h5 class="f-w-900 mb-3">القيمة</h5>
                        <h6 class="f-w-900">{{CustomerMedicalMeasurement.value}}</h6>
                    </div>
                    <div class="col-12 mb-5 ">
                        <h5 class="f-w-900 mb-3">التاريخ</h5>
                        <h6 class="f-w-900 dateFormat">{{formatDateTimeWithAMPM(CustomerMedicalMeasurement.date)}}</h6>
                    </div>
                    <div class="col-12 mb-5" >
                        <h5 class="f-w-900 mb-3">التعليق</h5>
                        <h6 class="f-w-900">{{CustomerMedicalMeasurement.comment}}</h6>
                        </div>
                  
                  
                </div>
            

            </div>
        </div>
    </div>

</div>
 </div>