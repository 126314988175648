import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  // public Data = new BehaviorSubject(null)

  constructor(private _HttpClient:HttpClient) { }
  
 
    GetPaged(data:any):Observable<any>{
      return this._HttpClient.post(`${environment.Server_URL}/Customer/GetAllPaged`,data);
     }
     GetCustomerById(id:any):Observable<any>{
      return this._HttpClient.get(`${environment.Server_URL}/Customer/CustomerProfile?id=${id}`);
     }
     Create(data):Observable<any>{

     return this._HttpClient.post(`${environment.Server_URL}/Customer/Create`, data  );
    }

    Update(data):Observable<any>{
    
     return this._HttpClient.post(`${environment.Server_URL}/Customer/Update`, data  );
    }

    Delete(id):Observable<any>{
     
     return this._HttpClient.get(`${environment.Server_URL}/Customer/Delete?id=${id}` ); 
   }
}
