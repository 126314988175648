import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MedicalMeasurementService } from 'src/app/shared/API-Service/services/medical-measurement.service';
import { CustomerMedicalMeasurementService } from 'src/app/shared/API-Service/services/customer-medical-measurement.service';
import Swal from 'sweetalert2';
import {CustomerMedicalMeasurement} from "../../../../shared/Models/CustomerMedicalMeasurement";
import { LookupsService } from '../../../../shared/API-Service/services/lookups.service';
import { RightPanelKeyboardHelper } from '@devexpress/analytics-core/analytics-internal';
@Component({
  selector: 'app-insert-customer-medical-measurement',
  templateUrl: './insert-customer-medical-measurement.component.html',
  styleUrls: ['./insert-customer-medical-measurement.component.css']
})
export class InsertCustomerMedicalMeasurementComponent implements OnInit {
  id: string;
  update: boolean;
  insertForm: FormGroup;
  CustomerMedicalMeasurementModal = new CustomerMedicalMeasurement();
  MedicalMeasurement:any;
  AllFormats: any;
  MedicalMeasurementFormdata = new FormData();
  Inputs: any[]=[]
  value:any;
  values:any[]=[];
  label: any;
  Allinputs: any[]=[];
  CustomerValue:any;
  CustomerValueY:any;
  CustomerValueX:any
  MaleFrom: any;
  MaleTo: any;
  MaleFromX: any;
  MaleFromY: any;

  MaleToY: any;
  MaleToX: any;

  FeMaleFrom: any;
  FeMaleTo: any;
  FeMaleFromX: any;
  FeMaleFromY: any;

  FeMaleToX: any;
  FeMaleToY: any;
  CustomerMedicalMeasurement: any;
  customerId: any;
  CustomerMedicalMeasurementValue: any;
  MedicalMeasurementId: any;
  disaple: boolean;
  currentDate: string;

  constructor(private LookupsService :LookupsService,private CustomerMedicalMeasurementService :CustomerMedicalMeasurementService,private MedicalMeasurementService:MedicalMeasurementService,private _formBuilder: FormBuilder,private router: Router,private route: ActivatedRoute) {
    this.GetMedicalMeasurement(); 
    this.currentDate = new Date().toISOString().split('T')[0]; // Get current date in ISO format

}
minDate(): string {
  // Get today's date and time
  const today = new Date();

  // Format date to be compatible with datetime-local input
  const formattedDate = today.toISOString().slice(0, 16);

  return formattedDate;
}
ngOnInit(): void {
  if (this.route.snapshot.paramMap.get("id")) {
    this.id =this.route.snapshot.paramMap.get("id")
    this.CustomerMedicalMeasurementService.GetCustomerMedicalMeasurementById(this.id).subscribe(async (res:any)=>{
    this.CustomerMedicalMeasurement=  await res.data;
    this.SelectMedicalMeasurement(this.CustomerMedicalMeasurement)
      this.initForm(this.CustomerMedicalMeasurement);
     if(this.Inputs){
       if(this.Inputs.length>1){
        this.CustomerValueX=this.Inputs[0]
        this.CustomerValueY=this.Inputs[1]

        this.CustomerValue=this.CustomerValueX+'/'+this.CustomerValueY

      
      }
      else{
        this.CustomerValue=this.Inputs[0]
      
      }
     }
     
    })
    this.update = true;
  }
   if(this.route.snapshot.paramMap.get("Customerid")){
    this.customerId=this.route.snapshot.paramMap.get("Customerid")
    this.initForm()

  }
  else {
    this.initForm()
    this.update = false;
  }
}
GetMedicalMeasurement(){
  this.MedicalMeasurementService.Get().subscribe(res=>{
    this.MedicalMeasurement=res.data;
  })
}
SelectMedicalMeasurement(event){
  this.CustomerValue=null
  this.MedicalMeasurementId=event.id;
  if(event.value){
    this.Inputs = event.value.split("/")

  }
  else{
    this.Inputs = event.formatValue.split("/")

  }
   this.Allinputs=[];
   this.Inputs.forEach(element => {
    this.Allinputs.push({"value":element,"for":"للذكور من","text":"","type":"من"})
    this.Allinputs.push({"value":element,"for":"للذكور الى","text":"","type":"الى"})
    this.Allinputs.push({"value":element,"for":"للإناث من","text":"","type":"من"})
    this.Allinputs.push({"value":element,"for":"للإناث الى","text":"","type":"الى"})
   });
  // this.Inputs.push({value: ""});
  // this.values.push({value: ""});
   this.label=this.Allinputs


}
initForm(CustomerMedicalMeasurementModal?: CustomerMedicalMeasurement) {

  this.insertForm = this._formBuilder.group({
    medicalMeasurementId: [CustomerMedicalMeasurementModal?.medicalMeasurementId || '', Validators.required],
    measurementDate: [CustomerMedicalMeasurementModal?.date || '', Validators.required],
    CustomerValue: [ ''],
    CustomerValueX: [  ''],
    CustomerValueY: [ ''],
    Comment: [ CustomerMedicalMeasurementModal?.comment || ''],
  });
}

get fc() {
  return this.insertForm.controls;
}
ValuesSet(){
if(this.Inputs.length>1){
  this.CustomerValue=this.CustomerValueX+'/'+this.CustomerValueY

  this.insertForm.get('CustomerValueX').setValidators(Validators.required)
  this.insertForm.get('CustomerValueY').setValidators(Validators.required)
  this.insertForm.get('CustomerValueX').updateValueAndValidity()
  this.insertForm.get('CustomerValueY').updateValueAndValidity()

  this.insertForm.get('CustomerValue').clearValidators()
  this.insertForm.get('CustomerValue').updateValueAndValidity()

}
else{
  this.insertForm.get('CustomerValue').setValidators(Validators.required)
  this.insertForm.get('CustomerValue').updateValueAndValidity()

  this.insertForm.get('CustomerValueY').clearValidators()
  this.insertForm.get('CustomerValueX').clearValidators()
  this.insertForm.get('CustomerValueY').updateValueAndValidity()
  this.insertForm.get('CustomerValueX').updateValueAndValidity()
}
}
Submit(){
  this.ValuesSet();
  if (this.insertForm.status == "VALID") {
    this.disaple=true

    if (this.update == true) {
      this.UpdateMedicalMeasurement();
    } else {
      this.InsertMedicalMeasurement();
    }
  }
   else {
    this.insertForm.markAllAsTouched();
  }


}
UpdateMedicalMeasurement(){
  const data= {
    "id":this.id,
    "value":String(this.CustomerValue),
    "date":this.insertForm.get('measurementDate').value,
    "medicalMeasurementId": this.insertForm.get('medicalMeasurementId').value,
    "comment":this.insertForm.get('Comment').value

  }
  this.removeNullfromData(data)

  this.CustomerMedicalMeasurementService.Update(data).subscribe(
    (response) => {
    Swal.fire({
    icon: "success",
    title: "تم التعديل بنجاح",
    showConfirmButton: false,
    timer: 1500,
    });
    this.router.navigate(["content/admin/ListCustomerMedicalMeasurement",this.customerId]);
    this.disaple=false

  },
    (err) => {
      this.disaple=false

      Swal.fire({
        icon: "error",
        title:err.error.message
        });
    }

    );
}
removeNullfromData(data: any): void {
  for (const prop in data) {
    if ( (data[prop] === null || (typeof data[prop] === 'string' && data[prop].trim() === ""))  &&   prop!='skipCount' && prop!='maxResultCount' ) {
      delete data[prop];
    }
  }
}
InsertMedicalMeasurement(){    

const data= {
  "customerId": Number(this.customerId),
  "medicalMeasurementId":  this.insertForm.get('medicalMeasurementId').value,
  "value": String(this.CustomerValue),
  "measurementDate":this.insertForm.get('measurementDate').value,
  "comment":this.insertForm.get('Comment').value
}
this.removeNullfromData(data)
     this.CustomerMedicalMeasurementService.Create(data).subscribe(
    (response) => {
    Swal.fire({
    icon: "success",
    title: "تم الحفظ بنجاح",
    showConfirmButton: false,
    timer: 1500,
    });
    this.router.navigate(["content/admin/ListCustomerMedicalMeasurement",this.customerId]);
    this.disaple=false

  },
    (err) => {
      this.disaple=false

      Swal.fire({
        icon: "error",
        title:err.error.message
        });
    }
          );
 

}


}
