import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
@Injectable({
  providedIn: 'root'
})
export class VideoAlbumService {

  public Data = new BehaviorSubject(null)

  constructor(private _HttpClient:HttpClient) { }
  Get():Observable<any>{
    return this._HttpClient.get(`${environment.Server_URL}/VideoAlbum/GetAllForList`);
   }
   GetPaged(data:any):Observable<any>{
    return this._HttpClient.post(`${environment.Server_URL}/VideoAlbum/Getlist`,data);
   }
   GetVideoAlbumById(id:any):Observable<any>{
    return this._HttpClient.get(`${environment.Server_URL}/VideoAlbum/GetById?Id=${id}`);
   }
   Create(data):Observable<any>{
  
   return this._HttpClient.post(`${environment.Server_URL}/VideoAlbum/Create`, data  );
  }

  Update(data):Observable<any>{
  
   return this._HttpClient.post(`${environment.Server_URL}/VideoAlbum/Update`, data  );
  }

  Delete(id):Observable<any>{
   
   return this._HttpClient.get(`${environment.Server_URL}/VideoAlbum/Delete?id=${id}` ); 
 }
}
