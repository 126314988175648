import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TipsService } from 'src/app/shared/API-Service/services/tips.service';
import Swal from 'sweetalert2';
import {Tip} from "../../../../shared/Models/tip";
import { TipCategoryService } from 'src/app/shared/API-Service/services/tip-category.service';
import { DrugGroupsService } from '../../../../shared/API-Service/services/drug-groups.service';
import { environment } from 'src/environments/environment.prod';
import { DatePipe } from '@angular/common';
import { AngularEditorConfig, AngularEditorModule } from '@kolkov/angular-editor';
import { AngularEditorValidateService } from 'src/app/shared/API-Service/services/angular-editor-validate.service';

@Component({
  selector: 'app-insert-tips',
  templateUrl: './insert-tips.component.html',
  styleUrls: ['./insert-tips.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class InsertTipsComponent implements OnInit {
  id: string;
  update: boolean;
  insertForm: FormGroup;
  Tip = new Tip();
  Tips:any;
  dropdownSettings: any = {};  
  TipCategory: any;
  selectedItems: any[]=[]
  DrugGroups:  any[]=[];
  DrugGroupsForEdit: any[]=[];
  TipsFormdata = new FormData();
  Image: File;
  imageLogo: string="";
  disaple: boolean;
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    enableToolbar: true,
      showToolbar: true,
          sanitize: false,

    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Cairo',
    fonts: [
      {class: 'cairo', name: 'Cairo'},
      {class: 'arial', name: 'Arial'},
      {class: 'times-new-roman', name: 'Times New Roman'},
      {class: 'calibri', name: 'Calibri'},
      {class: 'comic-sans-ms', name: 'Comic Sans MS'}
    ],
    toolbarHiddenButtons: [

      // ['bold']
      // ['toggleEditorMode']
      ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  }
  
  constructor(private AngularEditorValidateService:AngularEditorValidateService,public datepipe:DatePipe,private DrugGroupsService:DrugGroupsService,private TipCategoryService:TipCategoryService,private TipsService:TipsService,private _formBuilder: FormBuilder,private router: Router,private route: ActivatedRoute) { 

    // this.dropdownSettings = 
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'title',
      selectAllText: 'اختر الكل',
      unSelectAllText: 'الغاء اختر الكل',
      itemsShowLimit: 3,
      allowSearchFilter: true,
    };
    // this.initForm();

    this.GetTipCategory();

  this.GetDrugGroups();

  }
  ngOnInit(): void {

    if (this.route.snapshot.paramMap.get("id")) {
      this.id =this.route.snapshot.paramMap.get("id")
      this.TipsService.GetTipById(this.id).subscribe(async (res:any)=>{
      this.Tips=  await res.data;
    this.imageLogo=environment.ServerImages_URL+'/'+this.Tips.image
 //  this.Tips.date= this.datepipe.transform( this.Tips.date, 'yyyy-MM-dd');
   
this.Tips.drugGroups.forEach(element => {
        // const DrugGroups=   this.DrugGroups?.filter(x=>x.id==element)[0]
       
          this.DrugGroupsForEdit.push(element)

        
         });
    
      
         this.selectedItems=this.DrugGroupsForEdit
        this.initForm(this.Tips);
        
      })
      this.update = true;
    } else {
      this.initForm()
      this.update = false;
    }
  }
  GetTipCategory(){
    this.TipCategoryService.Get().subscribe(res=>{
      this.TipCategory=res.data;
    })
  }
  GetDrugGroups(){
    this.DrugGroupsService.Get().subscribe(async res=>{
      this.DrugGroups=await res.data;
    })
  }
  SelectTipCategory(event){

  }
  getLogoUrl(event: any) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.Image = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.imageLogo = reader.result as string;
        // this.logoForm?.append("image", this.Image);
      };
    }
  }
  noLeadingSpaceValidator(control: FormControl) {
    const value = control.value;
    // Check if the value starts with a space
    if (value && value.trimLeft() !== value) {
      return { leadingSpace: true };
    }
    return null;
  }
  initForm(Tip?: Tip) {
    this.insertForm = this._formBuilder.group({
      TipImage: [this.imageLogo || '', Validators.required],
      Title: [Tip?.title || '', [Validators.required,this.noLeadingSpaceValidator]],
      Description: [Tip?.description || '', Validators.required],
      Date: [Tip?.date || '', Validators.required],
      TipCategoryId: [Tip?.tipCategoryId || '', Validators.required],
      DrugGroupIds: [this.selectedItems || '', Validators.required],

    });
  }

  get fc() {
    return this.insertForm.controls;
  }

  Submit(){
    if(this.AngularEditorValidateService.AngularEditorValidate(this.insertForm.get('Description'))){
      this.insertForm.get('Description').setErrors({AngularEditorError:true})
      Swal.fire({
        icon: "warning",
        title:'يجب ادخال الوصف'
        });
    }
    if(!this.insertForm.value.TipImage){
      Swal.fire({
        icon: "warning",
        title:'يجب اضافة صورة'
        });
    }
    if(!this.insertForm.value.Description){
      Swal.fire({
        icon: "warning",
        title:'يجب ادخال الوصف'
        });
    }
    if (this.insertForm.status == "VALID") {
      this.disaple=true

      if (this.update == true) {
        this.UpdateTip();
      } else {
        this.InsertTip();
      }
    }
     else {
      this.insertForm.markAllAsTouched();
    }
  

  }
  UpdateTip(){
    this.TipsFormdata.set("Id", this.id);
    this.TipsFormdata.set("Title", this.insertForm.get("Title").value);
    this.TipsFormdata.set("Description", this.insertForm.get("Description").value);
    this.TipsFormdata.set("TipImage", this.Image);
    this.TipsFormdata.set("Date", this.insertForm.get("Date").value);
    this.TipsFormdata.set("TipCategoryId", this.insertForm.get("TipCategoryId").value);
    this.selectedItems.forEach(element => {
      this.TipsFormdata.append("DrugGroupIds", element.id);  
    });
    this.TipsService.Update(this.TipsFormdata).subscribe(
      (response) => {
      Swal.fire({
      icon: "success",
      title: "تم التعديل بنجاح",
      showConfirmButton: false,
      timer: 1500,
      });
      this.router.navigateByUrl("content/admin/ListTip");
      this.disaple=false

    },
      (err) => {
        this.disaple=false
        this.TipsFormdata.delete("DrugGroupIds")
        Swal.fire({
          icon: "error",
          title:err.error.message
          });
      }
  
      );
  }
  InsertTip(){    
    this.TipsFormdata.set("Title", this.insertForm.get("Title").value);
    this.TipsFormdata.set("Description", this.insertForm.get("Description").value);
    this.TipsFormdata.set("TipImage", this.Image);
    this.TipsFormdata.set("Date", this.insertForm.get("Date").value);
    this.TipsFormdata.set("TipCategoryId", this.insertForm.get("TipCategoryId").value);
    this.selectedItems.forEach(element => {
      this.TipsFormdata.append("DrugGroupIds", element.id);  
    });    this.TipsService.Create(this.TipsFormdata).subscribe(
      (response) => {
      Swal.fire({
      icon: "success",
      title: "تم الحفظ بنجاح",
      showConfirmButton: false,
      timer: 1500,
      });
      this.router.navigateByUrl("content/admin/ListTip");
      this.disaple=false

    },
      (err) => {
        this.disaple=false
        this.TipsFormdata.delete("DrugGroupIds")
        
        Swal.fire({
          icon: "error",
          title:err.error.message
          });
      }
            );
   
 
  }

}
