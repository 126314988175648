import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DrugGroupsService } from '../../../../shared/API-Service/services/drug-groups.service';
import Swal from 'sweetalert2';
import {BaseComponent } from '../../../../shared/components/base/base.component'

@Component({
  selector: 'app-list-drug-group',
  templateUrl: './list-drug-group.component.html',
  styleUrls: ['./list-drug-group.component.css']
})
export class ListDrugGroupComponent extends BaseComponent implements OnInit {
  DrugGroups:any
  constructor(private DrugGroupsService:DrugGroupsService,private _Router:Router) { super()}

  ngOnInit(): void {
    this.getDrugGroups();
    
  }
  pageChanged(event: any){
    this.pageNumber = event.page;// -1 * pageSize;
    this.pager.skipCount = (this.pageNumber - 1) * this.pager.maxResultCount;
    this.DrugGroupsService.GetPaged(this.pager).subscribe((res) => {
      this.DrugGroups = res.data.items
      this.totalCount=res.data.totalCount;
    console.log(this.DrugGroups);
    
    },(err)=>{
      console.log('their is a problem');
    },() =>{
      console.log('Completed');
    });

  }
  getDrugGroups(){
    const data={
      "maxResultCount": this.pager.maxResultCount,
      "skipCount": this.pager.skipCount,
    }
    this.DrugGroupsService.GetPaged(data).subscribe((res) => {
      this.DrugGroups = res.data.items
      this.totalCount=res.data.totalCount;
    console.log(this.DrugGroups);
    
    },(err)=>{
      console.log('their is a problem');
    },() =>{
      console.log('Completed');
    });
  }
  update(id){
    console.log(id);
    this._Router.navigate(['/content/admin/update-drug-group',id]);
    }

    delete(id : number){
      Swal.fire({
        title: 'هل تريد مسح العنصر ؟',
        text: "لن يكون لك صلاحية إعادته مره اخرى",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'الغاء',
        confirmButtonText: 'امسح العنصر !'
      }).then((result) => {
        if (result.isConfirmed) {
          this.DrugGroupsService.Delete(id).subscribe((res) => {
            Swal.fire({
              icon: "success",
              title: "تم المسح بنجاح",
              showConfirmButton: false,
              timer: 1500,
            });
         this.getDrugGroups();
          },(err) => {
            Swal.fire({
              icon: 'error',
              title: 'خطأ',
              text:err.error.message    
            })
          },() => {
            console.log("completed");
          })
        }
      }) 
    }

}
