import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GenericResponse } from 'src/app/shared/Models/GenericResponse';
import { environment } from 'src/environments/environment.prod';
import { GenericResponseSingle } from 'src/app/shared/Models/GenericResponseSingle';

@Injectable({
  providedIn: 'root'
})

export class PermissionsApiService {

      //#region Declare variables
      title:string;
      order:number;
      //#endregion
      
      //#region  constructor
      constructor(private http:HttpClient) { }
      //#endregion
    
        //#region Options
        httpOptionsWithTocken = { headers: new HttpHeaders({ 'Authorization': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJhaG1lZGFiZHVsbGFoMjUwIiwianRpIjoiZDIwZjU0MGUtMjhiNy00YmNjLWE4ZDgtNzkxNzA2YzJmZDRhIiwiZW1haWwiOiJhaG1lZGFiZHVsbGFoQHlhaG9vLmNvbSIsInVpZCI6IjBiMzg5N2FiLTQ2ZmMtNGM0Yy04MTYyLTRiNDExZTY4OWE1NCIsInJvbGVzIjoiVVNFUiIsImV4cCI6MTYzODM2OTM3NSwiaXNzIjoiU2VjdXJlQXBpIiwiYXVkIjoiU2VjdXJlQXBpVXNlciJ9.55LorE6Fclj3buy1Qw8wZ6CEe_ifW5jxwHe25wHRWsQ', 'Accept': ' */*' }) };
        //#endregion
    
        GetPermissions(): Observable<GenericResponse<any>> {
          return this.http.get<GenericResponse<any>>(`${environment.Server_URL}/PermissionSet/Get`);
        }
        GetPermissionsById(PermissionId): Observable<GenericResponse<any>> {
          return this.http.get<GenericResponse<any>>(`${environment.Server_URL}/PermissionSet/GetById?permissionSetId=${PermissionId}`);
        }
      
        InsertPermissions(Data): Observable<GenericResponseSingle<any>> {
          return this.http.post<GenericResponseSingle<any>>(`${environment.Server_URL}/PermissionSet/Create`,Data);
        }
    
        GetMenu(): Observable<any> {
          return this.http.get<GenericResponse<any>>(`${environment.Server_URL}/Menu/Get`);
        }
        GetByRoleId(): Observable<any> {
          return this.http.get<GenericResponse<any>>(`${environment.Server_URL}/Menu/GetByRoleId`);
        }
        UpdatePermissions(data:any): Observable<GenericResponseSingle<any>>{
          return this.http.post<GenericResponseSingle<any>>(`${environment.Server_URL}/PermissionSet/Update`,data);
        }
        // UpdateCallReason(id:number,Data:InsertCall): Observable<GenericResponseSingle<getCities>> {
        //   return this.http.put<GenericResponseSingle<getCities>>(`${environment.Server_URL}/CallReason/${id}`,Data);
        // }
    
        DeletePermissions(Id): Observable<GenericResponseSingle<any>> {
          return this.http.get<GenericResponseSingle<any>>(`${environment.Server_URL}/PermissionSet/Delete?id=${Id}`);
          // change detete
        }
}
