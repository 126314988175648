
         <!-- <ngx-spinner [fullScreen]="false" type="ball-clip-rotate-multiple" size="medium">
            <p class="loading">Loading Awesomeness...</p>
       </ngx-spinner>  -->
     

<div class="container-fluid">
<div class="row">

<div class="col-lg-5  col-sm-12 p-0  ">
 
   <div class="container-fluid">
     <div class="row">
       <div class="col-12 p-0 ">
         <div class="login-card">
           <div>
            
             <div id="Sign-User" class="login-main ">
               <div  class="text-center ">
                 <!-- <h1>We Care</h1> -->
                 <img class="img-fluid  for-light" src="../../../../assets/images/logo/صحتي final logo_SVG.svg" style="width: 40%"  alt="">

               </div>
               <form
                 class="theme-form needs-validation"
                 [formGroup]="person"
                (ngSubmit)="onSubmit()"
                 novalidate >
               <div class="form-row">

                 <label class="col-form-label col-12 TxtStart "> <span >رقم الموبايل</span>   </label>
 
                   <div class="col-12 col-sm-12">
          
                     <input
                   class="form-control TxtEnd"
                   required=""
                   type="tel"
                   onlyNumbers
                   [minlength]="11"
                   [maxlength]="11"
                   placeholder="mobile"
                   formControlName="mobile"
                   [tooltip]="fc.mobile.errors?.required ? '  مطلوب' : null" required
                   [isDisabled]="!((fc.mobile.errors?.required && fc.mobile.touched))"
                   containerClass="" [class.is-valid]="fc.mobile.valid && (fc.mobile.touched)"
                   [class.is-invalid]="fc.mobile.invalid && (fc.mobile.touched)"
                   (keyup.enter)="onSubmit()"
                 />
                   </div>
    
               <div class="col-12 mb-0 ">
 
                 <label class="col-form-label  col-12 TxtStart"> <span >كلمة المرور</span>    </label>
 
                 <div class="form-input position-relative">
 
 
                   <input
                     class="form-control  "
                     [type]="changetype? 'password' : 'text' "
                     required=""
                     placeholder="*********"
                     formControlName="password"
                     [tooltip]="fc.password.errors?.required ? '  مطلوب' : null" required
                     [isDisabled]="!((fc.password.errors?.required && fc.password.touched))"
                     containerClass="" [class.is-valid]="fc.password.valid && (fc.password.touched)"
                     [class.is-invalid]="fc.password.invalid && (fc.password.touched)"
                     (keyup.enter)="onSubmit()"
                     />
                     <label class="btn showPass" (click)='showPassword()'> {{ show ? 'اخفاء' : 'اظهار' }} كلمة المرور</label>

                     <!-- <div class="show-hide" style="margin: -35px -5px 0 0;" (click)="showPassword()" *ngIf="!show"><span class="show text-primary" > </span>
                     </div>
                     <div class="show-hide" style="margin: -35px -5px 0 0;" (click)="showPassword()" *ngIf="show"><span class="hide text-primary" > </span>
                     </div> -->
                 </div>
               </div>
               </div>
               
 
               <div class="form-group mb-0 mt-3">
 
                 <!-- ============================================================================================ -->
                                              <!-- This is the remember me tag -->
                 <!-- ============================================================================================= -->
                 <button class="btn btn-primary btn-block w-100"   type="submit" >
                 <label class="col-form-label "> 
                   <span class="w-100">تسجيل دخول</span>  
                 </label>
                 </button>
               </div>
               </form>
               <div style="text-align: center;">
                 <span class="copyRight"><br>
                   جميع الحقوق محفوظة لصيدليات الندى {{ today | date:'y'}} © 
                   <br>
                   تم التطوير بواسطة <a href="https://wecancity.com/" class="copyRight" target="_blank">wecancity</a>
                 </span>
               </div>
             </div>
           
           </div>
         </div>
       </div>
     </div>
   </div>

</div>

<div class="col-lg-7  p-0 d-none d-sm-block" >
  <img class="w-100 vh-100  "  alt=""
   src="../assets/images/Dashboard.png"
   alt="looginpage"
  />
    
  </div>
</div>

</div>


<!-- <ngx-spinner
[fullScreen]="false"
type="ball-clip-rotate-multiple"
size="medium"
>
<p class="loading">Loading Awesomeness...</p>
</ngx-spinner> -->
