import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Chart } from 'chart.js';
import {StatsDashboardService} from 'src/app/shared/API-Service/services/stats-dashboard.service'
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import { environment } from 'src/environments/environment.prod';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent extends BaseComponent implements OnInit {
  Stats: any;
  Graphs: any;
 ImgUrl= environment.ServerImages_URL+'/'
  public openTab: string = "All";
  public show : boolean = false
  MedicalMeasurments: any[]=[];
  CustomersOverDistrict: any;
  MeasurmentsOverDistrict: any;
  AddictiveDrugsOverDistrict: any;
  CustomersOverDistrictLabels:string[]=[]
  CustomersOverDistrictdata: number[] = [];
  MeasurmentsOverDistrictdata: number[] = [];InactiveCustomersList: any;
  TopNewCustomerList: any;
  AddictiveDrugsList: any;
  TopCustomerPrescriptionList: any;
  TopNewCustomerMedicalMeasurementsList: any;
  TopUnverifiedDrugsList: any;
  MeasurmentsOverDistrictLabels: string[]=[]
  AddictiveDrugsOverDistrictdata: number[] = [];;
  AddictiveDrugsOverDistrictLabels: string[]=[]
  MedicalMeasurmentsList: any[]=[];
  MedicalMeasurmentsdata: number[] = [];
  MedicalMeasurmentsLabels: string[]=[]
  data:  any = {};
  AddictiveDrugsPageNumber:any
  activeButton: string = '';
  toggle() {
    this.show = !this.show
  }
  constructor(private datePipe: DatePipe,private StatsDashboardService:StatsDashboardService,private router: Router) {
    super()

    this.data.today=this.convertUTCToLocal(new Date().toISOString())
    this.activeButton = 'Today';

   }
   convertUTCToLocal(utcTimestamp: string): string {
    const utcDate = new Date(utcTimestamp);
    const localDate = new Date(utcDate.getTime() - (utcDate.getTimezoneOffset() * 60000));
    const localISOString = localDate.toISOString();
    return localISOString.slice(0, 19) + 'Z'; // Keep the same format 'YYYY-MM-DDTHH:mm:ss'
  }
  // public tabbed(val: string) {
  //   this.openTab = val;
  //   this.filterData =
  //     val !== "All"
  //       ? this.transactions.filter((data: any) => {
  //           return data.type == this.openTab ? true : false;
  //         })
  //       : this.transactions;
  // }
  ngOnInit(): void {
    this.GetStats();
    this.GetMedicalMeasurmentsList();
    this.GetCustomersOverDistrict();
    this.GetMeasurmentsOverDistrict();
    this.GetAddictiveDrugsOverDistrict();
    this.GetMedicalMeasurments();
    this.GetTopNewCustomers();
    this.GetAddictiveDrugs();
    this.GetTopCustomerPrescription();
    this.GetTopNewCustomerMedicalMeasurements();
    this.GetTopUnverifiedDrugs();
  }
GetStats(){
  this.StatsDashboardService.GetStatistics().subscribe(res=>{
this.Stats=res.data
  })
}

GetTopNewCustomers(){
  this.StatsDashboardService.TopNewCustomers().subscribe(res=>{
this.TopNewCustomerList=res.data;
  })
}
GetAddictiveDrugs(){
  
this.StatsDashboardService.GetAddictiveDrugs().subscribe(res=>{
this.AddictiveDrugsList=res.data;
  })
}
PageChanged(event: any){
  this.pager.maxResultCount = event.page;// -1 * pageSize;
  this.pager.skipCount = (this.pageNumber - 1) * this.pager.maxResultCount;
this.GetAddictiveDrugs();
}


GetTopUnverifiedDrugs(){
  this.StatsDashboardService.TopUnverifiedDrugs().subscribe(res=>{
this.TopUnverifiedDrugsList=res.data;
  })
}
GetTopCustomerPrescription(){
  this.StatsDashboardService.GetTopCustomerPrescription().subscribe(res=>{
this.TopCustomerPrescriptionList=res.data;
  })
}

GetTopNewCustomerMedicalMeasurements(){
  this.StatsDashboardService.TopNewCustomerMedicalMeasurements().subscribe(res=>{
this.TopNewCustomerMedicalMeasurementsList=res.data;
  })
}
GetMedicalMeasurmentsList(){
  console.log(this.data)

  this.StatsDashboardService.GetMedicalMeasurmentsList(this.data).subscribe(res=>{
this.MedicalMeasurmentsList=res.data;
  })
}
GetCustomersOverDistrict(){
  this.StatsDashboardService.GetCustomersOverDistrict(this.data).subscribe(res=>{
    this.CustomersOverDistrict=res.data
    this.CustomersOverDistrictLabels=[]
    this.CustomersOverDistrictdata=[]
    this.CustomersOverDistrict.forEach(element => {
      this.CustomersOverDistrictLabels.push(element.district)
      this.CustomersOverDistrictdata.push(element.numberofcustomers)
    });
    this.createChart('chartContainer1','myChart1',this.CustomersOverDistrictLabels,this.CustomersOverDistrictdata,'العملاء')
  })
}

GetMedicalMeasurments(){
  this.StatsDashboardService.GetMedicalMeasurments(this.data).subscribe(res=>{
    this.MedicalMeasurments=res.data
    this.MedicalMeasurmentsLabels=[]
      this.MedicalMeasurmentsdata=[]
    this.MedicalMeasurments.forEach(element => {
      this.MedicalMeasurmentsLabels.push(element.medicalMeasurement)
      this.MedicalMeasurmentsdata.push(element.numberofmeasurements)
    });
    this.createChart('chartContainer2','myChart2',this.MedicalMeasurmentsLabels,this.MedicalMeasurmentsdata,'القياسات الطبية')
  })
}

OpenImage(image)
{
  Swal.fire({
    imageUrl: image,
  });
}
GetMeasurmentsOverDistrict(){
  this.StatsDashboardService.GetMeasurmentsOverDistrict(this.data).subscribe(res=>{
    this.MeasurmentsOverDistrict=res.data
    this.MeasurmentsOverDistrictLabels=[]
    this.MeasurmentsOverDistrictdata=[]
    this.MeasurmentsOverDistrict.forEach(element => {
      this.MeasurmentsOverDistrictLabels.push(element.governorate)
      this.MeasurmentsOverDistrictdata.push(element.numberOfMeasurements)
    });
    this.createChart('chartContainer3','myChart3',this.MeasurmentsOverDistrictLabels,this.MeasurmentsOverDistrictdata,'القياسات الطبية في المحافظات')
  })
}
GetAddictiveDrugsOverDistrict(){
  this.StatsDashboardService.GetAddictiveDrugsOverDistrict(this.data).subscribe(res=>{
    this.AddictiveDrugsOverDistrict=res.data
    this.AddictiveDrugsOverDistrictLabels=[]
    this.AddictiveDrugsOverDistrictdata=[]
    this.AddictiveDrugsOverDistrict.forEach(element => {
      this.AddictiveDrugsOverDistrictLabels.push(element.adistrict)
      this.AddictiveDrugsOverDistrictdata.push(element.numberOfAddictiveDrugsPrescribed)
    });
    this.createChart('chartContainer4','myChart4',this.AddictiveDrugsOverDistrictLabels,this.AddictiveDrugsOverDistrictdata,'الادوية الجدول')
  })
}

createChart(chartContainerName:string,ChartName:string,labels:any,data:any,labelText:string){
  
  document.getElementById(chartContainerName).innerHTML = '&nbsp;';
    document.getElementById(chartContainerName).innerHTML = '<canvas id="'+ChartName+'"></canvas>';
      const ctx = new Chart(ChartName, {
        type: 'bar',
        data: {
          
            labels: labels,
            datasets: [{
                label: labelText,
                data: data,
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)'
                ],
                borderWidth: 1
            }
        ]
        },
        options: {
          title: {
            display: false,
            text: 'Color test'
          },
          legend: {
            position: 'top',
            display: true,
            fullWidth: true,
            labels: {
              fontSize: 15,
              fontFamily: 'Cairo',
              fontColor: 'grey',
            }
          },
          scales: {
            xAxes: [{
              gridLines: {
                    display: true,
                    color: "#E6D7D7"
                  },
              ticks: {
                beginAtZero: true,
                stepSize: 2 ,
                fontSize:15,
                fontFamily: 'Cairo',
                fontColor: "grey", // this here     
            }
            }],
            yAxes: [{
              gridLines: {
                display: true,
                color: "#E6D7D7"
              },
            }]
          }
        }
    });
  
}
removeNullfromData(data: any,type:any): void {
  for (const prop in data) {
    if ( prop !=type) {
      delete data[prop];
    }
  }
}
getfrom(type:string){
  this.activeButton = type;

  switch (type) {
  case 'Today':
    this.data.today = this.convertUTCToLocal(new Date().toISOString());
    this.removeNullfromData(this.data,"today")
    break;
  case 'Month':
    this.data.monthToDate = this.convertUTCToLocal(new Date().toISOString());
    this.removeNullfromData(this.data,"monthToDate")
    break;
  case 'Year':
    this.data.yearToDate = this.convertUTCToLocal(new Date().toISOString());
    this.removeNullfromData(this.data,"yearToDate")
    break;
  case 'All':
    this.removeNullfromData(this.data,"all")
    break;
  default:
    break;
  }
  this.GetMedicalMeasurmentsList();
    this.GetCustomersOverDistrict();
    this.GetMeasurmentsOverDistrict();
    this.GetAddictiveDrugsOverDistrict();
    this.GetMedicalMeasurments();
  console.log(this.data)
}

GoToCustomerPrescriptionDetails(id){
  this.router.navigate(['/content/admin/CustomerPrescriptionDetails',id]);
}

GoToCustomerMedicalMeasurementDetails(id){
  this.router.navigate(['/content/admin/CustomerMedicalMeasurementDetails',id]);
}

}
