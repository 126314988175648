import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerPrescriptionService } from 'src/app/shared/API-Service/services/customer-prescription.service';
import { CustomerService } from 'src/app/shared/API-Service/services/Customer.service';
import Swal from 'sweetalert2';
import {CustomerPrescriptionNormalDrug} from "../../../../shared/Models/CustomerPrescription";
import { environment } from 'src/environments/environment.prod';
import { DatePipe } from '@angular/common';
import { LookupsService } from '../../../../shared/API-Service/services/lookups.service';
import { DrugsService } from '../../../../shared/API-Service/services/drugs.service';
@Component({
  selector: 'app-insert-customer-normal-drug-presciption',
  templateUrl: './insert-customer-normal-drug-presciption.component.html',
  styleUrls: ['./insert-customer-normal-drug-presciption.component.css']
})
export class InsertCustomerNormalDrugPresciptionComponent implements OnInit {
  id: string;
  update: boolean;
  insertForm: FormGroup;
  PrescriptionFormGroup: FormGroup;
  CustomerPrescriptionModal = new CustomerPrescriptionNormalDrug();
  CustomerInBodyTest:any;
  CustomerId: any;
  CustomerName: any;
  Image: File;
  imageLogo: string="";
  CustomerInBodyTestForm=new FormData();
  viewPdf: boolean;
  Uploaded: boolean;
  DoseTypes:  any[]=[];
  DoseTimes:  any[]=[];
  Drugs: any[]=[];
  NotificationState:boolean=true
  customerPrescriptionDrugs:any[]=[]
  DoseQuantity:  any[]=[];
  disaple: boolean;

  PresciptionForm=    {
    drugId: "",
    doseTimeId: "",
    count:  "",
    startDate:  "",
    days: "",
    drugItemCount: "",
    quantityPerItem: "",
    doseQuantityId:  null,
    pharmacistComment: "",
    notification: "",
    otherDrug:""
}
  customerPrescriptionDrugsForTable: any[]=[];
  customerPrescription: any
  notification: boolean;
  startDate: string;

  constructor(private DrugsService :DrugsService,private LookupsService :LookupsService,public datepipe:DatePipe,private CustomerService:CustomerService,private CustomerPrescriptionService:CustomerPrescriptionService,private _formBuilder: FormBuilder,private router: Router,private route: ActivatedRoute) { 
    
    const localDate = new Date();
const year = localDate.getFullYear();
const month = String(localDate.getMonth() + 1).padStart(2, '0');
const day = String(localDate.getDate()).padStart(2, '0');
const hours = String(localDate.getHours()).padStart(2, '0');
const minutes = String(localDate.getMinutes()).padStart(2, '0');
const localDateTime = `${year}-${month}-${day}T${hours}:${minutes}`;
    this.startDate = localDateTime
    this.PresciptionForm.startDate=this.startDate
    this.initForm()
 
  }

  // Function to get the default start date
  getDefaultStartDate(): string {
    const now = new Date();
    // Convert the current date and time to a string in the format expected by datetime-local input
    const formattedDate = `${this.padZero(now.getFullYear())}-${this.padZero(now.getMonth() + 1)}-${this.padZero(now.getDate())}T${this.padZero(now.getHours())}:${this.padZero(now.getMinutes())}`;
    return formattedDate;
  }

  // Function to pad single-digit numbers with a leading zero
  padZero(num: number): string {
    return num < 10 ? `0${num}` : `${num}`;
  }

  // Function to get the minimum date (today)
  getMinDate(): string {
    const now = new Date();
    // Convert the current date to a string in the format expected by datetime-local input
    const formattedDate = `${this.padZero(now.getFullYear())}-${this.padZero(now.getMonth() + 1)}-${this.padZero(now.getDate())}T${this.padZero(now.getHours())}:${this.padZero(now.getMinutes())}`;
    return formattedDate;
  }
  ngOnInit(): void {
    this.GetDoseTimes();
    this.GetDrugs();
    this.GetDoseQuantity();

    if (this.route.snapshot.paramMap.get("id")) {
      this.id =this.route.snapshot.paramMap.get("id")
      this.CustomerPrescriptionService.GetCustomerNormalDrugsById(this.id).subscribe(res=>
      {
        this.CustomerPrescriptionModal=res.data;
        // this.NotificationState=this.CustomerPrescriptionModal.notification

        // if(this.customerPrescription.notification){
        //   this.customerPrescription.notification="مفعل"
        // }
        // else{
        //   this.customerPrescription.notification = "غير مفعل"
        // }    

//this.customerPrescriptionDrugs=this.customerPrescription.customerPrescriptionDrugs;
    //    this.customerPrescriptionDrugsForTable=this.customerPrescription.customerPrescriptionDrugs;

       this.initForm(this.CustomerPrescriptionModal)
      this.NotificationState=true;
      })
      this.update = true;
    } 
     if(this.route.snapshot.paramMap.get("Customerid")){
this.CustomerId=this.route.snapshot.paramMap.get("Customerid")
this.CustomerService.GetCustomerById(this.CustomerId).subscribe(res=>{
  this.CustomerName=res.data.name;
})
// this.initForm()
    }
    // else {
    //   this.initForm()
    //   this.update = false;
    // }
  }
  GetDrugs(){
    this.DrugsService.Get().subscribe(res=>{
      this.Drugs=res.data;
    })
  }

  GetDoseQuantity(){
    this.LookupsService.GetDoseQuantity().subscribe(res=>{
      this.DoseQuantity=res.data;
    })
      }
  GetDoseTimes(){
    this.LookupsService.GetDoseTimes().subscribe(res=>{
      this.DoseTimes=res.data;

    })
  }
  initForm(CustomerPrescriptionModal?: CustomerPrescriptionNormalDrug) {
    this.insertForm = this._formBuilder.group({
      drugId:[CustomerPrescriptionModal?.drugId||'',Validators.required],
      doseTimeId:[CustomerPrescriptionModal?.doseTimeId||'',Validators.required],
      count: [CustomerPrescriptionModal?.count||'',Validators.required],
      startDate: [CustomerPrescriptionModal?.startDate||this.startDate,Validators.required],
      days: [CustomerPrescriptionModal?.days||'',Validators.required],
      drugItemCount: [CustomerPrescriptionModal?.drugItemCount||''],
      quantityPerItem:[CustomerPrescriptionModal?.quantityPerItem||''],
      doseQuantityId: [CustomerPrescriptionModal?.doseQuantityId||''],
      pharmacistComment: [CustomerPrescriptionModal?.pharmacistComment||''],
      notification:[CustomerPrescriptionModal?.notification||''],
      otherDrug:[CustomerPrescriptionModal?.otherDrug||'']
    });

  }
  get fc() {
    return this.insertForm.controls;
  }

  Submit(){
    
    if (this.insertForm.status == "VALID") {
      this.disaple=true
      if (this.update == true) {
        this.UpdateCustomerPrescription();
      } else {
        this.InsertCustomerPrescription();
      }
    }
     else {
      this.insertForm.markAllAsTouched();
    }
  

  }
  checknotification(e){
    this.NotificationState=e.target.checked

  }
  removeNullfromData(data: any): void {
    for (const prop in data) {
      if ( (data[prop] === null || (typeof data[prop] === 'string' && data[prop].trim() === ""))  &&   prop!='skipCount' && prop!='maxResultCount' ) {
        delete data[prop];
      }
    }
  }
  UpdateCustomerPrescription(){
    // if(this.PresciptionForm.notification=="true"){
    //   this.notification=true;
    // }
    // else{
    //   this.notification=false;
    // }
    const data ={
      "id":this.id,
      "drugId": this.insertForm.get('drugId').value,
      "customerId": this.CustomerId,
      "doseTimeId": this.insertForm.get('doseTimeId').value,
      "count": this.insertForm.get('count').value,
      "startDate": this.insertForm.get('startDate').value,
      "days": this.insertForm.get('days').value,
      "doseQuantityId": this.insertForm.get('doseQuantityId').value,
      "pharmacistComment": this.insertForm.get('pharmacistComment').value,
      "notification": this.NotificationState,
      "otherDrug": this.insertForm.get('otherDrug').value
    }
    this.removeNullfromData(data);
    this.CustomerPrescriptionService.UpdateCustomerPrescriptionDrug(data).subscribe(
      (response) => {
      Swal.fire({
      icon: "success",
      title: "تم التعديل بنجاح",
      showConfirmButton: false,
      timer: 1500,
      });
      this.router.navigate(['/content/admin/ListCustomerPrescription',this.CustomerId],{state:{activeId:1}});
      this.disaple=false

    },
      (err) => {
        this.disaple=false

        Swal.fire({
          icon: "error",
          title:err.error.message
          });
      }
  
      );
  }
  InsertCustomerPrescription(){
    // if(this.PresciptionForm.notification){
    //   this.notification=true;
    // }
    // else{
    //   this.notification=false;
    // }

  const data ={
    "drugId": this.insertForm.get('drugId').value,
    "customerId": this.CustomerId,
    "doseTimeId": this.insertForm.get('doseTimeId').value,
    "count": this.insertForm.get('count').value,
    "startDate": this.insertForm.get('startDate').value,
    "days": this.insertForm.get('days').value,
    "doseQuantityId": this.insertForm.get('doseQuantityId').value,
    "pharmacistComment": this.insertForm.get('pharmacistComment').value,
    "notification": this.NotificationState,
    "otherDrug": this.insertForm.get('otherDrug').value
  }
  this.removeNullfromData(data);

    this.CustomerPrescriptionService.CreateCustomerPrescriptionDrug(data).subscribe(
      (response) => {
      Swal.fire({
      icon: "success",
      title: "تم الحفظ بنجاح",
      showConfirmButton: false,
      timer: 1500,
      });
      this.router.navigate(['/content/admin/ListCustomerPrescription',this.CustomerId]);
      this.disaple=false

    },
      (err) => {
        this.disaple=false

        Swal.fire({
          icon: "error",
          title:err.error.message
          });
      }
            );
   
 
  }


}
