import { Injectable } from '@angular/core';
import {AngularValidteFun} from 'src/app/shared/API-Service/services/AngularValidteFun'
@Injectable({
  providedIn: 'root'
})
export class AngularEditorValidateService  {
value
  constructor() { 
this.value="TestDirctive From Service"
  }
   AngularEditorValidate(control:any) {
    return AngularValidteFun(control);
  }
}
