
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header" style="padding-bottom: 5px !important">
                    <div >
                        <h5 *ngIf="!update" class="pb-2">اضافة دواء</h5>
                        <h5 *ngIf="update" class="pb-2">تعديل دواء</h5>

                       <span> بالرجاء ملئ جميع الحقول </span>
                    </div>
                    <ng-template >
                        
                    </ng-template>
                </div>
                <div class="card-body">
                    <form [formGroup]="insertForm" (ngSubmit)="Submit()">

                    <div class="row m-0 mb-2">
                        <div class="col-md-6">
                          <label for=""> الاسم
                          </label>
                            <div class="form-group">
                               <input type="text"  class="form-control" formControlName="title" 
                               [tooltip]="fc.title.errors?.required ? ' الاسم مطلوب' : null" required
                               [isDisabled]="!((fc.title.errors?.required && fc.title.touched))"
                               containerClass="" [class.is-valid]="fc.title.valid && (fc.title.touched)"
                               [class.is-invalid]="fc.title.invalid && (fc.title.touched)"
                               >
                            </div>
                        </div>

                        <div class="col-md-6">
                            <label for=""> الترتيب
                            </label>
                              <div class="form-group">
                                 <input type="number" [min]="1" onlyNumbers class="form-control" formControlName="order" 
                                 [tooltip]="fc.order.errors?.required ? ' الترتيب مطلوب' : null" required
                                 [isDisabled]="!((fc.order.errors?.required && fc.order.touched))"
                                 containerClass="" [class.is-valid]="fc.order.valid && (fc.order.touched)"
                                 [class.is-invalid]="fc.order.invalid && (fc.order.touched)"

                                 >
                              </div>
                          </div>
                    
                          <div class="col-md-6">
                            <div class="form-group">
                              <label >أختر مجموعة الادوية </label>
                              <ng-select
                              class="js-example-basic-single p-0"
                              name="videoAlbum"
                              placeholder="إختر مجموعة الادوية"
                              formControlName="drugGroupIds"
                              required=""
                              [items]="DrugGroups"
                              bindLabel="title"
                              bindValue="id"
                              [style.border]="fc.drugGroupIds.touched ? (fc.drugGroupIds.invalid ? '1px solid #dc3545 !important' : (fc.drugGroupIds.valid && fc.drugGroupIds.touched ? '1px solid #28a745' : 'none')) : 'none'"

                              >
                              </ng-select>
                        
                            </div>
                        </div>
                        <div class="col-md-4 my-3 " >
                            <input type="file" formControlName="drugImage" accept="image/*" #file id="getLogo"
                             style="display: none" (change)="getLogoUrl($event)" />
                         <button type="button" onclick="document.getElementById('getLogo').click()"
                             class="btn btn-primary mt-3 d-block"  style="width: 60%;">
                             ارفاق صورة
                         </button>  
                         <img *ngIf="imageLogo" src="{{ imageLogo }}" style="width: 60%; height: 220px;" class="image-style p-2" />
 
                                     </div>
                          <div class="col-md-12">
                            <label for=""> ارشادات المريض
                            </label>
                            <!-- <angular-editor  formControlName="patientcounseling"                                
                            [tooltip]="fc.patientcounseling.errors?.required ? ' ارشادات المريض مطلوب' : null" required
                            [isDisabled]="!((fc.patientcounseling.errors?.required && fc.patientcounseling.touched))"
                            containerClass="" [class.is-valid]="fc.patientcounseling.valid && (fc.patientcounseling.touched)"
                            [class.is-invalid]="fc.patientcounseling.invalid && (fc.patientcounseling.touched)" [config]="config" required=""></angular-editor> -->
                              <div class="form-group">


                                 <textarea type="text" style="height: 100px !important;" class="form-control" formControlName="patientcounseling" 
                                 [tooltip]="fc.patientcounseling.errors?.required ? ' ارشادات المريض مطلوب' : null" required
                                 [isDisabled]="!((fc.patientcounseling.errors?.required && fc.patientcounseling.touched))"
                                 containerClass="" [class.is-valid]="fc.patientcounseling.valid && (fc.patientcounseling.touched)"
                                 [class.is-invalid]="fc.patientcounseling.invalid && (fc.patientcounseling.touched)"
                                 ></textarea>
                              </div>
                          </div>
                         
                        <div class="col-12 col-md-12 mt-3">
                            <div *ngIf="update; else elseBlock">
                                <button  [disabled]="disaple"  *ngIf="!drugid" class="btn btn-success pull-right" type="submit" >
                                  تعديل
                                </button>
                                <button  [disabled]="disaple"  *ngIf="drugid" class="btn btn-success pull-right" type="submit" >
                                  تأكيد التحقق
                                </button>
                              </div>
                  
                              <ng-template #elseBlock>
                                <button  [disabled]="disaple"  class="btn btn-success pull-right" type="submit">
                                  إضافة
                                </button>
                              </ng-template>
                </div>
                </div>
                    </form>
    </div>
</div>
        </div>
    </div>
</div>