import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerInBodyTestService } from 'src/app/shared/API-Service/services/customer-in-body-test.service';
import { CustomerService } from 'src/app/shared/API-Service/services/Customer.service';
import Swal from 'sweetalert2';
import {CustomerInBodyTest} from "../../../../shared/Models/CustomerInBodyTest";
import { environment } from 'src/environments/environment.prod';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-insert-customerinbodytest',
  templateUrl: './insert-customerinbodytest.component.html',
  styleUrls: ['./insert-customerinbodytest.component.css']
})
export class InsertCustomerinbodytestComponent implements OnInit {
  id: string;
  update: boolean;
  insertForm: FormGroup;
  CustomerInBodyTestModal = new CustomerInBodyTest();
  CustomerInBodyTest:any;
  CustomerId: any;
  CustomerName: any;
  Image: File;
  imageLogo: string="";
  CustomerInBodyTestForm=new FormData();
  viewPdf: boolean;
  Uploaded: boolean;
  disaple: boolean;

  constructor(public datepipe:DatePipe,private CustomerService:CustomerService,private CustomerInBodyTestService:CustomerInBodyTestService,private _formBuilder: FormBuilder,private router: Router,private route: ActivatedRoute) { 
    // this.GetDrugGroups();
  }
  minDate(): string {
    // Get today's date and time
    const today = new Date();
  
    // Format date to be compatible with datetime-local input
    const formattedDate = today.toISOString().slice(0, 16);
  
    return formattedDate;
  }
  ngOnInit(): void {
    if (this.route.snapshot.paramMap.get("id")) {
      this.id =this.route.snapshot.paramMap.get("id")
      this.CustomerInBodyTestService.GetCustomerInBodyTestById(this.id).subscribe(async (res:any)=>{
      this.CustomerInBodyTest=  await res.data;
      this.CustomerName=this.CustomerInBodyTest.customerName
      this.CustomerId=this.CustomerInBodyTest.customerId
      // this.CustomerInBodyTest.date= this.datepipe.transform( this.CustomerInBodyTest.date, 'yyyy-MM-dd');
     const ex= this.CustomerInBodyTest.test.split('.').pop();
     if(ex=="pdf"){
       this.viewPdf=true;
       this.Uploaded=true;
     }
     else{
      this.viewPdf=false;
      this.Uploaded=true;
     }
      this.imageLogo=environment.ServerImages_URL+'/'+this.CustomerInBodyTest.test

        this.initForm(this.CustomerInBodyTest);
        
      })
      this.update = true;
    } 
    else if(this.route.snapshot.paramMap.get("CustomerId")){
this.CustomerId=this.route.snapshot.paramMap.get("CustomerId")
this.CustomerService.GetCustomerById(this.CustomerId).subscribe(res=>{
  this.CustomerName=res.data.name;
})
this.initForm()
    }
    else {
      this.initForm()
      this.update = false;
    }
  }
  initForm(CustomerInBodyTestModal?: CustomerInBodyTest) {
    this.insertForm = this._formBuilder.group({
      Date: [CustomerInBodyTestModal?.date || '', Validators.required],
      customerId: [this.CustomerId || '', Validators.required],
      test: [CustomerInBodyTestModal?.test || '', Validators.required],
      Comment:[CustomerInBodyTestModal?.comment||'']
    });
  }

  openPdf(){
    if(this.update){
      window.open(this.imageLogo, "_blank");
    }
  }
  get fc() {
    return this.insertForm.controls;
  }

  getLogoUrl(event: any) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.Image = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.imageLogo = reader.result as string;
          this.Uploaded=true
          if(this.Image.type=="application/pdf"){
            this.viewPdf=true;
          }
          else{
            this.viewPdf=false;
          }
        // this.logoForm?.append("image", this.Image);
      };
    }
  }
  Submit(){
    if (this.insertForm.status == "VALID") {
      this.disaple=true
      
      if (this.update == true) {
        this.UpdateCustomerInBodyTest();
      } else {
        this.InsertCustomerInBodyTest();
      }
    }
     else {
      if(!this.Image){
        Swal.fire({
          icon: "error",
          title:'من فضلك اختار ملف'
          });
      }
      this.insertForm.markAllAsTouched();
    }
  

  }
  UpdateCustomerInBodyTest(){
    this.CustomerInBodyTestForm.set("Id", this.id);
    this.CustomerInBodyTestForm.set("TestFile", this.Image);
    this.CustomerInBodyTestForm.set("Date", this.insertForm.get("Date").value);
    this.CustomerInBodyTestForm.set("CustomerId", this.CustomerId);
    this.CustomerInBodyTestForm.set("Comment", this.insertForm.get("Comment").value);

    this.CustomerInBodyTestService.Update(this.CustomerInBodyTestForm).subscribe(
      (response) => {
      Swal.fire({
      icon: "success",
      title: "تم التعديل بنجاح",
      showConfirmButton: false,
      timer: 1500,
      });
      this.router.navigate(['/content/admin/ListCustomerinbodytest',this.CustomerId]);
      this.disaple=false
    },
      (err) => {
        this.disaple=false
        Swal.fire({
          icon: "error",
          title:err.error.message
          });
      }
  
      );
  }
  InsertCustomerInBodyTest(){
    this.CustomerInBodyTestForm.set("TestFile", this.Image);
    this.CustomerInBodyTestForm.set("Date", this.insertForm.get("Date").value);
    this.CustomerInBodyTestForm.set("CustomerId", this.CustomerId);
    this.CustomerInBodyTestForm.set("Comment", this.insertForm.get("Comment").value);

    this.CustomerInBodyTestService.Create(this.CustomerInBodyTestForm).subscribe(
      (response) => {
      Swal.fire({
      icon: "success",
      title: "تم الحفظ بنجاح",
      showConfirmButton: false,
      timer: 1500,
      });
      this.router.navigate(['/content/admin/ListCustomerinbodytest',this.CustomerId]);
      this.disaple=false
    },
      (err) => {
        this.disaple=false
        Swal.fire({
          icon: "error",
          title:err.error.message
          });
      }
            );
   
 
  }

}
