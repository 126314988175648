<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header" style="padding-bottom: 5px !important">
          <div>
            <h5  *ngIf="!update" class="pb-2">اضافة قياس طبي</h5>
            <h5 *ngIf="update" class="pb-2">تعديل قياس طبي</h5>

            <span> بالرجاء ملئ جميع الحقول </span>
          </div>
          <ng-template>

          </ng-template>
        </div>
        <div class="card-body">
          <form [formGroup]="insertForm" (ngSubmit)="Submit()">

            <div class="row m-0 mb-2">
              <div class="col-md-6">
                <label for=""> الاسم
                </label>
                <div class="form-group">
                  <input type="text" class="form-control" formControlName="title"
                    [tooltip]="fc.title.errors?.required ? ' الاسم مطلوب' : null" required
                    [isDisabled]="!((fc.title.errors?.required && fc.title.touched))" containerClass=""
                    [class.is-valid]="fc.title.valid && (fc.title.touched)"
                    [class.is-invalid]="fc.title.invalid && (fc.title.touched)">
                </div>
              </div>
              <div class="col-md-6">
                <label for=""> الوحدة
                </label>
                <div class="form-group">
                  <input type="text" class="form-control" formControlName="unit"
                    [tooltip]="fc.unit.errors?.required ? ' الوحدة مطلوب' : null" required
                    [isDisabled]="!((fc.unit.errors?.required && fc.unit.touched))" containerClass=""
                    [class.is-valid]="fc.unit.valid && (fc.unit.touched)"
                    [class.is-invalid]="fc.unit.invalid && (fc.unit.touched)">
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <label>أختر الصيغة </label>
                  <ng-select class="js-example-basic-single" formControlName="formatId"
                    aria-label="Default select example" (change)="SelectAllFormats($event)" required="required"
                    [items]="AllFormats" bindLabel="value" bindValue="id"
                    [style.border]="fc.formatId.touched ? (fc.formatId.invalid ? '1px solid #dc3545 !important' : (fc.formatId.valid && fc.formatId.touched ? '1px solid #28a745' : 'none')) : 'none'"

                    >
                  </ng-select>
                </div>
              </div>
              <!-- <div class="col-md-12"> -->
              <!-- <ng-container *ngFor="let value of Allinputs; let i = index">

                            <div class="col-md-3">
                                   <div > 
                                    <br>
                                <label >{{label[i].for +' '+ label[i].value }} </label>
                                <input   class="form-control" type="text"  [(ngModel)]="value.text"   name="value{{i}}.value" id="value{{i}}.for" [ngModelOptions]="{standalone: true}">                           
                          </div>
                        
                            </div>
                          </ng-container> -->

              <div class="col-md-6 text-center">
                <label class="GenderLable">الذكور</label>
                <div class="row" *ngIf="Inputs.length<2 &&Inputs.length!=0">
                  <div class="col-md-6">
                    <label> من</label>
                    <input class="form-control" type="text"  formControlName="MaleFrom"
                    [tooltip]="fc.MaleFrom.errors?.required ? ' الحقل مطلوب' : null" required
                    [isDisabled]="!((fc.MaleFrom.errors?.required && fc.MaleFrom.touched))" containerClass=""
                    [class.is-valid]="fc.MaleFrom.valid && (fc.MaleFrom.touched)"
                    [class.is-invalid]="fc.MaleFrom.invalid && (fc.MaleFrom.touched)"   name="y" id="y"
                      [ngModelOptions]="{standalone: true}">
                  </div>


                  <div class="col-md-6">

                    <label>  الى </label>
                    <input class="form-control" type="text"  formControlName="MaleTo"
                    [tooltip]="fc.MaleTo.errors?.required ? ' الحقل مطلوب' : null" required
                    [isDisabled]="!((fc.MaleTo.errors?.required && fc.MaleTo.touched))" containerClass=""
                    [class.is-valid]="fc.MaleTo.valid && (fc.MaleTo.touched)"
                    [class.is-invalid]="fc.MaleTo.invalid && (fc.MaleTo.touched)"    name="y" id="y"
                      [ngModelOptions]="{standalone: true}">

                  </div>



                </div>


                <div class="row" *ngIf="Inputs.length>1&&Inputs.length!=0">
                  <div class="row">
                    
                 
                  <div class="col-md-2 ">
                    <div class="col-m-6" style="padding-top: 33px;">
                      <label>من</label>
                   
                    </div>

                  </div>
                  <div class="col-md-4 ">
                    <div class="col-m-6">
                      <label>y</label>

                        <input class="form-control" type="text"  formControlName="MaleFromY"
                        [tooltip]="fc.MaleFromY.errors?.required ? ' الحقل مطلوب' : null" required
                        [isDisabled]="!((fc.MaleFromY.errors?.required && fc.MaleFromY.touched))" containerClass=""
                        [class.is-valid]="fc.MaleFromY.valid && (fc.MaleFromY.touched)"
                        [class.is-invalid]="fc.MaleFromY.invalid && (fc.MaleFromY.touched)"   name="y" id="y"
                          [ngModelOptions]="{standalone: true}">



                    </div>

                  </div>
                  <div class="col-md-1 ">
                    
                    <div class="col-m-6" style="padding-top: 29px;">
                      <label style="font-size:x-large" class="Color">/</label>
                   
                    </div>
                   

                  </div>
                  <div class="col-md-4">
                    <div class="col-m-6">
                      <label> x </label>
                      <input class="form-control" type="text"  formControlName="MaleFromX"
                      [tooltip]="fc.MaleFromX.errors?.required ? ' الحقل مطلوب' : null" required
                      [isDisabled]="!((fc.MaleFromX.errors?.required && fc.MaleFromX.touched))" containerClass=""
                      [class.is-valid]="fc.MaleFromX.valid && (fc.MaleFromX.touched)"
                      [class.is-invalid]="fc.MaleFromX.invalid && (fc.MaleFromX.touched)"   name="y" id="y"
                        [ngModelOptions]="{standalone: true}">



                    </div>
                  </div>
                  
                </div>
<div class="row mt-3">
                  <div class="col-md-2 ">
                    <div class="col-m-6" style="padding-top: 33px;">
                      <label>الى</label>
                   
                    </div>

                  </div>
                   
                  <div class="col-md-4 ">
                    <div class="col-m-6">
                      <label>y</label>

                        <input class="form-control" type="text"  formControlName="MaleToY"
                        [tooltip]="fc.MaleFromX.errors?.required ? ' الحقل مطلوب' : null" required
                        [isDisabled]="!((fc.MaleToY.errors?.required && fc.MaleToY.touched))" containerClass=""
                        [class.is-valid]="fc.MaleToY.valid && (fc.MaleToY.touched)"
                        [class.is-invalid]="fc.MaleToY.invalid && (fc.MaleToY.touched)"   name="y" id="y"
                          [ngModelOptions]="{standalone: true}">



                    </div>

                  </div>
                  <div class="col-md-1 ">
                    
                    <div class="col-m-6" style="padding-top: 29px;">
                      <label style="font-size:x-large; " class="Color">/</label>
                   
                    </div>
                   

                  </div>
                  <div class="col-md-4">
                    <div class="col-m-6">
                      <label> x </label>
                      <input class="form-control" type="text"  formControlName="MaleToX"
                      [tooltip]="fc.MaleToX.errors?.required ? ' الحقل مطلوب' : null" required
                      [isDisabled]="!((fc.MaleToX.errors?.required && fc.MaleToX.touched))" containerClass=""
                      [class.is-valid]="fc.MaleToX.valid && (fc.MaleToX.touched)"
                      [class.is-invalid]="fc.MaleToX.invalid && (fc.MaleToX.touched)"   name="y" id="y"
                        [ngModelOptions]="{standalone: true}">



                    </div>
                  </div>
                 
                </div>
                </div>
              </div>




              <div class="col-md-6 text-center" style="border-right:6px solid #2e31b2">
                <label class="GenderLable">الإناث</label>
                <div class="row" *ngIf="Inputs.length<2 &&Inputs.length!=0">
                  <div class="col-md-6">
                    <label> من</label>
                    <input class="form-control" type="text"  formControlName="FeMaleFrom"
                    [tooltip]="fc.FeMaleFrom.errors?.required ? ' الحقل مطلوب' : null" required
                    [isDisabled]="!((fc.FeMaleFrom.errors?.required && fc.FeMaleFrom.touched))" containerClass=""
                    [class.is-valid]="fc.FeMaleFrom.valid && (fc.FeMaleFrom.touched)"
                    [class.is-invalid]="fc.FeMaleFrom.invalid && (fc.FeMaleFrom.touched)"    name="y" id="y"
                      [ngModelOptions]="{standalone: true}">
                  </div>


                  <div class="col-md-6">

                    <label> الى</label>
                    <input class="form-control" type="text"   formControlName="FeMaleTo"
                    [tooltip]="fc.FeMaleTo.errors?.required ? ' الحقل مطلوب' : null" required
                    [isDisabled]="!((fc.FeMaleTo.errors?.required && fc.FeMaleTo.touched))" containerClass=""
                    [class.is-valid]="fc.FeMaleTo.valid && (fc.FeMaleTo.touched)"
                    [class.is-invalid]="fc.FeMaleTo.invalid && (fc.FeMaleTo.touched)"   name="y" id="y"
                      [ngModelOptions]="{standalone: true}">

                  </div>



                </div>


              
                <div class="row" *ngIf="Inputs.length>1&&Inputs.length!=0">
                  <div class="row">

                  <div class="col-md-2 ">
                    <div class="col-m-6" style="padding-top: 33px;">
                      <label>من</label>
                   
                    </div>

                  </div>
                  <div class="col-md-4 ">
                    <div class="col-m-6">
                      <label>y</label>

                        <input class="form-control" type="text" formControlName="FeMaleFromY"
                        [tooltip]="fc.FeMaleFromY.errors?.required ? ' الحقل مطلوب' : null" required
                        [isDisabled]="!((fc.FeMaleFromY.errors?.required && fc.FeMaleFromY.touched))" containerClass=""
                        [class.is-valid]="fc.FeMaleFromY.valid && (fc.FeMaleFromY.touched)"
                        [class.is-invalid]="fc.FeMaleFromY.invalid && (fc.FeMaleFromY.touched)"     name="y" id="y"
                          [ngModelOptions]="{standalone: true}">



                    </div>

                  </div>
                  <div class="col-md-1 ">
                    
                    <div class="col-m-6" style="padding-top: 29px;">
                      <label style="font-size:x-large" class="Color">/</label>
                   
                    </div>
                   

                  </div>
                  <div class="col-md-4">
                    <div class="col-m-6">
                      <label> x </label>
                      <input class="form-control" type="text" formControlName="FeMaleFromX"
                      [tooltip]="fc.FeMaleFromX.errors?.required ? ' الحقل مطلوب' : null" required
                      [isDisabled]="!((fc.FeMaleFromX.errors?.required && fc.FeMaleFromX.touched))" containerClass=""
                      [class.is-valid]="fc.FeMaleFromX.valid && (fc.FeMaleFromX.touched)"
                      [class.is-invalid]="fc.FeMaleFromX.invalid && (fc.FeMaleFromX.touched)"    name="y" id="y"
                        [ngModelOptions]="{standalone: true}">



                    </div>
                  </div>
                </div>

                
<div class="row mt-3">
                  <div class="col-md-2 ">
                    <div class="col-m-6" style="padding-top: 33px;">
                      <label>الى</label>
                   
                    </div>

                  </div>
                  <div class="col-md-4 ">
                    <div class="col-m-6">
                      <label>y</label>

                        <input class="form-control" type="text"  formControlName="FeMaleToY"
                        [tooltip]="fc.FeMaleToY.errors?.required ? ' الحقل مطلوب' : null" required
                        [isDisabled]="!((fc.FeMaleToY.errors?.required && fc.FeMaleToY.touched))" containerClass=""
                        [class.is-valid]="fc.FeMaleToY.valid && (fc.FeMaleToY.touched)"
                        [class.is-invalid]="fc.FeMaleToY.invalid && (fc.FeMaleToY.touched)"    name="y" id="y"
                          [ngModelOptions]="{standalone: true}">



                    </div>

                  </div>

                  <div class="col-md-1 ">
                    
                    <div class="col-m-6" style="padding-top: 29px;">
                      <label style="font-size:x-large" class="Color">/</label>
                   
                    </div>
                   

                  </div>
                  <div class="col-md-4">
                    <div class="col-m-6">
                      <label> x </label>
                      <input class="form-control" type="text" formControlName="FeMaleToX"
                      [tooltip]="fc.FeMaleToX.errors?.required ? ' الحقل مطلوب' : null" required
                      [isDisabled]="!((fc.FeMaleToX.errors?.required && fc.FeMaleToX.touched))" containerClass=""
                      [class.is-valid]="fc.FeMaleToX.valid && (fc.FeMaleToX.touched)"
                      [class.is-invalid]="fc.FeMaleToX.invalid && (fc.FeMaleToX.touched)"    name="y" id="y"
                        [ngModelOptions]="{standalone: true}">



                    </div>
                  </div>
                
                </div>   

                </div>
              </div>
              <!-- </div> -->
              <br>
              <div class="col-md-4 my-3 mt-5 " >
                <input type="file" formControlName="image" accept="image/*,application/pdf" #file id="getLogo"
                 style="display: none" (change)="getLogoUrl($event)" />
             <button type="button" onclick="document.getElementById('getLogo').click()"
                 class="btn btn-primary mt-3 d-block"  style="width: 60%;">
                 اختار صورة للقياس البدني
             </button>  
             <img *ngIf="imageLogo" src="{{ imageLogo }}" style="width: 60%; height: 220px;" class="image-style p-2" />

                         </div>
              <div class="col-12 col-md-12 mt-5">
                <div *ngIf="update; else elseBlock">
                  <button [disabled]="disaple" class="btn btn-success pull-right" type="submit">
                    تعديل
                  </button>
                </div>

                <ng-template #elseBlock>
                  <button [disabled]="disaple" class="btn btn-success pull-right" type="submit">
                    إضافة
                  </button>
                </ng-template>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>