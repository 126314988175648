import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerService } from 'src/app/shared/API-Service/services/Customer.service';
import Swal from 'sweetalert2';
import {BaseComponent } from '../../../../shared/components/base/base.component'
import { UsersService } from 'src/app/shared/API-Service/services/users.service';
import { PlatformLocation } from '@angular/common';
@Component({
  selector: 'app-list-customer',
  templateUrl: './list-customer.component.html',
  styleUrls: ['./list-customer.component.css']
})
export class ListCustomerComponent  extends BaseComponent implements OnInit {
  Clients:any;
  pharmacists:any[]=[]
  SearchForm={
    name:null,
    mobile:null,
    code:null,
    pahrmacistId:null,
    maxResultCount:this.pager.maxResultCount,
    skipCount:this.pager.skipCount,
    pageNumber:this.pageNumber
  }

  wantSearchData: any;
  searchData: any;
  param:any;
  fromBack: boolean=false;
  HaveSearch: boolean;
  constructor(private CustomerService:CustomerService,private _Router:Router,private UsersService :UsersService) { super()
    this.Getpharmacist();
    if(this._Router.getCurrentNavigation().trigger=='popstate'){
      const SearchData = JSON.parse(localStorage.getItem('SearchData'))
      if(SearchData){
        this.SearchForm=SearchData
        this.pager.maxResultCount=this.SearchForm.maxResultCount
        this.pager.skipCount=this.SearchForm.skipCount
        this.pageNumber=this.SearchForm.pageNumber
        this.pager.name=this.SearchForm.name,
        this.pager.mobile= this.SearchForm.mobile,
        this.pager.code= this.SearchForm.code
        this.pager.pahrmacistId= this.SearchForm.pahrmacistId  
        this.pageChanged({ page: this.SearchForm.pageNumber });
      }
      else{
        this.getCustomer();
      }
  
    }
    else{
      this.param = this._Router.getCurrentNavigation()?.extras?.state;
      if (this.param) {

      const  SearchData = JSON.parse(localStorage.getItem('SearchData'))
              if(SearchData){
                this.SearchForm=SearchData
                this.pager.maxResultCount=this.SearchForm?.maxResultCount
                this.pager.skipCount=this.SearchForm?.skipCount
                this.pageNumber=this.SearchForm?.pageNumber
                this.pager.name=this.SearchForm?.name,
                this.pager.mobile= this.SearchForm?.mobile,
                this.pager.code= this.SearchForm?.code
             this.pager.pahrmacistId= this.SearchForm.pahrmacistId  
                this.pageChanged({ page: this.SearchForm.pageNumber });
              }
              else{
                this.getCustomer();
              }
              
      }
      else{
        this.getCustomer();
    }
    }
  
    
    
  }
  ngAfterContentChecked(): void{
    // if(this.param){
      if(this.SearchForm){
        this.pageNumber=this.SearchForm.pageNumber
      }
      else{
        this.pageNumber=1
      }
    // }
  }
  
  ngOnInit(): void {

  }
  
  Getpharmacist(){
    this.UsersService.GetPharmacists().subscribe((res)=>{
      this.pharmacists=res.data;
    })
  }
  Search(){
    this.pageNumber=1;
    this.SearchForm.pageNumber=1
    this.pager.skipCount=0
 
      if(!this.SearchForm.name || this.SearchForm.name.trim().length==0 ){
      this.SearchForm.name=null
    }
    if(!this.SearchForm.mobile || this.SearchForm.mobile.trim().length==0){
      this.SearchForm.mobile=null
    }
    if(!this.SearchForm.code || this.SearchForm.code.trim().length==0){
      this.SearchForm.code=null
    }
    if(!this.SearchForm.pahrmacistId){
      this.SearchForm.pahrmacistId=null
    }
    this.pager.name=this.SearchForm.name,
    this.pager.mobile= this.SearchForm.mobile,
    this.pager.code= this.SearchForm.code
    this.pager.pahrmacistId= this.SearchForm.pahrmacistId  
    this.removeNullfromData(this.pager)
  //   const data={
  //     "maxResultCount": this.pager.maxResultCount,
  // "skipCount": this.pager.skipCount,
  // "name": this.SearchForm.name,
  // "mobile": this.SearchForm.mobile,
  // "code":this.SearchForm.code,
  // "pahrmacistId": this.SearchForm.pharmacistId
  //   }
 
    this.CustomerService.GetPaged(this.pager).subscribe((res) => {
      this.Clients = res.data.items
      this.totalCount=res.data.totalCount;
    
    },(err)=>{
      console.log('their is a problem');
    },() =>{
      console.log('Completed');
    });
  }
  removeNullfromData(data: any): void {
    for (const prop in data) {
      if ( (data[prop] === null || (typeof data[prop] === 'string' && data[prop].trim() === ""))  &&   prop!='skipCount' && prop!='maxResultCount' ) {
        delete data[prop];
      }
    }
  }
  pageChanged(event: any){
    if(!this.SearchForm.name || this.SearchForm.name.trim().length==0 ){
      this.SearchForm.name=null
    }
    if(!this.SearchForm.mobile || this.SearchForm.mobile.trim().length==0){
      this.SearchForm.mobile=null
    }
    if(!this.SearchForm.code || this.SearchForm.code.trim().length==0){
      this.SearchForm.code=null
    }
    if(!this.SearchForm.pahrmacistId ){
      this.SearchForm.pahrmacistId=null
    }
    this.pageNumber = event.page;// -1 * pageSize;
    this.SearchForm.pageNumber=this.pageNumber
    this.pager.skipCount = (this.pageNumber - 1) * this.pager.maxResultCount;
    this.removeNullfromData(this.pager)

    this.CustomerService.GetPaged(this.pager).subscribe((res) => {
      this.Clients = res.data.items
      this.totalCount=res.data.totalCount;
    
    },(err)=>{
      console.log('their is a problem');
    },() =>{
      console.log('Completed');
    });

  }
  getCustomer(){
    // const data={
    //   "maxResultCount": this.pager.maxResultCount,
    //   "skipCount": this.pager.skipCount,
    // }

    this.removeNullfromData(this.pager)
    this.CustomerService.GetPaged(this.pager).subscribe((res) => {
      this.Clients = res.data.items
      this.totalCount=res.data.totalCount;
    
    },(err)=>{
      console.log('their is a problem');
    },() =>{
      console.log('Completed');
    });
  }
  update(id){
    this.SearchForm.maxResultCount=this.pager.maxResultCount;
    this.SearchForm.skipCount=this.pager.skipCount
    this.pager.pageNumber=this.pageNumber
    localStorage.setItem('SearchData',JSON.stringify(this.pager))
    localStorage.setItem('SearchData',JSON.stringify(this.pager))
    this._Router.navigate(['/content/admin/update-customer',id]);
    }

    delete(id : number){
      Swal.fire({
        title: 'هل تريد مسح العنصر ؟',
        text: "لن يكون لك صلاحية إعادته مره اخرى",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'الغاء',
        confirmButtonText: 'امسح العنصر !'
      }).then((result) => {
        if (result.isConfirmed) {
          this.CustomerService.Delete(id).subscribe((res) => {
            Swal.fire({
              icon: "success",
              title: "تم المسح بنجاح",
              showConfirmButton: false,
              timer: 1500,
            });
         this.getCustomer();
          },(err) => {
            Swal.fire({
              icon: 'error',
              title: 'خطأ',
              text:err.error.message    
            })
          },() => {
            console.log("completed");
          })
        }
      }) 
    }
    goToCustomerBody(id){
      this.SearchForm.maxResultCount=this.pager.maxResultCount;
      this.SearchForm.skipCount=this.pager.skipCount
      this.pager.pageNumber=this.pageNumber
      localStorage.setItem('SearchData',JSON.stringify(this.pager))
      this._Router.navigate(['/content/admin/ListCustomerinbodytest',id]);
    }
    goToCustomerMedicalMeasurement(id){
      this.SearchForm.maxResultCount=this.pager.maxResultCount;
      this.SearchForm.skipCount=this.pager.skipCount
      this.pager.pageNumber=this.pageNumber
      localStorage.setItem('SearchData',JSON.stringify(this.pager))
      this._Router.navigate(['/content/admin/ListCustomerMedicalMeasurement',id]);

    }
    goToCustomerPrescription(id){
      this.SearchForm.maxResultCount=this.pager.maxResultCount;
      this.SearchForm.skipCount=this.pager.skipCount
      this.pager.pageNumber=this.pageNumber
      localStorage.setItem('SearchData',JSON.stringify(this.pager))
      this._Router.navigate(['/content/admin/ListCustomerPrescription',id]);
    }
}
