import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TipsService } from '../../../../shared/API-Service/services/tips.service';
import { TipCategoryService } from '../../../../shared/API-Service/services/tip-category.service';

import Swal from 'sweetalert2';
import {BaseComponent } from '../../../../shared/components/base/base.component'
@Component({
  selector: 'app-list-tips',
  templateUrl: './list-tips.component.html',
  styleUrls: ['./list-tips.component.css']
})
export class ListTipsComponent extends BaseComponent implements OnInit {
  Tips:any;
  SearchForm={
    tipCategoryId: "",
  name: ""

  }
  TipCategory: any;
  constructor(private TipsService:TipsService,private TipCategoryService:TipCategoryService,private _Router:Router) { super()}

  ngOnInit(): void {
    this.getTips();
    this.getTipCategory();
  }
  pageChanged(event: any){
    this.pageNumber = event.page;// -1 * pageSize;
    this.pager.skipCount = (this.pageNumber - 1) * this.pager.maxResultCount;
    this.getTips()
  }
  getTips(){
    this.removeNullfromData(this.pager)
    this.TipsService.GetPaged(this.pager).subscribe((res) => {
      this.Tips = res.data.items
      this.totalCount=res.data.totalCount;
    
    },(err)=>{
      console.log('their is a problem');
    },() =>{
      console.log('Completed');
    });
  }
  getTipCategory(){
    this.TipCategoryService.Get().subscribe(res=>{
      this.TipCategory= res.data
    })
  }

  removeNullfromData(data: any): void {
    for (const prop in data) {
      if ( !data[prop] && prop!='skipCount' && prop!='maxResultCount') {
        delete data[prop];
      }
    }
  }
  Search(){
    this.pageNumber=1;
    // this.pager.skipCount=0
    this.pager.tipCategoryId=this.SearchForm.tipCategoryId;
    this.pager.name=this.SearchForm.name;
  this.getTips()
  }
  update(id){
    this._Router.navigate(['/content/admin/update-tip',id]);
    }

    delete(id : number){
      Swal.fire({
        title: 'هل تريد مسح العنصر ؟',
        text: "لن يكون لك صلاحية إعادته مره اخرى",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'الغاء',
        confirmButtonText: 'امسح العنصر !'
      }).then((result) => {
        if (result.isConfirmed) {
          this.TipsService.Delete(id).subscribe((res) => {
            Swal.fire({
              icon: "success",
              title: "تم المسح بنجاح",
              showConfirmButton: false,
              timer: 1500,
            });
         this.getTips();
          },(err) => {
            Swal.fire({
              icon: 'error',
              title: 'خطأ',
              text:err.error.message    
            })
          },() => {
            console.log("completed");
          })
        }
      }) 
    }


}
