import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators,  } from '@angular/forms';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { LoginService } from '../../../../shared/API-Service/services/login.service';
import { PermissionsApiService } from 'src/app/shared/API-Service/services/Permissions-api.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  public today: number = Date.now();

changetype:boolean = true;
show:boolean = false;
person:FormGroup ;
  MenuByRoleId: any[]=[];
  children: any[]=[]
  constructor(private PermissionsApiService:PermissionsApiService,private _FormBuilder:FormBuilder,private _LoginService:LoginService, private _Router:Router ) { }

  ngOnInit(): void {
    this.initiate();
  }


  initiate(){
    this.person = this._FormBuilder.group({
      "mobile": ['', Validators.required],
      "password": ['', Validators.required],
    });
  }
  // loopform() {
  //   this.data = new FormData();
  //   this.data.append("userName", this.person.value.user_name);
  //   this.data.append("password", this.person.value.passowrd);
    
  // }
 showPassword(){
  this.show = !this.show;
  this.changetype = !this.changetype;
  }
  get fc() {
    return this.person.controls;
  }
  onSubmit(){
    if (this.person.status == "VALID") {
      this._LoginService.user_login(this.person.value).subscribe((res) => {
          localStorage.setItem('WeCareAuthorization',res.data["token"]);
          localStorage.setItem('WeCareCurrentUser',JSON.stringify(res.data))
          localStorage.setItem('WeCareRole',res.data['roleId'] )
          localStorage.setItem('WeCareuserId',res.data['id'])
          localStorage.setItem('WeCareUserName',res.data['name'])
          this.PermissionsApiService.GetByRoleId().subscribe({
            next: (data) => {
              if(!data.data.defaultMenuUrl)
              {
                data.data.defaultMenuUrl='/content/admin/ListUser'
              }
              this.children.push({path:data.data.defaultMenuUrl});
              this.MenuByRoleId.push({children:this.children});
              localStorage.setItem("WeCaremenuRolus",JSON.stringify(this.MenuByRoleId));
              this._Router.navigate([data.data.defaultMenuUrl]);

            }

          });
          Swal.fire({
            icon: "success",
            title: "تم تسجيل الدخول بنجاح",
            showConfirmButton: false,
            timer: 1500,
          });
          //  this._Router.navigate(["/content/admin/ListUser"]);
    },(err) =>{
    console.log("their is an error");
    Swal.fire({
      icon: 'error',
      title: 'failed to sign in',
      text:err.error.message    
    })
    }, () =>{
      console.log("completed");
      
    });
    }

    else {
      this.person.markAllAsTouched();
    }
  }
}
