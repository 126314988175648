<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header" style="padding-bottom: 5px !important">
                    <div class="row">
                        <div class="col-6">
                            <h5 class="pb-2">قائمة البوم الفيديو</h5>
                        </div>
                        <div class="col-6 header-titles">
                            <a routerLink="/content/admin/insert-VideoAlbum" class="d-block">
                                <button appActionNew class="btn btn-success pull-right" type="button">
                                    <span class="m-2">إدخال البوم فيديو</span>
                                    <i class="fa fa-plus"></i>
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="card-block row">
                    <div class="col-sm-12 col-lg-12 col-xl-12">
                        <div class="table-responsive">
                            <table class="table table-responsive-sm ">
                                <thead>
                                    <tr class=" " style="text-align: start !important;">
                                        <th scope="col"></th>
                                        <th scope="col">الأسم</th>
                                        <th scope="col">الترتيب</th>
                                        <!-- <th scope="col">التاريخ</th> -->
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let view of VideoAlbum;let x = index" class=" " style="text-align: start !important;">
                                        <td scope="row" class="p-2">{{x+1}}</td>
                                        <td class="p-2">{{view.title}}</td>
                                        <td class="p-2">{{view.order}}</td>
                                        <!-- <td class="p-2">{{view.date | date:"dd/MM/yyyy"}}</td> -->
                                            <td class="font-style chose">
                                                <button class="btn pull-right" appActionEdit
                                                style="color: rgba(35, 118, 241, 0.667); padding: 7px"
                                                (click)="update(view.id)">
                                                <i class="fa fa-pencil"></i>
                                            </button>
                                                   <button appActionDelete class="btn  pull-right"  style="color: red; padding: 7px"
                                                   (click)="delete(view.id)">
                                                    <i class="fa fa-trash"></i>
                                                       </button>
                                               
                                            
                                            </td>
                                        </tr>
                                </tbody>
                            </table>
                            <div class="d-flex justify-content-center">
                                <pagination [boundaryLinks]="true" [maxSize]="20" [totalItems]="totalCount" previousText="&lsaquo;" nextText="&rsaquo;"
                                  firstText="&laquo;" lastText="&raquo;" [itemsPerPage]="pager.maxResultCount" [(ngModel)]="pageNumber"
                                  (pageChanged)="pageChanged($event)">
                                </pagination>
                              </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>




