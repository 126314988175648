export class Customer {
  name: string=''
mobile:  string=''
genderId :number=null
districtId:number=null
address:   string=''
password:   string=''
pharmacyCode:string=''
registerRequestId:number=null
pharmacistId:number
}