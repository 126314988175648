
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header" style="padding-bottom: 5px !important">
                    <div >
                        <h5 *ngIf="!update" class="pb-2">اضافة  فيديو</h5>
                        <h5  *ngIf="update" class="pb-2">تعديل  فيديو</h5>

                       <span> بالرجاء ملئ جميع الحقول </span>
                    </div>
                    <ng-template >
                        
                    </ng-template>
                </div>
                <div class="card-body">
                    <form [formGroup]="insertForm" (ngSubmit)="Submit()">

                    <div class="row m-0 mb-2">
                        <div class="col-md-6">
                          <label for=""> الاسم
                          </label>
                            <div class="form-group">
                               <input type="text"  class="form-control" formControlName="title" 
                               [tooltip]="fc.title.errors?.required ? ' الاسم مطلوب' : null" required
                               [isDisabled]="!((fc.title.errors?.required && fc.title.touched))"
                               containerClass="" [class.is-valid]="fc.title.valid && (fc.title.touched)"
                               [class.is-invalid]="fc.title.invalid && (fc.title.touched)"
                               >
                            </div>
                        </div>
                        <div class="col-md-6">
                            <label for=""> التاريخ
                            </label>
                              <div class="form-group">
                                 <input type="date" formControlName="date" class="form-control" 
                                 [tooltip]="fc.date.errors?.required ? ' التاريخ مطلوب' : null" required
                                 [isDisabled]="!((fc.date.errors?.required && fc.date.touched))"
                                 containerClass="" [class.is-valid]="fc.date.valid && (fc.date.touched)"
                                 [class.is-invalid]="fc.date.invalid && (fc.date.touched)"
                                 >

                              </div>
                          </div>
                        <div class="col-md-6">
                            <label for=""> الترتيب
                            </label>
                              <div class="form-group">
                                 <input type="number" [min]="1" onlyNumbers class="form-control" formControlName="order" 
                                 [tooltip]="fc.order.errors?.required ? ' الترتيب مطلوب' : null" required
                                 [isDisabled]="!((fc.order.errors?.required && fc.order.touched))"
                                 containerClass="" [class.is-valid]="fc.order.valid && (fc.order.touched)"
                                 [class.is-invalid]="fc.order.invalid && (fc.order.touched)"
                                 >
                              </div>
                          </div>
                          <div class="col-md-6">
                            <label for=""> البوم الفيديو
                            </label>
                              <div class="form-group">
                                <ng-select
                                class="js-example-basic-single p-0"
                                name="videoAlbum"
                                placeholder="إختر البوم الفيديو"
                                formControlName="videoAlbumId"
                                required=""
                                [items]="VideoAlbums"
                                bindLabel="title"
                                bindValue="id"
                                [style.border]="fc.videoAlbumId.touched ? (fc.videoAlbumId.invalid ? '1px solid #dc3545 !important' : (fc.videoAlbumId.valid && fc.videoAlbumId.touched ? '1px solid #28a745' : 'none')) : 'none'"

                                >
                                </ng-select>
                              </div>
                          </div>
                          <div class="col-md-6">
                              <label >أختر مجموعة الادوية </label>
                              <ng-multiselect-dropdown
                              [formControl]="fc['drugGroupIds']"
                              id="validationCustom02=3"
                              required="required"
                                placeholder="أختر مجموعة الادوية أو أكتر من القائمة"
                                [settings]="dropdownSettings"
                                [(ngModel)]="selectedItems"
                                [data]="DrugGroups" 
                                [style.border]="fc.drugGroupIds.touched ? (fc.drugGroupIds.invalid ? '1px solid #dc3545 !important' : (fc.drugGroupIds.valid && fc.drugGroupIds.touched ? '1px solid #28a745' : 'none')) : 'none'" >
     
                              >
                              </ng-multiselect-dropdown>
                        </div>
                        <div class="col-md-6">
                            <label for=""> رابط الفيديو
                            </label>
                              <div class="form-group">
                                 <input type="text"  class="form-control" formControlName="videoUrl" 
                                 [tooltip]="fc.videoUrl.errors?.required ? ' رابط فيديو مطلوب' : null" required
                                 [isDisabled]="!((fc.videoUrl.errors?.required && fc.videoUrl.touched))"
                                 containerClass="" [class.is-valid]="fc.videoUrl.valid && (fc.videoUrl.touched)"
                                 [class.is-invalid]="fc.videoUrl.invalid && (fc.videoUrl.touched)"
                                 >
                              </div>
                          </div>
                          <div class="col-md-12"            
                          [style.border]="fc.description.touched ? (fc.description.invalid ? '1px solid #dc3545 !important' : (fc.description.valid && fc.description.touched ? '1px solid #28a745' : 'none')) : 'none'">
                            <label for=""> الوصف
                            </label>
                              <div class="form-group">
                                
                                <angular-editor [placeholder]="'بالرجاء كتابة المحتوى هنا.....'" [config]="editorConfig" 
                                formControlName="description" 
                               ></angular-editor>
                                 <!-- <textarea type="text" style="height: 100px !important;" class="form-control" formControlName="description" 
                                 [tooltip]="fc.description.errors?.required ? ' الوصف مطلوب' : null" required
                                 [isDisabled]="!((fc.description.errors?.required && fc.description.touched))"
                                 containerClass="" [class.is-valid]="fc.description.valid && (fc.description.touched)"
                                 [class.is-invalid]="fc.description.invalid && (fc.description.touched)"
                                 ></textarea> -->
                              </div>
                          </div>
                        <div class="col-12 col-md-12">
                            <div *ngIf="update; else elseBlock">
                                <button [disabled]="disaple" class="btn btn-success pull-right" type="submit" >
                                  تعديل
                                </button>
                              </div>
                  
                              <ng-template #elseBlock>
                                <button [disabled]="disaple" class="btn btn-success pull-right" type="submit">
                                  إضافة
                                </button>
                              </ng-template>
                </div>
                </div>
                    </form>
    </div>
</div>
        </div>
    </div>
</div>