import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerPrescriptionService } from 'src/app/shared/API-Service/services/customer-prescription.service';
import { CustomerService } from 'src/app/shared/API-Service/services/Customer.service';
import Swal from 'sweetalert2';
import {CustomerPrescription} from "../../../../shared/Models/CustomerPrescription";
import { environment } from 'src/environments/environment.prod';
import { DatePipe } from '@angular/common';
import { LookupsService } from '../../../../shared/API-Service/services/lookups.service';
import { DrugsService } from '../../../../shared/API-Service/services/drugs.service';

@Component({
  selector: 'app-insert-customer-prescription',
  templateUrl: './insert-customer-prescription.component.html',
  styleUrls: ['./insert-customer-prescription.component.css']
})
export class InsertCustomerPrescriptionComponent implements OnInit {
  id: string;
  update: boolean;
  insertForm: FormGroup;
  PrescriptionFormGroup: FormGroup;
  CustomerPrescriptionModal = new CustomerPrescription();
  CustomerInBodyTest:any;
  CustomerId: any;
  CustomerName: any;
  Image: File;
  imageLogo: string="";
  CustomerInBodyTestForm=new FormData();
  viewPdf: boolean;
  Uploaded: boolean;
  DoseTypes:  any[]=[];
  DoseTimes:  any[]=[];
  Drugs: any[]=[];
  customerPrescriptionDrugs:any[]=[]
  DoseQuantity:  any[]=[];
  disaple: boolean;

  PresciptionForm=    {
    drugId: null,
    doseTimeId:null,
    count:  null,
    startDate:  null,
    // days: "",
    drugItemCount: null,
    quantityPerItem: null,
    doseQuantityId: null,
    pharmacistComment: null,
    notification: null,
    repDays:null
}
  customerPrescriptionDrugsForTable: any[]=[];
  customerPrescription: any;
  notification: boolean;
  startDate: string;
  UpdateTable: boolean=false;
  TableItemIndex: any;
  NotificationState:boolean=true
  doseQuantityName: any;

  constructor( private DrugsService :DrugsService,private LookupsService :LookupsService,public datepipe:DatePipe,private CustomerService:CustomerService,private CustomerPrescriptionService:CustomerPrescriptionService,private _formBuilder: FormBuilder,private router: Router,private route: ActivatedRoute) { 
    
    const localDate = new Date();
const year = localDate.getFullYear();
const month = String(localDate.getMonth() + 1).padStart(2, '0');
const day = String(localDate.getDate()).padStart(2, '0');
const hours = String(localDate.getHours()).padStart(2, '0');
const minutes = String(localDate.getMinutes()).padStart(2, '0');
const localDateTime = `${year}-${month}-${day}T${hours}:${minutes}`;
    this.startDate = localDateTime
    this.PresciptionForm.startDate=this.startDate
    
    this.initForm()

  }
  checknotification(e){
    this.NotificationState=e.target.checked

  }


  // Function to get the default start date
  getDefaultStartDate(): string {
    const now = new Date();
    // Convert the current date and time to a string in the format expected by datetime-local input
    const formattedDate = `${this.padZero(now.getFullYear())}-${this.padZero(now.getMonth() + 1)}-${this.padZero(now.getDate())}T${this.padZero(now.getHours())}:${this.padZero(now.getMinutes())}`;
    return formattedDate;
  }

  // Function to pad single-digit numbers with a leading zero
  padZero(num: number): string {
    return num < 10 ? `0${num}` : `${num}`;
  }

  // Function to get the minimum date (today)
  getMinDate(): string {
    const now = new Date();
    // Convert the current date to a string in the format expected by datetime-local input
    const formattedDate = `${this.padZero(now.getFullYear())}-${this.padZero(now.getMonth() + 1)}-${this.padZero(now.getDate())}T${this.padZero(now.getHours())}:${this.padZero(now.getMinutes())}`;
    return formattedDate;
  }
  ngOnInit(): void {
    
    this.GetDoseTypes();
    this.GetDoseTimes();
    this.GetDrugs();
    this.GetDoseQuantity();
    
    if (this.route.snapshot.paramMap.get("id")) {
      this.id =this.route.snapshot.paramMap.get("id")
      // this.CustomerService.GetCustomerById(this.id).subscribe(res=>{
      //   this.CustomerName=res.data.name;
      // })
      this.CustomerPrescriptionService.GetCustomerPrescriptionById(this.id).subscribe(res=>
      {
        this.customerPrescription=res.data;
        this.customerPrescription.customerPrescriptionDrugs.forEach(element => {
          if(element.notification){
            element.notification="مفعل"
          }
          else{
            element.notification = "غير مفعل"
          }        });
//this.customerPrescriptionDrugs=this.customerPrescription.customerPrescriptionDrugs;
        this.customerPrescriptionDrugsForTable=this.customerPrescription.customerPrescriptionDrugs;
        

        this.initForm(this.customerPrescription)
     this.NotificationState=true
      })
      this.update = true;
    } 
     if(this.route.snapshot.paramMap.get("Customerid")){
this.CustomerId=this.route.snapshot.paramMap.get("Customerid")
this.CustomerService.GetCustomerById(this.CustomerId).subscribe(res=>{
  this.CustomerName=res.data.name;
})
// this.initForm()
    }
    // else {
    //   this.initForm()
    //   this.update = false;
    // }
  }
  GetDrugs(){
    this.DrugsService.Get().subscribe(res=>{
      this.Drugs=res.data;
    })
  }
  GetDoseTypes(){
this.LookupsService.GetDoseTypes().subscribe(res=>{
  this.DoseTypes=res.data;
})
  }
  GetDoseQuantity(){
    this.LookupsService.GetDoseQuantity().subscribe(res=>{
      this.DoseQuantity=res.data;
    })
      }
  GetDoseTimes(){
    this.LookupsService.GetDoseTimes().subscribe(res=>{
      this.DoseTimes=res.data;
    })
  }
    generateRandomId(): string {
    // Set of numbers for the random ID
    const numbers = '0123456789';
    const idLength = 10; // Length of the random ID
    let randomId = '';
    for (let i = 0; i < idLength; i++) {
      randomId += numbers.charAt(Math.floor(Math.random() * numbers.length));
    }
    return randomId;
  }
  Add(){
    if(this.insertForm.get('drugId').value &&
    this.insertForm.get('doseTimeId').value &&
    this.insertForm.get('count').value&&
    this.insertForm.get('startDate').value
    // this.insertForm.get('days').value
     )
    {
      if(this.PresciptionForm.notification){
        this.notification=true;
      }
      else{
        this.notification=false;
      }
      const id=this.generateRandomId()
    //   this.customerPrescriptionDrugs.push({
    //     id:id,
    //     drugId: Number(this.PresciptionForm.drugId),
    //     doseTimeId:  Number(this.PresciptionForm.doseTimeId),
    //     count:  this.PresciptionForm.count,
    //     startDate:  this.PresciptionForm.startDate,
    //     // days:  this.PresciptionForm.days,
    //     drugItemCount:  this.PresciptionForm.drugItemCount,
    //     quantityPerItem:  this.PresciptionForm.quantityPerItem,
    //     doseQuantityId:   this.insertForm.get('doseQuantityId').value,
    //     pharmacistComment:  this.PresciptionForm.pharmacistComment,
    //     notification:  this.NotificationState,

    // })
    const drugName = this.Drugs.filter(x=>x.id==this.PresciptionForm.drugId)[0].title
    const doseTimeName = this.DoseTimes.filter(x=>x.id==this.PresciptionForm.doseTimeId)[0].title
    if(this.insertForm.get('doseQuantityId').value){
          this.doseQuantityName = this.DoseQuantity.filter(x=>x.id== this.insertForm.get('doseQuantityId').value)[0].title
    }
    else{
      this.doseQuantityName=null
    }
  let notification
    if(this.NotificationState){
      notification="مفعل"
    }
    else{
      notification = "غير مفعل"
    }
    let notificationForEdit
        this.customerPrescriptionDrugsForTable.push({
        id:id,
        drugId: this.PresciptionForm.drugId,
        drugTitle: drugName,
        doseTimeId:  this.PresciptionForm.doseTimeId,
        doseTimeTitle:doseTimeName,
        doseQuantityTitle:this.doseQuantityName,
        count:  this.PresciptionForm.count,
        startDate:  this.PresciptionForm.startDate,
        // days:  this.PresciptionForm.days,
        drugItemCount:  this.PresciptionForm.drugItemCount,
        quantityPerItem:  this.PresciptionForm.quantityPerItem,
        doseQuantityId:   this.insertForm.get('doseQuantityId').value,
        pharmacistComment:  this.PresciptionForm.pharmacistComment,
        notification:     notification  ,
    
    })
    this.customerPrescriptionDrugs=[]
    this.customerPrescriptionDrugsForTable.forEach(element => {
      if(element.notification=="مفعل"){
        notificationForEdit=true
      }
      else{
        notificationForEdit=false
      }
      this.customerPrescriptionDrugs.push({
        drugId : element.drugId,
        doseTimeId:  element.doseTimeId,
        count:  element.count,
        startDate:  element.startDate,
        // days:  element.days,
        doseQuantityId:   element.doseQuantityId,
        pharmacistComment:  element.pharmacistComment,
        notification:  notificationForEdit,
      })
    });
  this.PresciptionForm.drugId=null
  this.PresciptionForm.doseTimeId=null
  this.PresciptionForm.count=null
  this.PresciptionForm.startDate=null
  // this.PresciptionForm.days=""
  this.PresciptionForm.drugItemCount=null
  this.PresciptionForm.quantityPerItem=null
  this.PresciptionForm.doseQuantityId=null
  this.PresciptionForm.pharmacistComment=null
  this.PresciptionForm.notification=null  
    }
    else{
      Swal.fire({
        icon: "warning",
        title:"من فضلك ادخل البيانات كامله"
        });
    }

  }
  initForm(CustomerPrescriptionModal?: CustomerPrescription) {
    this.insertForm = this._formBuilder.group({
      title: [CustomerPrescriptionModal?.title || '', Validators.required],
      repDays:[CustomerPrescriptionModal?.repDays||''],
      drugId:[''],
      doseTimeId:[''],
      count: [''],
      startDate: [this.startDate],
      // days: [''],
      drugItemCount: [''],
      quantityPerItem:[''],
      doseQuantityId: [null],
      pharmacistComment: [''],
      notification:[this.NotificationState]
    });

  }
  get fc() {
    return this.insertForm.controls;
  }

  Submit(){
  
    if (this.insertForm.status == "VALID") {
      this.disaple=true
      if (this.update == true) {
        this.UpdateCustomerPrescription();
      } else {
        this.InsertCustomerPrescription();
      }
    }
     else {
      this.insertForm.markAllAsTouched();
    }
  

  }
  removeNullfromData(data: any): void {
    for (const prop in data) {
      if ( (data[prop] === null || (typeof data[prop] === 'string' && data[prop].trim() === ""))  &&   prop!='skipCount' && prop!='maxResultCount' ) {
        delete data[prop];
      }
    }
  }
  UpdateCustomerPrescription(){
    this.customerPrescriptionDrugs=[];
    let notificationForEdit

    this.customerPrescriptionDrugsForTable.forEach(element => {
      if(element.notification=="مفعل"){
        notificationForEdit=true
      }
      else{
        notificationForEdit=false
      }
      this.customerPrescriptionDrugs.push({
        drugId : element.drugId,
        doseTimeId:  element.doseTimeId,
        count:  element.count,
        startDate:  element.startDate,
        // days:  element.days,
        doseQuantityId:   element.doseQuantityId,
        pharmacistComment:  element.pharmacistComment,
        notification:  notificationForEdit,
      })
    });
    let repDays
    if (this.insertForm.get('repDays').value){
       repDays =this.insertForm.get('repDays').value
    }
    else{
      repDays= 0
    }
    const data ={
      "id":this.id,
      "title": this.insertForm.get('title').value,
      "customerId": this.CustomerId,
      "doseTypeId": 2,
      "repDays": repDays,
      "customerPrescriptionDrugs": this.customerPrescriptionDrugs
    }
    this.removeNullfromData(this.customerPrescriptionDrugs);

    this.CustomerPrescriptionService.Update(data).subscribe(
      (response) => {
      Swal.fire({
      icon: "success",
      title: "تم التعديل بنجاح",
      showConfirmButton: false,
      timer: 1500,
      });
      this.router.navigate(['/content/admin/ListCustomerPrescription',this.CustomerId],{state:{activeId:2}});
      this.disaple=false

    },
      (err) => {
        this.disaple=false

        Swal.fire({
          icon: "error",
          title:err.error.message
          });
      }
  
      );
  }
  InsertCustomerPrescription(){
    
    let repDays
    if (this.insertForm.get('repDays').value){
       repDays =this.insertForm.get('repDays').value
    }
    else{
      repDays= 0
    }
  const data ={
    "title": this.insertForm.get('title').value,
    "customerId": this.CustomerId,
    "doseTypeId": 2,
   "repDays": repDays,
    "customerPrescriptionDrugs": this.customerPrescriptionDrugs
  }
  this.customerPrescriptionDrugs.forEach(element => {
    this.removeNullfromData(element);

  });

    this.CustomerPrescriptionService.Create(data).subscribe(
      (response) => {
      Swal.fire({
      icon: "success",
      title: "تم الحفظ بنجاح",
      showConfirmButton: false,
      timer: 1500,
      });
      this.router.navigate(['/content/admin/ListCustomerPrescription',this.CustomerId],{state:{activeId:2}});
      this.disaple=false

    },
      (err) => {
        this.disaple=false

        Swal.fire({
          icon: "error",
          title:err.error.message
          });
      }
            );
   
 
  }
  Edit(){
    if(this.insertForm.get('drugId').value &&
    this.insertForm.get('doseTimeId').value &&
    this.insertForm.get('count').value&&
    this.insertForm.get('startDate').value
    // this.insertForm.get('days').value
     )
    {
     
this.customerPrescriptionDrugsForTable[this.TableItemIndex].drugId=this.insertForm.get('drugId').value
this.customerPrescriptionDrugsForTable[this.TableItemIndex].drugTitle=this.Drugs.filter(x=>x.id==this.insertForm.get('drugId').value)[0].title

this.customerPrescriptionDrugsForTable[this.TableItemIndex].doseTimeId=this.insertForm.get('doseTimeId').value
this.customerPrescriptionDrugsForTable[this.TableItemIndex].doseTimeTitle=this.DoseTimes.filter(x=>x.id==this.insertForm.get('doseTimeId').value)[0].title

this.customerPrescriptionDrugsForTable[this.TableItemIndex].doseQuantityId=this.insertForm.get('doseQuantityId').value
this.customerPrescriptionDrugsForTable[this.TableItemIndex].doseQuantityTitle=this.DoseQuantity.filter(x=>x.id==this.insertForm.get('doseQuantityId').value)[0]?.title

this.customerPrescriptionDrugsForTable[this.TableItemIndex].startDate=this.insertForm.get('startDate').value
this.customerPrescriptionDrugsForTable[this.TableItemIndex].count=this.insertForm.get('count').value
// this.customerPrescriptionDrugsForTable[this.TableItemIndex].days=this.insertForm.get('days').value
this.customerPrescriptionDrugsForTable[this.TableItemIndex].pharmacistComment=this.insertForm.get('pharmacistComment').value
if(this.NotificationState){
  this.customerPrescriptionDrugsForTable[this.TableItemIndex].notification="مفعل"
}
else{
  this.customerPrescriptionDrugsForTable[this.TableItemIndex].notification="غير مفعل"

}
if(this.customerPrescriptionDrugs.length!=0){
 this.customerPrescriptionDrugs[this.TableItemIndex].drugId=this.insertForm.get('drugId').value

this.customerPrescriptionDrugs[this.TableItemIndex].doseTimeId=this.insertForm.get('doseTimeId').value

this.customerPrescriptionDrugs[this.TableItemIndex].doseQuantityId=this.insertForm.get('doseQuantityId').value

this.customerPrescriptionDrugs[this.TableItemIndex].startDate=this.insertForm.get('startDate').value
this.customerPrescriptionDrugs[this.TableItemIndex].count=this.insertForm.get('count').value
// this.customerPrescriptionDrugs[this.TableItemIndex].days=this.insertForm.get('days').value
this.customerPrescriptionDrugs[this.TableItemIndex].pharmacistComment=this.insertForm.get('pharmacistComment').value
this.customerPrescriptionDrugs[this.TableItemIndex].notification=this.NotificationState

// if(this.NotificationState){
// }
// else{
//   this.customerPrescriptionDrugs[this.TableItemIndex].notification="غير مفعل"

// }

}
this.insertForm.get('drugId').reset()
this.insertForm.get('doseTimeId').reset()
this.insertForm.get('doseQuantityId').reset()
this.insertForm.get('startDate').reset()
this.insertForm.get('count').reset()
// this.insertForm.get('days').reset()
this.insertForm.get('pharmacistComment').reset()
this.UpdateTable=false
    }
else{
      Swal.fire({
        icon: "warning",
        title:"من فضلك ادخل البيانات كامله"
        });
    }

  }
  updateFromTable(item,index){
this.insertForm.get('drugId').setValue(item.drugId)
this.insertForm.get('doseTimeId').setValue(item.doseTimeId)
this.insertForm.get('doseQuantityId').setValue(item.doseQuantityId)
this.insertForm.get('startDate').setValue(item.startDate)
this.insertForm.get('count').setValue(item.count)
// this.insertForm.get('days').setValue(item.days)
this.insertForm.get('pharmacistComment').setValue(item.pharmacistComment)
this.TableItemIndex=index
if(item.notification=="مفعل"){
  this.NotificationState=true
}
else{
  this.NotificationState=false

}
this.UpdateTable=true
  }
  deleteFromTable(item,index){
    if(item.id){
     const Itemindex= this.customerPrescriptionDrugsForTable.findIndex(x=>x.id!=item.id)
      this.customerPrescriptionDrugsForTable=this.customerPrescriptionDrugsForTable.filter(x=>x.id!=item.id &&Itemindex!=index)
this.customerPrescriptionDrugs=this.customerPrescriptionDrugs.filter(x=>x.id!=item.id&&Itemindex!=index)

}
    this.PresciptionForm.drugId=null
    this.PresciptionForm.doseTimeId=null
    this.PresciptionForm.count=null
    // this.PresciptionForm.days=""
    this.PresciptionForm.drugItemCount=null
    this.PresciptionForm.quantityPerItem=null
    this.PresciptionForm.doseQuantityId=null
    this.PresciptionForm.pharmacistComment=null
    this.PresciptionForm.notification=null
    this.UpdateTable=false
}
}
