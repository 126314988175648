import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DrugGroupsService } from 'src/app/shared/API-Service/services/drug-groups.service';
import Swal from 'sweetalert2';
import {DrugGroups} from "../../../../shared/Models/DrugGroups";
@Component({
  selector: 'app-insert-drug-group',
  templateUrl: './insert-drug-group.component.html',
  styleUrls: ['./insert-drug-group.component.css']
})
export class InsertDrugGroupComponent implements OnInit {
  id: string;
  update: boolean;
  insertForm: FormGroup;
  DrugGroups = new DrugGroups();
  Districts:any;
  disaple: boolean;

  constructor(private DrugGroupsService:DrugGroupsService,private _formBuilder: FormBuilder,private router: Router,private route: ActivatedRoute) { 
    // this.GetDrugGroups();
  }
  ngOnInit(): void {
    if (this.route.snapshot.paramMap.get("id")) {
      console.log(this.route.snapshot.paramMap.get("id"))
      this.id =this.route.snapshot.paramMap.get("id")
      this.DrugGroupsService.GetDrugGroupById(this.id).subscribe(async (res:any)=>{
        console.log(res.data);
      this.Districts=  await res.data;
        this.initForm(this.Districts);
        
      })
      this.update = true;
    } else {
      this.initForm()
      this.update = false;
    }
  }
  initForm(DrugGroups?: DrugGroups) {
    this.insertForm = this._formBuilder.group({
      title: [DrugGroups?.title || '', Validators.required],
      order: [DrugGroups?.order || '', Validators.required],
    });
  }

  get fc() {
    return this.insertForm.controls;
  }

  GetDistrict(){
    this.DrugGroupsService.Get().subscribe(res=>{
      this.Districts=res.data;
    })
  }
  Submit(){
    if (this.insertForm.status == "VALID") {
      this.disaple=true

      if (this.update == true) {
        this.UpdateDrugGroup();
      } else {
        this.InsertDrugGroup();
      }
    }
     else {
      this.insertForm.markAllAsTouched();
    }
  

  }
  UpdateDrugGroup(){
    const data ={
      "id": this.id,
      "title":this.insertForm.get('title').value,
      "order": this.insertForm.get('order').value,
    }

    this.DrugGroupsService.Update(data).subscribe(
      (response) => {
      Swal.fire({
      icon: "success",
      title: "تم التعديل بنجاح",
      showConfirmButton: false,
      timer: 1500,
      });
      this.router.navigateByUrl("content/admin/ListDrugGroup");
      this.disaple=false
    },
      (err) => {
        this.disaple=false

        Swal.fire({
          icon: "error",
          title:err.error.message
          });
      }
  
      );
  }
  InsertDrugGroup(){
      const data ={
        "title":this.insertForm.get('title').value,
        "order": this.insertForm.get('order').value,
      }
    this.DrugGroupsService.Create(data).subscribe(
      (response) => {
      Swal.fire({
      icon: "success",
      title: "تم الحفظ بنجاح",
      showConfirmButton: false,
      timer: 1500,
      });
      this.router.navigateByUrl("content/admin/ListDrugGroup");
      this.disaple=false

    },
      (err) => {
        this.disaple=false

        Swal.fire({
          icon: "error",
          title:err.error.message
          });
      }
            );
   
 
  }

}
