import { ThrowStmt } from "@angular/compiler";
import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { colorToString } from "@devexpress/analytics-core/analytics-utils";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { LookupsService } from "src/app/shared/API-Service/services/lookups.service";
import { PermissionsApiService } from "src/app/shared/API-Service/services/Permissions-api.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-add-permission",
  templateUrl: "./add-permission.component.html",
  styleUrls: ["./add-permission.component.css"],
})
export class AddPermissionComponent implements OnInit {
  PermissionForm: any = new FormData();
  update: boolean;
  Roles: any[] = [];
  Actions: any[] = [];
  form = {
    name: "",
    role: [],
    description: "",
  };
  err: boolean;
  Menus: any[] = [];
  All: any[] = [];
  menuActions: any[] = [];
  dropdownSettings: IDropdownSettings = {};
  roles: any[] = [];
  PermissionID: any;
  Permission: any[] = [];
  RolesForEdit: any[] = [];
  editPermission: boolean;
  menuId: any;
  defaultMenuId: void;
  MainMenuChange: boolean;

  constructor(
    private PermissionsApiService: PermissionsApiService,
    private LookupsServiceService: LookupsService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.MainMenuChange=false;
    this.editPermission = false;
    this.GetRoles();
     
      
      if (this.route.snapshot.paramMap.get('id')) {

      this.editPermission = true;
      this.PermissionID = this.route.snapshot.paramMap.get('id');
      this.GetPermissionById(this.PermissionID);
    }
  }

  ngOnInit(): void {
    this.InitForm();
    if (!this.PermissionID) {
      this.GetMenu();
    }
    this.GetActions();
    this.update = false;
    this.dropdownSettings = {
      singleSelection: false,
      idField: "id",
      textField: "title",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 3,
      allowSearchFilter: true,
    };
  }
  getCellStyle(actionId: number): any {
    if (actionId === 6 || actionId === 7) {
      return {
        'text-align': 'center',
        'width': '26%',
      };
    } else {
      // Return default styles if actionId is not 5 or 6
      return {};
    }
  }
  InitForm() {
    const group: any = {};

    this.PermissionForm = new FormGroup({
      name: new FormControl(["", Validators.required]),
      role: new FormControl(["", Validators.required]),
      description: new FormControl(["", Validators.required]),
    });
    // this.Actions.forEach(element => {
    //  let elementname= element.id
    //     this.PermissionForm.push({elementname:new FormControl(['', Validators.required]),
    //   })
    // });
  }
  GetMenu() {
    var res;
    this.PermissionsApiService.GetMenu().subscribe({
      next: (data) => {
        res = data;
        if (res.messageCode == 200) {
          this.err = false;
          this.Menus=res.data
          this.Menus=this.Menus.filter(x=>x.parentId!=null);
          this.Menus.forEach(element => {
            if(element.isSubMenu!=false){
              element.actions.push({
              "actionId": 0,
              "inPermission":false
            })
            }})
        } else {
          this.err = true;
        }
      },
      error: (error) => {
        this.err = true;
        Swal.fire({
          icon: 'error',
          title: 'خطأ',
          text: error.error.message,
        })
      },
    });
  }
  GetRoles() {
    var res;
    this.LookupsServiceService.GetRoles().subscribe({
      next: (data) => {
        res = data;
        if (res.messageCode == 200) {
          this.err = false;
          this.Roles = res.data;
        } else {
          this.err = true;
        }
      },
      error: (error) => {
        this.err = true;

        Swal.fire({
          icon: 'error',
          title: 'خطأ',
          text: error.error.message,
        })
      },
    });
  }
  GetActions() {
    var res;
    this.LookupsServiceService.GetActions().subscribe({
      next: (data) => {
        res = data;
        if (res.messageCode == 200) {
          this.err = false;
          this.Actions = res.data;
          this.Actions.unshift({name:"الرئيسية",id:0})
        } else {
          this.err = true;
        }
      },
      error: (error) => {
        this.err = true;
        Swal.fire({
          icon: 'error',
          title: 'خطأ',
          text: error.error.message,
        })
      },
    });
  }
  GetPermissionById(PermissionID) {
    var res;
    this.PermissionsApiService.GetPermissionsById(PermissionID).subscribe({
      next: (data) => {
        res = data;
        if (res.messageCode == 200) {
          this.err = false;
          this.Menus=res.data.allMenu
          this.Menus.forEach(element => {
            if(element.isSubMenu!=false){
              element.actions.push({
              "actionId": 0,
              "inPermission":false
            })
            }
            
            element.actions.forEach(element2 => {
              if(element2.inPermission){
                this.menuActions.push(element2.menuActionId)
              }
            });
          });
          this.defaultMenuId=res.data.defaultMenuId;
        const menuindex=  this.Menus.findIndex(x=>x.id==this.defaultMenuId && x.isSubMenu==true);
        if(menuindex!=-1){
        const actionsindex= this.Menus[menuindex].actions.findIndex(x=>x.actionId==0);
     
                  this.Menus[menuindex].actions[actionsindex].inPermission=true;
        }
          this.form.name = res.data.name;
          this.form.description = res.data.description;
          const roles = res.data.roles;
          if (this.Roles.length == 0) {
            this.GetRoles();
            roles.forEach((element) => {
              const role = this.Roles.filter((x) => x.id == element);
              this.RolesForEdit.push(role[0]);
            });
            // this.form.role=this.RolesForEdit;
            if (this.RolesForEdit.length == 0) {
              // this.GetRoles();
              roles.forEach((element) => {
                const role = this.Roles.filter((x) => x.id == element);
                this.RolesForEdit.push(role[0]);
              });
            }
            if (this.RolesForEdit.length != 0) {
              this.PermissionForm.patchValue({
                role: this.RolesForEdit,
              });
            }
          }
          roles.forEach((element) => {
            const role = this.Roles.filter((x) => x.id == element);
            this.RolesForEdit.push(role[0]);
          });
          // this.form.role=this.RolesForEdit;
          this.PermissionForm.patchValue({
            role: this.RolesForEdit,
          });
        } else {
          this.err = true;
        }
      },
      error: (error) => {
        this.err = true;
        Swal.fire({
          icon: 'error',
          title: 'خطأ',
          text: error.error.message,
        })
      },
    });
  }

  onCheckboxChange(e, i,menuindex,actionmindex) {
    if (e.target.checked) {
      this.Menus.forEach(element => {
        element.actions.forEach(actionselement => {
          if(actionselement.actionId==0 && this.Menus[menuindex].actions[actionmindex].actionId==0){
            actionselement.inPermission=false;
            this.Menus[menuindex].actions[actionmindex].inPermission=true;
            this.menuId=this.Menus[menuindex].id;
            this.MainMenuChange=true
          }
 
          
        });
      });
     
      
if(this.Menus[menuindex].actions[actionmindex].actionId!=0){
   this.menuActions.push(i);
}
     
    }
     else {
      const index = this.menuActions.indexOf(i);
      if (index !== -1) {
        this.menuActions.splice(index, 1);
      }
    }

  }
  onSubmit() {
    if (!this.PermissionForm.get("name").value) {
      Swal.fire({
        icon: "error",
        title: "خطأ",
        text: "ادخل الاسم اولا",
      });
    } else if (!this.PermissionForm.get("role").value) {
      Swal.fire({
        icon: "error",
        title: "خطأ",
        text: "ادخل الدور اولا",
      });
    } else if (!this.PermissionForm.get("description").value) {
      Swal.fire({
        icon: "error",
        title: "خطأ",
        text: "ادخل الوصف اولا",
      });
    } else if (this.menuActions.length == 0) {
      Swal.fire({
        icon: "error",
        title: "خطأ",
        text: "اختر الصلاحيات اولا",
      });
    } else {
      if (!this.editPermission) {
        this.roles=[]
        this.PermissionForm.get("role").value.forEach((element) => {
          this.roles.push(element.id);
        });
        let data = {
          name: this.form.name,
          description: this.form.description,
          inactive: false,
          menuActions: this.menuActions,
          roles: this.roles,
          defaultMenuId: this.menuId
        };
        var res;
        this.PermissionsApiService.InsertPermissions(data).subscribe({
          next: (data) => {
            res = data;
            if (res.messageCode == 200) {
              this.err = false;
              Swal.fire({
                icon: "success",
                title: "تم الحفظ بنجاح",
                showConfirmButton: false,
                timer: 1500,
              });

              this.router.navigateByUrl("content/admin/Permissions");
            } else {
              this.err = true;
            }
          },
          error: (error) => {
            Swal.fire({
              icon: 'error',
              title: 'خطأ',
              text: error.error.message,
            })
            this.err = true;
          },
        });
      } else {
          this.roles=[]
        this.PermissionForm.get("role").value.forEach((element) => {
          this.roles.push(element.id);
        });
        if(this.MainMenuChange){
          let data = {
          name: this.form.name,
          description: this.form.description,
          inactive: false,
          menuActions: this.menuActions,
          roles: this.roles,
          id: this.PermissionID,
          defaultMenuId: this.menuId
        };
        var res;
        this.PermissionsApiService.UpdatePermissions(data).subscribe({
          next: (data) => {
            res = data;
            if (res.messageCode == 200) {
              this.err = false;
              Swal.fire({
                icon: "success",
                title: "تم التعديل بنجاح",
                showConfirmButton: false,
                timer: 1500,
              });

              this.router.navigateByUrl("content/admin/Permissions");
            } else {
              this.err = true;
            }
          },
          error: (error) => {
            this.err = true;
            Swal.fire({
              icon: 'error',
              title: 'خطأ',
              text: error.error.message,
            })
          },
        });
        }
        else{
          let data = {
            name: this.form.name,
            description: this.form.description,
            inactive: false,
            menuActions: this.menuActions,
            roles: this.roles,
            id: this.PermissionID,
            defaultMenuId: this.defaultMenuId
          };
          var res;
          this.PermissionsApiService.UpdatePermissions(data).subscribe({
            next: (data) => {
              res = data;
              if (res.messageCode == 200) {
                this.err = false;
                Swal.fire({
                  icon: "success",
                  title: "تم التعديل بنجاح",
                  showConfirmButton: false,
                  timer: 1500,
                });
  
                this.router.navigateByUrl("content/admin/Permissions");
              } else {
                this.err = true;
              }
            },
            error: (error) => {
              this.err = true;
              Swal.fire({
                icon: 'error',
                title: 'خطأ',
                text: error.error.message,
              })
            },
          });
        }
        
      }
    }
  }
}
