import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TipCategoryService } from 'src/app/shared/API-Service/services/tip-category.service';
import Swal from 'sweetalert2';
import {TipCategory} from "../../../../shared/Models/tipcategory";
import { environment } from 'src/environments/environment.prod';
@Component({
  selector: 'app-insert-tip-category',
  templateUrl: './insert-tip-category.component.html',
  styleUrls: ['./insert-tip-category.component.css']
})
export class InsertTipCategoryComponent implements OnInit {
  id: string;
  update: boolean;
  insertForm: FormGroup;
  TipCategoryFormdata = new FormData();

  TipCategory = new TipCategory();
  TipCategories:any;
  Image: any;
  imageLogo: string;
  Uploaded: boolean;
  viewPdf: boolean;
  disaple: boolean;

  constructor(private TipCategoryService:TipCategoryService,private _formBuilder: FormBuilder,private router: Router,private route: ActivatedRoute) { 
    // this.GetDrugGroups();
  }

  ngOnInit(): void {
    if (this.route.snapshot.paramMap.get("id")) {
      this.id =this.route.snapshot.paramMap.get("id")
      this.TipCategoryService.GetTipCategoryById(this.id).subscribe(async (res:any)=>{
      this.TipCategories=  await res.data;
      this.imageLogo=environment.ServerImages_URL+'/'+this.TipCategories.image
        this.initForm(this.TipCategories);
        
      })
      this.update = true;
    } else {
      this.initForm()
      this.update = false;
    }
  }
  initForm(TipCategory?: TipCategory) {
    this.insertForm = this._formBuilder.group({
      title: [TipCategory?.title || '', Validators.required],
      order: [TipCategory?.order || '', Validators.required],
    });
  }

  get fc() {
    return this.insertForm.controls;
  }


  Submit(){
    if (this.insertForm.status == "VALID") {
      this.disaple=true

      if (this.update == true) {
        this.UpdateTipCategory();
      } else {
        this.InsertTipCategory();
      }
    }
     else {
      this.insertForm.markAllAsTouched();
    }
  

  }
  UpdateTipCategory(){
    this.TipCategoryFormdata.set('CategoryImage',this.Image)
    this.TipCategoryFormdata.set('id',this.id)
    this.TipCategoryFormdata.set('title',this.insertForm.get('title').value)
    this.TipCategoryFormdata.set('order',this.insertForm.get('order').value)
    this.TipCategoryService.Update(this.TipCategoryFormdata).subscribe(
      (response) => {
      Swal.fire({
      icon: "success",
      title: "تم التعديل بنجاح",
      showConfirmButton: false,
      timer: 1500,
      });
      this.router.navigateByUrl("content/admin/ListTipCategory");
      this.disaple=false
    },
      (err) => {
        this.disaple=false

        Swal.fire({
          icon: "error",
          title:err.error.message
          });
      }
  
      );
  }
  getLogoUrl(event: any) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.Image = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.imageLogo = reader.result as string;
          this.Uploaded=true
          if(this.Image.type=="application/pdf"){
            this.viewPdf=true;
          }
          else{
            this.viewPdf=false;
          }
        // this.logoForm?.append("image", this.Image);
      };
    }
  }
  InsertTipCategory(){
    this.TipCategoryFormdata.set('CategoryImage',this.Image)
    this.TipCategoryFormdata.set('title',this.insertForm.get('title').value)
    this.TipCategoryFormdata.set('order',this.insertForm.get('order').value)
    this.TipCategoryService.Create(this.TipCategoryFormdata).subscribe(
      (response) => {
      Swal.fire({
      icon: "success",
      title: "تم الحفظ بنجاح",
      showConfirmButton: false,
      timer: 1500,
      });
      this.router.navigateByUrl("content/admin/ListTipCategory");
      this.disaple=false

    },
      (err) => {
        this.disaple=false

        Swal.fire({
          icon: "error",
          title:err.error.message
          });
      }
            );
   
 
  }

}
