import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TipCategoryService } from '../../../../shared/API-Service/services/tip-category.service';
import Swal from 'sweetalert2';
import {BaseComponent } from '../../../../shared/components/base/base.component'
@Component({
  selector: 'app-list-tip-category',
  templateUrl: './list-tip-category.component.html',
  styleUrls: ['./list-tip-category.component.css']
})
export class ListTipCategoryComponent extends BaseComponent implements OnInit {

  constructor(private TipCategoryService:TipCategoryService,private _Router:Router) { super()}
  TipCategory:any
  ngOnInit(): void {
    this.getTipCategory();
    
  }
  pageChanged(event: any){
    this.pageNumber = event.page;// -1 * pageSize;
    this.pager.skipCount = (this.pageNumber - 1) * this.pager.maxResultCount;
    this.TipCategoryService.GetPaged(this.pager).subscribe((res) => {
      this.TipCategory = res.data.items
      this.totalCount=res.data.totalCount;
    
    },(err)=>{
      console.log('their is a problem');
    },() =>{
      console.log('Completed');
    });

  }
  getTipCategory(){
    const data={
      "maxResultCount": this.pager.maxResultCount,
      "skipCount": this.pager.skipCount,
    }
    this.TipCategoryService.GetPaged(data).subscribe((res) => {
      this.TipCategory = res.data.items
      this.totalCount=res.data.totalCount;
    
    },(err)=>{
      console.log('their is a problem');
    },() =>{
      console.log('Completed');
    });
  }
  update(id){
    this._Router.navigate(['/content/admin/update-tip-category',id]);
    }

    delete(id : number){
      Swal.fire({
        title: 'هل تريد مسح العنصر ؟',
        text: "لن يكون لك صلاحية إعادته مره اخرى",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'الغاء',
        confirmButtonText: 'امسح العنصر !'
      }).then((result) => {
        if (result.isConfirmed) {
          this.TipCategoryService.Delete(id).subscribe((res) => {
            Swal.fire({
              icon: "success",
              title: "تم المسح بنجاح",
              showConfirmButton: false,
              timer: 1500,
            });
         this.getTipCategory();
          },(err) => {
            Swal.fire({
              icon: 'error',
              title: 'خطأ',
              text:err.error.message    
            })
          },() => {
            console.log("completed");
          })
        }
      }) 
    }

}
