<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            
            <div class="card">
                <div class="card-header" style="padding-bottom: 5px !important">
                    <div class="row">
                        <div class="col-6">
                            <h5 class="pb-2">بحث</h5>
                        </div>
                    </div>
                </div>
                <div class="card-block ">
                    <div class="row m-0 mb-2">
                        <div class="col-md-3">
                          <label for=""> الاسم
                          </label>
                            <div class="form-group">
                               <input type="text"    (keyup.enter)="Search()"  class="form-control" [(ngModel)]="SearchForm.name"
                               >
                            </div>
                        </div>
                        <div class="col-md-3">
                            <label for=""> موبايل
                            </label>
                              <div class="form-group">
                                 <input type="tel" onlyNumbers    (keyup.enter)="Search()" [maxlength]="11" [minlength]="11"   class="form-control" [(ngModel)]="SearchForm.mobile"
                                 >
                              </div>
                          </div>
                          <div class="col-md-3">
                            <label for=""> الكود
                            </label>
                              <div class="form-group">
                                 <input type="text"    (keyup.enter)="Search()"  class="form-control" [(ngModel)]="SearchForm.code"
                                 >
                              </div>
                          </div>
                          <div class="col-md-3">
                            <label for="">الصيدلي</label>
                            <div class="form-group">
                                  <ng-select
                                  class="js-example-basic-single p-0"
                                  name="District"
                                  placeholder="إختر الصيدلي"
                                  [(ngModel)]="SearchForm.pahrmacistId"
                                  [items]="pharmacists"
                                  bindLabel="name"
                                  bindValue="id"
                                  (keyup.enter)="Search()"
                                  >
                                  </ng-select>
                              </div>
                          </div>
                          <div class="col-md-12">
                            <button class="btn btn-primary pull-right mb-2" (click)="Search()" type="submit">
                                بحث
                              </button>
                </div>
                </div>
                </div>
               
            </div>
            <div class="card">
                <div class="card-header" style="padding-bottom: 5px !important">
                    <div class="row">
                        <div class="col-6">
                            <h5 class="pb-2">قائمة العملاء</h5>
                        </div>
                        <div class="col-6 header-titles">
                            <a routerLink="/content/admin/insert-customer" class="d-block">
                                <button appActionNew class="btn btn-success pull-right" type="button">
                                    <span class="m-2">إدخال عميل</span>
                                    <i class="fa fa-plus"></i>
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
               
                <div class="card-block row">
                    <div class="col-sm-12 col-lg-12 col-xl-12">
                        <div class="table-responsive">
                            <table class="table table-responsive-sm ">
                                <thead>
                                    <tr class=" " style="text-align: start !important;">
                                        <th scope="col"></th>
                                        <th scope="col">الأسم</th>
                                        <th scope="col">الموبايل</th>
                                        <th scope="col">العنوان</th>
                                        <th scope="col">الكود</th>
                                        <th scope="col">تم الاضافة بواسطة</th>
                                        <th scope="col">الصيدلي المتابع</th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let view of Clients;let x = index" class=" " style="text-align: start !important;">
                                        <td scope="row" class="p-2">{{x+1}}</td>
                                        <td class="p-2">{{view.name}}</td>
                                        <td class="p-2">{{view.mobile}}</td>
                                        <td class="p-2">{{view.address}}</td>
                                        <td class="p-2">{{view.code}}</td>   
                                        <td class="p-2">{{view.createdby}}</td>   
                                        <td class="p-2">{{view.pharmacistName}}</td>   
                                            <td class="font-style chose">
                                                <button class="btn pull-right" appActionCustomerPrescription
                                                style="color: rgba(35, 118, 241, 0.667); padding: 7px"
                                                (click)="goToCustomerPrescription(view.id)" title="روشته">
                                                الروشتات
                                                <i class="fa fa-file"></i>
                                            </button>
                                                <button class="btn pull-right" appActionCustomerMedicalMeasurement
                                                style="color: rgba(35, 118, 241, 0.667); padding: 7px"
                                                (click)="goToCustomerMedicalMeasurement(view.id)" title="قياسات طبية">
                                                القياسات الطبية
                                                <i class="fa fa-heartbeat"></i>
                                            </button>
                                            
                                                <button class="btn pull-right" 
                                                style="color: rgba(35, 118, 241, 0.667); padding: 7px"
                                                (click)="goToCustomerBody(view.id)" title="قياسات بدنية">
                                                التحليلات البدنية
                                                <i class="fa fa-male"></i>
                                            </button>
                                                <button class="btn pull-right" appActionEdit
                                                style="color: rgba(35, 118, 241, 0.667); padding: 7px"
                                                (click)="update(view.id)">
                                                تعديل ملف العميل
                                                <i class="fa fa-pencil"></i>
                                            </button>
                                                   <!-- <button class="btn  pull-right"  style="color: red; padding: 7px"
                                                   (click)="delete(view.id)">
                                                    <i class="fa fa-trash"></i>
                                                       </button> -->
                                               
                                            
                                            </td>
                                        </tr>
                                </tbody>
                            </table>
                            <div class="d-flex justify-content-center">
                                <pagination [boundaryLinks]="true" [maxSize]="20" [totalItems]="totalCount" previousText="&lsaquo;" nextText="&rsaquo;"
                                  firstText="&laquo;" lastText="&raquo;" [itemsPerPage]="pager.maxResultCount" [(ngModel)]="pageNumber"
                                  (pageChanged)="pageChanged($event)">
                                </pagination>
                              </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>




