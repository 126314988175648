<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card">
                    <div class="card-header">
                      <div class="row">
                        <div class="col-6">
                          <h5>روشتة العميل ( {{CustomerInfo?.name}} )       /    رقم الموبايل   : {{CustomerInfo?.mobile}} </h5>
                        </div>
                        <div class="col-4">
                          <h5> الصيدلي المتابع   : {{CustomerInfo?.pharmacistName}}</h5>
                        </div>
                        <div class="col-2">
                          <button class="back-button" (click)="back()">
                            <span>BACK</span>
                            <div class="arrow-container">
                              <div class="arrow"></div>
                            </div>
                            </button>
                        </div>
                      </div>
                     
                    </div>
                    <div class="card-body">
                      <ul ngbNav #nav="ngbNav" [(activeId)]="active3" (navChange)="onNavChange1($event)" class="nav-tabs">
                        <li [ngbNavItem]="1">
                          <a ngbNavLink> <span>ادوية عادية</span>
                            </a>
                          <ng-template ngbNavContent>
                            <div class="card-header" style="padding-bottom: 5px !important">
                                <div class="row">
                                    <div class="col-6">
                                    </div>
                                    <div class="col-6 header-titles">
                                        <a (click)="AddCustomerPrescription()" class="d-block">
                                            <button class="btn btn-success pull-right" type="button">
                                                <span class="m-2">إدخال  روشتة</span>
                                                <i class="fa fa-plus"></i>
                                            </button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="card-block row">
                                <div class="col-sm-12 col-lg-12 col-xl-12">
                                    <div class="table-responsive">
                                        <table class="table table-responsive-sm text-center">
                                            <thead>
                                                <tr class=" ">
                                                    <th scope="col"></th>
                                                    <th scope="col">اسم الدواء</th>
                                                    <th scope="col">وقت الجرعة</th>
                                                    <th scope="col">عدد الايام \ الساعات</th>
                                                    <th scope="col">الجرعة</th>
                                                    <th scope="col">عدد ايام الدواء</th>
                                                    <th scope="col"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <ng-container *ngFor="let view of CustomerPrescription;let i = index">
            
                                                <tr  >
                                                    <td scope="row" class="p-2">{{i+1}}</td>
                                                    <td class="p-2">{{view.drugTitle}}</td>
                                                    <td class="p-2">{{view.doseTimeTitle}}</td>
                                                    <td class="p-2">{{view.count}}</td>
                                                    <td class="p-2">{{view.doseQuantityTitle}}</td>
                                                    <td class="p-2">{{view.days}}</td>
                                                        <td class="font-style chose">
                                                            <!-- <button style="color: #000;padding: 1px 2px;  background: transparent !important;" (click)="openModal(view.id);$event.stopPropagation()">
                                                                <i class="fa fa-commenting-o" style="font-size: 25px !important"></i>
                                                              </button> -->
                                                            <button class="btn pull-right"
                                                            style="color: rgba(35, 118, 241, 0.667); padding: 7px"
                                                            (click)="update(view.id)">
                                                            <i class="fa fa-pencil"></i>
                                                        </button>
                                                               <button class="btn  pull-right"  style="color: red; padding: 7px"
                                                               (click)="DeleteNormalCustomerPrescription(view.id)">
                                                                <i class="fa fa-trash"></i>
                                                                   </button>
                                                           
                                                        
                                                        </td>
                                                    </tr>
                                            
                                                </ng-container>
                                            </tbody>
                                        </table>
                                        <div class="d-flex justify-content-center">
                                            <pagination [boundaryLinks]="true" [maxSize]="20" [totalItems]="totalCount" previousText="&lsaquo;" nextText="&rsaquo;"
                                              firstText="&laquo;" lastText="&raquo;" [itemsPerPage]="pager.maxResultCount" [(ngModel)]="pageNumber"
                                              (pageChanged)="pageChanged($event)">
                                            </pagination>
                                          </div>
                                    </div>
                                </div>
                            </div>
                          </ng-template>
                        </li>
                        <li [ngbNavItem]="2">
                          <a ngbNavLink> <span>ادوية شهرية</span> </a>
                          <ng-template ngbNavContent>
                            <div class="card-header" style="padding-bottom: 5px !important">
                                <div class="row">
                                    <div class="col-6">
                                        <!-- <h5 class="pb-2"> روشتة العميل</h5> -->
                                    </div>
                                    <div class="col-6 header-titles">
                                        <a (click)="AddCustomerPrescriptionSchedule()" class="d-block">
                                            <button appActionNew class="btn btn-success pull-right" type="button">
                                                <span class="m-2">إدخال  روشتة</span>
                                                <i class="fa fa-plus"></i>
                                            </button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="card-block row">
                                <div class="col-sm-12 col-lg-12 col-xl-12">
                                    <div class="table-responsive">
                                        <table class="table table-responsive-sm text-center">
                                            <thead>
                                                <tr class=" ">
                                                    <th scope="col"></th>
                                                    <th scope="col">الأسم</th>
                                                    <th scope="col">نوع الجرعة</th>
                                                    <th scope="col">التاريخ</th>
                                                    <th scope="col">بواسطة</th>
                                                    <th scope="col"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <ng-container *ngFor="let view of CustomerPrescriptionSchedule;let x = index">
            
                                                <tr  (click)="OpenDetailes(x)" style="cursor:pointer; ">
                                                    <td scope="row" class="p-2">{{x+1}}</td>
                                                    <td class="p-2">{{view.title}}</td>
                                                    <td class="p-2">{{view.doseTypeTitle}}</td>
                                                    <td class="p-2">{{view.creationDate | date:"dd/MM/yyyy"}}</td>
                                                    <td class="p-2">{{view.createdByName}}</td>
                                                        <td class="font-style chose">
                                                            <button style="color: #000;padding: 1px 2px;  background: transparent !important;" (click)="openModal(view.id);$event.stopPropagation()">
                                                                <i class="fa fa-commenting-o" style="font-size: 25px !important"></i>
                                                              </button>
                                                            <button class="btn pull-right" appActionEdit
                                                            style="color: rgba(35, 118, 241, 0.667); padding: 7px"
                                                            (click)="updateSchedule(view.id)">
                                                            <i class="fa fa-pencil"></i>
                                                        </button>
                                                               <button class="btn  pull-right" appActionDelete style="color: red; padding: 7px"
                                                               (click)="delete(view.id)">
                                                                <i class="fa fa-trash"></i>
                                                                   </button>
                                                           
                                                        
                                                        </td>
                                                    </tr>
                                                    <tr style="text-align: center;">
                                                        <td style="border-left: none; border-top: none;"></td>
                                                        <td style="border-left: none;border-right: none; ; border-top: none;"></td>
                                                        <td colspan="12" *ngIf="view.ShowDetails" style="border-right: none;">
                                                          <table style="width: 102.59%;
                                                           margin-right: -8px;
                                                          margin-bottom: 10px;">
                                                            <thead>
                                                            </thead>
                                                            <tr  style="color: black !important; background-color: #EE7A24;">
                                                              <th scope="col" class="font-style-header text-center">#</th>
                                                              <th scope="col" class="font-style-header text-center">اسم الدواء</th>
                                                              <th scope="col" class="font-style-header text-center">وقت الجرعة</th>
                                                              <th scope="col" class="font-style-header text-center">عدد الأيام/الساعات</th>
                                                              <th scope="col" class="font-style-header text-center">الجرعة</th>
                                                              <th scope="col" class="font-style-header text-center">عدد أيام الدواء</th>
                                                              <th scope="col" class="font-style-header text-center">  اول جرعه</th>
                                                            </tr>
                                                            <tbody style="background-color: white !important;">
                                                              <tr *ngFor="let customerPrescriptionDrugs of view.customerPrescriptionDrugs let i2 = index" style=" background-color: #e7e6e6;">
                                                                <td scope="row" class="font-style text-center">{{i2+1}}</td>
                                                                <td class="font-style text-center">{{customerPrescriptionDrugs.drugTitle}}</td>
                                                                <td class="font-style text-center">{{customerPrescriptionDrugs.doseTimeTitle}}</td>
                                                                <td class="font-style text-center">{{customerPrescriptionDrugs.count}}</td>
                                                                <td class="font-style text-center">{{customerPrescriptionDrugs.doseQuantityTitle}}</td>
                                                                <td class="font-style text-center"> {{customerPrescriptionDrugs.days}}</td>
                                                                <td class="font-style text-center">{{customerPrescriptionDrugs.startDate}}</td>
                                                              </tr>
                                                            </tbody>
                                                          </table>
                    
                                                        </td>
                                                      </tr>
                                                </ng-container>
                                            </tbody>
                                        </table>
                                        <div class="d-flex justify-content-center">
                                            <pagination [boundaryLinks]="true" [maxSize]="20" [totalItems]="ScheduletotalCount" previousText="&lsaquo;" nextText="&rsaquo;"
                                              firstText="&laquo;" lastText="&raquo;" [itemsPerPage]="SchedulemaxResultCount" [(ngModel)]="SchedulepageNumber"
                                              (pageChanged)="SchedulepageChanged($event)">
                                            </pagination>
                                          </div>
                                    </div>
                                </div>
                            </div>
                          </ng-template>
                        </li>
                      </ul>
                      <div [ngbNavOutlet]="nav" class="mt-2"></div>
                    </div>
                  
                  </div>
              
            </div>
        </div>
    </div>
</div>




