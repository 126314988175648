import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UsersService } from '../../../../shared/API-Service/services/users.service';
import { LookupsService } from 'src/app/shared/API-Service/services/lookups.service';
import Swal from 'sweetalert2';
import {User} from "../../../../shared/Models/User";
@Component({
  selector: 'app-insert-user',
  templateUrl: './insert-user.component.html',
  styleUrls: ['./insert-user.component.css']
})
export class InsertUserComponent implements OnInit {
  id: string;
  update: boolean;
  insertForm: FormGroup;
  UserModal = new User();
  Districts:any[]=[];
  Customer: any;
  Roles: any;
  User: any;
  isRoleIdInvalid: boolean;
  disaple: boolean;
  constructor(private LookupsService:LookupsService, private UsersService:UsersService,private _formBuilder: FormBuilder,private router: Router,private route: ActivatedRoute) { 
 this.GetRoles();
  }
  
  ngOnInit(): void {
    if (this.route.snapshot.paramMap.get("id")) {
      this.id =this.route.snapshot.paramMap.get("id")
      this.UsersService.GetUserById(this.id).subscribe(async (res:any)=>{
      this.User=  await res.data;
        this.initForm(this.User);
        
      })
      this.update = true;
    } else {
      this.initForm()
      this.update = false;
    }
  }
  initForm(User?: User) {
    this.insertForm = this._formBuilder.group({
      name: [User?.name || '', Validators.required],
      mobile: [User?.mobile || '', Validators.required],
      roleId: [User?.roleId || '', Validators.required],
      password: [User?.password || '', Validators.required]
    });
  }

  get fc() {
    return this.insertForm.controls;
  }
GetRoles(){
  this.LookupsService.GetRoles().subscribe(res=>{
    this.Roles=res.data
  })
}

  Submit(){
    if(this.update==true){
      this.insertForm.get("password").clearValidators();
      this.insertForm.get("password").updateValueAndValidity();
    }
    if (this.insertForm.status == "VALID") {
      this.disaple=true
      if (this.update == true) {
        this.UpdateCustomer();
      } else {
        this.InsertCustomer();
      }
    }
     else {
      this.insertForm.markAllAsTouched();
    }
  

  }
  UpdateCustomer(){
    const data ={
      "id": this.id,
      "name":this.insertForm.get('name').value,
      "mobile": this.insertForm.get('mobile').value,
      "roleId": Number(this.insertForm.get('roleId').value),
    }

    this.UsersService.Update(data).subscribe(
      (response) => {
      Swal.fire({
      icon: "success",
      title: "تم التعديل بنجاح",
      showConfirmButton: false,
      timer: 1500,
      });
      this.router.navigateByUrl("content/admin/ListUser");
      this.disaple=false
    },
      (err) => {
        this.disaple=false
        Swal.fire({
          icon: "error",
          title:err.error.message
          });
      }
  
      );
  }
  InsertCustomer(){
      const data ={
        "name":this.insertForm.get('name').value,
        "mobile": this.insertForm.get('mobile').value,
        "roleId": Number(this.insertForm.get('roleId').value),
        "password": this.insertForm.get('password').value,
      }
    this.UsersService.Create(data).subscribe(
      (response) => {
      Swal.fire({
      icon: "success",
      title: "تم الحفظ بنجاح",
      showConfirmButton: false,
      timer: 1500,
      });
      this.router.navigateByUrl("content/admin/ListUser");
      this.disaple=false

    },
      (err) => {
        this.disaple=false
        Swal.fire({
          icon: "error",
          title:err.error.message
          });
      }
            );
   
 
  }
}
