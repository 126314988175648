import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HelpService } from '../../../../shared/API-Service/services/help.service';
import Swal from 'sweetalert2';
import {BaseComponent } from '../../../../shared/components/base/base.component'
@Component({
  selector: 'app-list-help',
  templateUrl: './list-help.component.html',
  styleUrls: ['./list-help.component.css']
})
export class ListHelpComponent extends BaseComponent  implements OnInit {
  Helps:any;
  constructor(private HelpService:HelpService,private _Router:Router) { super()}

  ngOnInit(): void {
    this.getHelps();
    
  }
  pageChanged(event: any){
    this.pageNumber = event.page;// -1 * pageSize;
    this.pager.skipCount = (this.pageNumber - 1) * this.pager.maxResultCount;
    this.HelpService.GetPaged(this.pager).subscribe((res) => {
      this.Helps = res.data.items
      this.totalCount=res.data.totalCount;
    console.log(this.Helps);
    
    },(err)=>{
      console.log('their is a problem');
    },() =>{
      console.log('Completed');
    });

  }
  getHelps(){
    const data={
      "maxResultCount": this.pager.maxResultCount,
      "skipCount": this.pager.skipCount,
    }
    this.HelpService.GetPaged(data).subscribe((res) => {
      this.Helps = res.data.items
      this.totalCount=res.data.totalCount;
    console.log(this.Helps);
    
    },(err)=>{
      console.log('their is a problem');
    },() =>{
      console.log('Completed');
    });
  }
  update(id){
    console.log(id);
    this._Router.navigate(['/content/admin/update-Help',id]);
    }

    delete(id : number){
      Swal.fire({
        title: 'هل تريد مسح العنصر ؟',
        text: "لن يكون لك صلاحية إعادته مره اخرى",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'الغاء',
        confirmButtonText: 'امسح العنصر !'
      }).then((result) => {
        if (result.isConfirmed) {
          this.HelpService.Delete(id).subscribe((res) => {
            Swal.fire({
              icon: "success",
              title: "تم المسح بنجاح",
              showConfirmButton: false,
              timer: 1500,
            });
         this.getHelps();
          },(err) => {
            Swal.fire({
              icon: 'error',
              title: 'خطأ',
              text:err.error.message    
            })
          },() => {
            console.log("completed");
          })
        }
      }) 
    }


}
