<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header" style="padding-bottom: 5px !important">
                    <div class="row">
                        <div class="col-6">
                            <h5 class="pb-2"> قائمة قياسات البدن للعميل</h5>
                        </div>
                        <div class="col-6">
                            <button class="back-button pull-right mb-4" (click)="back()">
                                <span>BACK</span>
                                <div class="arrow-container">
                                  <div class="arrow"></div>
                                </div>
                                </button>
                        </div>
                    </div>
                    <div class="row">
                      
                        <div class="col-12 header-titles">
                            <a (click)="New()" class="d-block">
                                <button class="btn btn-success pull-right" type="button">
                                    <span class="m-2">إدخال قياس بدن</span>
                                    <i class="fa fa-plus"></i>
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="card-block row">
                    <div class="col-sm-12 col-lg-12 col-xl-12">
                        <div class="table-responsive">
                            <table class="table table-responsive-sm ">
                                <thead>
                                    <tr class=" " style="text-align: start !important;">
                                        <th scope="col"></th>
                                        <th scope="col">اسم العميل</th>
                                        <th scope="col">التاريخ</th>
                                        <th scope="col">بواسطه</th>
                                        <th scope="col">الملف</th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let view of Customerinbodytest;let x = index" class=" " style="text-align: start !important;">
                                        <td scope="row" class="p-2">{{x+1}}</td>
                                        <td class="p-2">{{view.customerName}}</td>
                                        <td class="p-2">{{view.date}}</td>
                                        <td class="p-2">{{view.createdByName}}</td>
                                        <td class="p-2">
                                                <img  *ngIf="view.ex!='pdf'"   [src]="view.test"  height="50" width="50"  (click)="OpenImage(view.test)">
                                                <img   *ngIf="view.ex=='pdf'"  src="../../../../assets/images/pdfImage.png"  height="50" width="50"  (click)="OpenPdf(view.test)">
                                        </td>
                                            <td class="font-style chose">
                                                <button class="btn pull-right"
                                                style="color: rgba(35, 118, 241, 0.667); padding: 7px"
                                                (click)="update(view.id)">
                                                <i class="fa fa-pencil"></i>
                                            </button>
                                                   <button class="btn  pull-right"  style="color: red; padding: 7px"
                                                   (click)="delete(view.id)">
                                                    <i class="fa fa-trash"></i>
                                                       </button>
                                               
                                            
                                            </td>
                                        </tr>
                                </tbody>
                            </table>
                            <div class="d-flex justify-content-center">
                                <pagination [boundaryLinks]="true" [maxSize]="20" [totalItems]="totalCount" previousText="&lsaquo;" nextText="&rsaquo;"
                                  firstText="&laquo;" lastText="&raquo;" [itemsPerPage]="pager.maxResultCount" [(ngModel)]="pageNumber"
                                  (pageChanged)="pageChanged($event)">
                                </pagination>
                              </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>




