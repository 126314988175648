<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header" style="padding-bottom: 5px !important">
                    <div class="row">
                        <div class="col-6">
                            <h5 class="pb-2">  قائمة الادوية المزمنه المنتهية</h5>
                        </div>
                        <!-- <div class="col-6 header-titles">
                            <a class="d-block">
                                <button class="btn btn-primary pull-right" (click)="NotifyCustomerDrugAlmostFinished()"
                                    [disabled]="SendDisable || customerPrescriptionDrugsIds.length==0" type="button">
                                    <span class="m-2">ارسال اشعار</span>
                                    <i class="fa fa-paper-plane"></i>
                                </button>
                            </a>
                        </div> -->
                    </div>
                </div>
                <div class="card-block row">
                    <div class="col-sm-12 col-lg-12 col-xl-12">
                        <div class="table-responsive">
                            <table class="table table-responsive-sm ">
                                <thead>
                                    <tr class=" " style="text-align: start !important;">
                                        <th scope="col"></th>
                                        <th scope="col">اسم العميل</th>
                                        <th scope="col">رقم الموبايل</th>
                                        <th scope="col">الكود</th>
                                        <th scope="col">اسم الروشتة</th>
                                        <th scope="col">نوع الدواء</th>
                                        <th scope="col">الصيدلي المتابع</th>
                                        <!-- <th scope="col" class="font-style-header text-center"> الاشعار
                                        </th>
                                        <th scope="col" class="font-style-header text-center"> تاريخ اخر
                                            الاشعار</th> -->
                                            <th scope="col"></th>

                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngFor="let view of DrugAlmostFinished ;let x = index">

                                        <tr (click)="OpenDetailes(x)"
                                            style="cursor:pointer;text-align: start !important; ">
                                            <td scope="row" class="p-2">{{x+1}}</td>
                                            <td class="p-2">{{view.customerName}}</td>
                                            <td class="p-2">{{view.customerMobile}}</td>
                                            <td class="p-2">{{view.customerCode}}</td>
                                            <td class="p-2">{{view.title}}</td>
                                            <td class="p-2">{{view.doseTypeTitle}}</td>
                                            <td class="p-2">{{view.pharmacistName}}</td>   
                                            <!-- <td *ngIf="view.notified==true"
                                            class="font-style text-center">تم الاشعار </td>
                                        <td *ngIf="view.notified==false"
                                            class="font-style text-center">لم يتم الاشعار </td> -->
                                        <!-- <td class="font-style text-center">
                                            {{view.lastNotifyDate |
                                            date:'medium'}}</td> -->
                                            <td class="font-style chose">
                                                <!-- <button class="btn  pull-right"
                                                style="color: rgb(95, 100, 246); padding: 7px"
                                                (click)="NotifyCustomerDrugAlmostFinished(view.id)" placement="top" ngbTooltip="ارسال اشعار">
                                                <i class="fa fa-paper-plane"></i>
                                            </button> -->
                                            <button class="btn  pull-right"
                                            style="color: rgb(95, 100, 246); padding: 7px"
                                            (click)="RenewPrescription(view.id)" placement="top" ngbTooltip="صرف">
                                            <i class="fa fa-check-square-o"></i>
                                        </button>
                                                <!-- <button class="btn  pull-right"
                                                    style="color: red; padding: 7px"
                                                    (click)="DismissNotifyFinished(view.id)" placement="top" ngbTooltip="تجاهل">
                                                    <i class="fa fa-eye-slash"></i>
                                                </button> -->
                                            </td>
                                        </tr>
                                        <tr style="text-align: center;">
                                            <td style="border-left: none; border-top: none;"></td>
                                            <td style="border-left: none;border-right: none; ; border-top: none;"></td>
                                            <td colspan="12" *ngIf="view.ShowDetails" style="border-right: none;">
                                                <table style="width: 102.59%;
                                               margin-right: -8px;
                                              margin-bottom: 10px;">
                                                    <thead>
                                                    </thead>
                                                    <tr style="color: black !important; background-color: #EE7A24;">
                                                        <th scope="col"  class="font-style-header text-center">#</th>
                                                        <th scope="col" class="font-style-header text-center">اسم الدواء
                                                        </th>
                                                        <th scope="col" class="font-style-header text-center">العدد</th>
                                                        <th scope="col" class="font-style-header text-center">الايام
                                                        </th>
                                                        <th scope="col" class="font-style-header text-center">وقت الجرعة
                                                        </th>
                                                        <th scope="col" class="font-style-header text-center"> تاريخ اول
                                                            جرعه</th>
                                                        <th scope="col" class="font-style-header text-center"> تاريخ اخر
                                                            جرعه</th>




                                                    </tr>
                                                    <tbody style="background-color: white !important;">
                                                        <tr *ngFor="let customerPrescriptionDrugs of view.customerPrescriptionDrugs let i2 = index">
                                                            <td scope="row" style="color: black !important;" class="font-style text-center">{{i2+1}}</td>
                                                            <td class="font-style text-center" style="color: black !important;">
                                                                {{customerPrescriptionDrugs.drugTitle}}</td>
                                                            <td class="font-style text-center" style="color: black !important;">
                                                                {{customerPrescriptionDrugs.count}}</td>
                                                            <td class="font-style text-center" style="color: black !important;">
                                                                {{customerPrescriptionDrugs.days}}</td>
                                                            <td class="font-style text-center" style="color: black !important;">
                                                                {{customerPrescriptionDrugs.doseTimeTitle}}</td>
                                                            <td class="font-style text-center" style="color: black !important;">
                                                                {{customerPrescriptionDrugs.startDate | date:'medium'}}
                                                            </td>
                                                            <td class="font-style text-center" style="color: black !important;">
                                                                {{customerPrescriptionDrugs.endDate | date:'medium'}}
                                                            </td>
                                                     

                                                            
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <br>
                                            </td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                            <div class="d-flex justify-content-center">
                                <pagination [boundaryLinks]="true" [maxSize]="20" [totalItems]="totalCount"
                                    previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;"
                                    [itemsPerPage]="pager.maxResultCount" [(ngModel)]="pageNumber"
                                    (pageChanged)="pageChanged($event)">
                                </pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>