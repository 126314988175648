import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
@Injectable({
  providedIn: 'root'
})
export class RegisterRequestService {
  public Data = new BehaviorSubject(null)
  constructor(private _HttpClient:HttpClient) { }
  Get():Observable<any>{
    return this._HttpClient.get(`${environment.Server_URL}/RegisterRequest/Get`);
   }
   GetPaged(data:any):Observable<any>{
    return this._HttpClient.post(`${environment.Server_URL}/RegisterRequest/GetAllPaged`,data);
   }
   GetRegisterRequestById(id:any):Observable<any>{
    return this._HttpClient.get(`${environment.Server_URL}/RegisterRequest/GetById?id=${id}`);
   }
   Create(data):Observable<any>{
  
   return this._HttpClient.post(`${environment.Server_URL}/RegisterRequest/Create`, data  );
  }

  Update(data):Observable<any>{
  
   return this._HttpClient.post(`${environment.Server_URL}/RegisterRequest/Update`, data  );
  }

  Delete(id):Observable<any>{
   
   return this._HttpClient.get(`${environment.Server_URL}/RegisterRequest/Delete?id=${id}` ); 
 }
 Reject(id):Observable<any>{
   
  return this._HttpClient.get(`${environment.Server_URL}/RegisterRequest/Reject?id=${id}` ); 
}
}
