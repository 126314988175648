import { NgModule, Component } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { InsertCustomerinbodytestComponent } from "./CustomerInBodyTest/insert-customerinbodytest/insert-customerinbodytest.component";
import { ListCustomerinbodytestComponent } from "./CustomerInBodyTest/list-customerinbodytest/list-customerinbodytest.component";
import { InsertCustomerMedicalMeasurementComponent } from "./CustomerMedicalMeasurement/insert-customer-medical-measurement/insert-customer-medical-measurement.component";
import { ListCustomerMedicalMeasurementComponent } from "./CustomerMedicalMeasurement/list-customer-medical-measurement/list-customer-medical-measurement.component";
import { InsertCustomerPrescriptionComponent } from "./CustomerPrescription/insert-customer-prescription/insert-customer-prescription.component";
import { ListCustomerPrescriptionComponent } from "./CustomerPrescription/list-customer-prescription/list-customer-prescription.component";
import { InsertCustomerComponent } from "./Customers/insert-customer/insert-customer.component";
import { ListCustomerComponent } from "./Customers/list-customer/list-customer.component";
import { InsertDistrictComponent } from "./Districts/insert-district/insert-district.component";
import { ListDistrictsComponent } from "./Districts/list-districts/list-districts.component";
import { InsertDrugComponent } from "./Drug/insert-drug/insert-drug.component";
import { ListDrugsComponent } from "./Drug/list-drugs/list-drugs.component";
import { ListDrugAlmostFinishedComponent } from "./DrugAlmostFinished/list-drug-almost-finished/list-drug-almost-finished.component";
import { InsertDrugGroupComponent } from "./DrugGroup/insert-drug-group/insert-drug-group.component";
import { ListDrugGroupComponent } from "./DrugGroup/list-drug-group/list-drug-group.component";
import { InsertMedicalMeasurementComponent } from "./MedicalMeasurement/insert-medical-measurement/insert-medical-measurement.component";
import { ListMedicalMeasurementComponent } from "./MedicalMeasurement/list-medical-measurement/list-medical-measurement.component";
import { InsertTipsComponent } from "./Tip/insert-tips/insert-tips.component";
import { ListTipsComponent } from "./Tip/list-tips/list-tips.component";
import { InsertTipCategoryComponent } from "./TipCategory/insert-tip-category/insert-tip-category.component";
import { ListTipCategoryComponent } from "./TipCategory/list-tip-category/list-tip-category.component";
import { InsertUserComponent } from "./Users/insert-user/insert-user.component";
import { ListUsersComponent } from "./Users/list-users/list-users.component";
import { InsertVideoAlbumComponent } from "./VideoAlbum/insert-video-album/insert-video-album.component";
import { ListVideoAlbumComponent } from "./VideoAlbum/list-video-album/list-video-album.component";
import { InsertVideoComponent } from "./Videos/insert-video/insert-video.component";
import { ListVideosComponent } from "./Videos/list-videos/list-videos.component";
import { ListRegisterRequestComponent } from "./RegisterRequest/list-register-request/list-register-request.component";
import { InsertRegisterRequestComponent } from "./RegisterRequest/insert-register-request/insert-register-request.component";
import { InsertCustomerNormalDrugPresciptionComponent } from "./CustomerPrescription/insert-customer-normal-drug-presciption/insert-customer-normal-drug-presciption.component";
import { AllPermissionComponent } from "./Permissions/all-permission/all-permission.component";
import { AddPermissionComponent } from "./Permissions/add-permission/add-permission.component";
import { ChangePasswordComponent } from "./User/change-password/change-password.component";
import { AuthGuardGuard } from '../../shared/services/auth-guard.guard'
import { ListHelpComponent } from "./Help/list-help/list-help.component";
import { InsertHelpComponent } from "./Help/insert-help/insert-help.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { CustomerWithoutTokenComponent } from "./Customers/customer-without-token/customer-without-token.component";
import { CustomerPrescriptionDetailsComponent } from "./CustomerPrescription/customer-prescription-details/customer-prescription-details.component";
import { CustomerMedicalMeasurementDetailsComponent } from "./CustomerMedicalMeasurement/customer-medical-measurement-details/customer-medical-measurement-details.component";
import { AllUnverifiedDrugsComponent } from "./DrugAlmostFinished/list-drug-almost-finished/all-unverified-drugs/all-unverified-drugs.component";

// ChangePassword Component





const routes: Routes = [
    {
        path: "",
        children: [
            {
            //  canActivate: [AuthGuardGuard],
                path: "ChangePassword",
                component: ChangePasswordComponent,
            },
            {
                  path: "Dashboard",
                  component: DashboardComponent,
              },
            {
                canActivate: [AuthGuardGuard],
                path: "ListUser",
                component: ListUsersComponent,
            },
            {
                canActivate: [AuthGuardGuard],
                path: "insert-User",
                component: InsertUserComponent,
            },
            {
                canActivate: [AuthGuardGuard],
                path: "update-User/:id",
                component: InsertUserComponent,
            },
            {
                canActivate: [AuthGuardGuard],
                path: "Permissions",
                component: AllPermissionComponent,
              },
              {
               canActivate: [AuthGuardGuard],
                path: "AddPermissions",
                component: AddPermissionComponent,
              },
              {
                canActivate: [AuthGuardGuard],
                path: "updatePermissions/:id",
                component: AddPermissionComponent,
        
              },
            {
                canActivate: [AuthGuardGuard],
                path: "ListCustomers",
                component: ListCustomerComponent,
            },
            {
             //   canActivate: [AuthGuardGuard],
                path: "ListCustomerWithoutToken",
                component: CustomerWithoutTokenComponent,  
            },
            {
                //   canActivate: [AuthGuardGuard],
                   path: "CustomerPrescriptionDetails/:id",
                   component: CustomerPrescriptionDetailsComponent,  
            },
            {
                //   canActivate: [AuthGuardGuard],
                   path: "CustomerMedicalMeasurementDetails/:id",
                   component: CustomerMedicalMeasurementDetailsComponent,  
            },
            {
                //   canActivate: [AuthGuardGuard],
                   path: "AllUnverifiedDrugs",
                   component: AllUnverifiedDrugsComponent,  
            },
            {
                canActivate: [AuthGuardGuard],
                path: "insert-customer",
                component: InsertCustomerComponent,
            },
            {
                canActivate: [AuthGuardGuard],
                path: "update-customer/:id",
                component: InsertCustomerComponent,
            },
            {
                canActivate: [AuthGuardGuard],
                path: "insert-customer/:registerRequestId",
                component: InsertCustomerComponent,
            },
            {
                canActivate: [AuthGuardGuard],
                path: "ListDistricts",
                component: ListDistrictsComponent,
            },
            {
                canActivate: [AuthGuardGuard],
                path: "insert-district",
                component: InsertDistrictComponent,
            },
            {
                canActivate: [AuthGuardGuard],
                path: "update-district/:id",
                component: InsertDistrictComponent,
            },
            {
                canActivate: [AuthGuardGuard],
                path: "ListDrugs",
                component: ListDrugsComponent,
            },
            {
                canActivate: [AuthGuardGuard],
                path: "insert-Drug",
                component: InsertDrugComponent,
            },
            {
                canActivate: [AuthGuardGuard],
                path: "verify-Drug/:drugid",
                component: InsertDrugComponent,
            },
            {
                canActivate: [AuthGuardGuard],
                path: "update-Drug/:id",
                component: InsertDrugComponent,
            },
            {
                canActivate: [AuthGuardGuard],
                path: "ListDrugGroup",
                component: ListDrugGroupComponent,
            },
            {
                canActivate: [AuthGuardGuard],
                path: "insert-drug-group",
                component: InsertDrugGroupComponent,
            },
            {
                canActivate: [AuthGuardGuard],
                path: "update-drug-group/:id",
                component: InsertDrugGroupComponent,
            },

            {
                canActivate: [AuthGuardGuard],
                path: "ListTipCategory",
                component: ListTipCategoryComponent,
            },
            {
                canActivate: [AuthGuardGuard],
                path: "insert-tip-category",
                component: InsertTipCategoryComponent,
            },
            {
                canActivate: [AuthGuardGuard],
                path: "update-tip-category/:id",
                component: InsertTipCategoryComponent,
            },
            {
                canActivate: [AuthGuardGuard],
                path: "ListTip",
                component: ListTipsComponent,
            },
            {
                canActivate: [AuthGuardGuard],
                path: "insert-tip",
                component: InsertTipsComponent,
            },
            {
                canActivate: [AuthGuardGuard],
                path: "update-tip/:id",
                component: InsertTipsComponent,
            },
            {
                canActivate: [AuthGuardGuard],
                path: "ListCustomerinbodytest/:id",
                component: ListCustomerinbodytestComponent,
            },
            {
                canActivate: [AuthGuardGuard],
                path: "insert-customerinbodytest/:CustomerId",
                component: InsertCustomerinbodytestComponent,
            },
            {
                canActivate: [AuthGuardGuard],
                path: "update-customerinbodytest/:id/:CustomerId",
                component: InsertCustomerinbodytestComponent,
            },
            {
                canActivate: [AuthGuardGuard],
                path: "ListVideoAlbum",
                component: ListVideoAlbumComponent,
            },
            {
                canActivate: [AuthGuardGuard],
                path: "insert-VideoAlbum",
                component: InsertVideoAlbumComponent,
            },
            {
                canActivate: [AuthGuardGuard],
                path: "update-VideoAlbum/:id",
                component: InsertVideoAlbumComponent,
            },
            {
                canActivate: [AuthGuardGuard],
                path: "ListVideo",
                component: ListVideosComponent,
            },
            {
                canActivate: [AuthGuardGuard],
                path: "insert-Video",
                component: InsertVideoComponent,
            },
            {
                canActivate: [AuthGuardGuard],
                path: "update-Video/:id",
                component: InsertVideoComponent,
            },
            {
                canActivate: [AuthGuardGuard],
                path: "ListMedicalMeasurement",
                component: ListMedicalMeasurementComponent,
            },
            {
                canActivate: [AuthGuardGuard],
                path: "insert-MedicalMeasurement",
                component: InsertMedicalMeasurementComponent,
            },
            {
                canActivate: [AuthGuardGuard],
                path: "update-MedicalMeasurement/:id",
                component: InsertMedicalMeasurementComponent,
            },
            {
                canActivate: [AuthGuardGuard],
                path: "ListCustomerMedicalMeasurement/:id",
                component: ListCustomerMedicalMeasurementComponent,
            },
            {
                canActivate: [AuthGuardGuard],
                path: "insert-CustomerMedicalMeasurement/:Customerid",
                component: InsertCustomerMedicalMeasurementComponent,
            },
            {
                canActivate: [AuthGuardGuard],
                path: "update-CustomerMedicalMeasurement/:id/:Customerid",
                component: InsertCustomerMedicalMeasurementComponent,
            },
            
            {
                canActivate: [AuthGuardGuard],
                path: "ListCustomerPrescription/:id",
                component: ListCustomerPrescriptionComponent,
            },
            {
                canActivate: [AuthGuardGuard],
                path: "insert-Customernormaldrug/:Customerid",
                component: InsertCustomerNormalDrugPresciptionComponent,
            },
            {
                canActivate: [AuthGuardGuard],
                path: "update-Customernormaldrug/:id/:Customerid",
                component: InsertCustomerNormalDrugPresciptionComponent,
            },
            {
                canActivate: [AuthGuardGuard],
                path: "insert-CustomerPrescriptionschedule/:Customerid",
                component: InsertCustomerPrescriptionComponent,
            },
            {
                canActivate: [AuthGuardGuard],
                path: "update-CustomerPrescription/:id/:Customerid",
                component: InsertCustomerPrescriptionComponent,
            },
            {
                canActivate: [AuthGuardGuard],
                path: "ListRegisterRequest",
                component: ListRegisterRequestComponent,
            },
            {
                canActivate: [AuthGuardGuard],
                path: "insert-register-request",
                component: InsertRegisterRequestComponent,
            },
            {
                canActivate: [AuthGuardGuard],
                path: "update-register-request/:id",
                component: InsertRegisterRequestComponent,
            },
            {
                canActivate: [AuthGuardGuard],
                path: "ListDrugAlmostFinishedComponent",
                component: ListDrugAlmostFinishedComponent,
            },
            {
             //   canActivate: [AuthGuardGuard],
                path: "ListHelp",
                component: ListHelpComponent,
            },
            {
               // canActivate: [AuthGuardGuard],
                path: "insert-Help",
                component: InsertHelpComponent,
            },
            {
                //canActivate: [AuthGuardGuard],
                path: "update-Help/:id",
                component: InsertHelpComponent,
            },
],
    },
];

@NgModule({
    declarations: [],
    imports: [RouterModule.forChild(routes)],
})
export class AdminRoutingModule { }
