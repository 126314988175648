export class CustomerPrescription {
    title: string = ''
    doseTypeId: number = null as any
    customerId: number = null as any
    notes: string = ''
    repDays:string=''
    customerPrescriptionDrugs: [
        {
            drugId: number,
            doseTimeId: number,
            count: number,
            startDate: number,
            days: number,
            drugItemCount: string
            quantityPerItem: number,
            doseQuantityId: number,
            pharmacistComment: string
            notification: boolean

        }
    ]
}
export class CustomerPrescriptionNormalDrug {
    customerId: number = null as any
    drugId: number
    doseTimeId: number
    count: number
    startDate: number
    days: number
    drugItemCount: string
    quantityPerItem: number
    doseQuantityId: number
    pharmacistComment: string
    notification: boolean
    otherDrug:string

}