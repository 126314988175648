<div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header" style="padding-bottom: 5px !important">
            <div>
              <h5 *ngIf="!update" class="pb-2"> اضافة قياس طبي للعميل</h5>
              <h5 *ngIf="update" class="pb-2"> تعديل قياس طبي للعميل</h5>

              <span> بالرجاء ملئ جميع الحقول </span>
            </div>
            <ng-template>
  
            </ng-template>
          </div>
          <div class="card-body">
            <form [formGroup]="insertForm" (ngSubmit)="Submit()">
  
              <div class="row m-0 mb-2">
             
                
                <div class="col-md-6">
                  <label for=""> تاريخ القياس
                  </label>
                  <div class="form-group">
                      <input type="datetime-local" class="form-control" formControlName="measurementDate"
                        [tooltip]="fc.measurementDate.errors?.required ? ' الوحدة مطلوب' : null" required
                        [isDisabled]="!((fc.measurementDate.errors?.required && fc.measurementDate.touched))" containerClass=""
                        [class.is-valid]="fc.measurementDate.valid && (fc.measurementDate.touched)"
                        [class.is-invalid]="fc.measurementDate.invalid && (fc.measurementDate.touched)"
                        [max]="minDate()" 
                        >
                    
                      </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>أختر القياس </label>
                    <ng-select class="js-example-basic-single" formControlName="medicalMeasurementId"
                      aria-label="Default select example" (change)="SelectMedicalMeasurement($event)" required="required"
                      [items]="MedicalMeasurement" bindLabel="title" bindValue="id"
                      [style.border]="fc.medicalMeasurementId.touched ? (fc.medicalMeasurementId.invalid ? '1px solid #dc3545 !important' : (fc.medicalMeasurementId.valid && fc.medicalMeasurementId.touched ? '1px solid #28a745' : 'none')) : 'none'"
                      >
                    </ng-select>
                  </div>
                </div>
              
                <!-- <div class="col-md-12"> -->
                <!-- <ng-container *ngFor="let value of Allinputs; let i = index">
  
                              <div class="col-md-3">
                                     <div > 
                                      <br>
                                  <label >{{label[i].for +' '+ label[i].value }} </label>
                                  <input   class="form-control" type="text"  [(ngModel)]="value.text"   name="value{{i}}.value" id="value{{i}}.for" [ngModelOptions]="{standalone: true}">                           
                            </div>
                          
                              </div>
                            </ng-container> -->
  
                <div class="col-md-6 text-center">
                  <div class="row" *ngIf="Inputs.length<2 &&Inputs.length!=0">

                    <div class="col-md-6">
                      <!-- <label> من</label> -->
                      <label class="GenderLable">القيمة</label>

                      <input class="form-control" type="number" [(ngModel)]="CustomerValue"  formControlName="CustomerValue"
                      [tooltip]="fc.CustomerValue.errors?.required ? ' الحقل مطلوب' : null" required
                      [isDisabled]="!((fc.CustomerValue.errors?.required && fc.CustomerValue.touched))" containerClass=""
                      [class.is-valid]="fc.CustomerValue.valid && (fc.CustomerValue.touched)"
                      [class.is-invalid]="fc.CustomerValue.invalid && (fc.CustomerValue.touched)"   name="y" id="y"
                        [ngModelOptions]="{standalone: true}">
                    </div>  
                  </div>
  
  
                  <div class="row" *ngIf="Inputs.length>1&&Inputs.length!=0">
                    <div class="row">
                      
                      <label class="GenderLable"  style="padding-top: 35px;">القيمة</label>

                  
                    <div class="col-md-4">
                      <div class="col-m-6">
                        <label> x </label>
                        <input class="form-control" type="number" [(ngModel)]="CustomerValueX" formControlName="CustomerValueX"
                        [tooltip]="fc.CustomerValueX.errors?.required ? ' الحقل مطلوب' : null" required
                        [isDisabled]="!((fc.CustomerValueX.errors?.required && fc.CustomerValueX.touched))" containerClass=""
                        [class.is-valid]="fc.CustomerValueX.valid && (fc.CustomerValueX.touched)"
                        [class.is-invalid]="fc.CustomerValueX.invalid && (fc.CustomerValueX.touched)"   name="y" id="y"
                          [ngModelOptions]="{standalone: true}">
  
  
  
                      </div>
                    </div>
                    <div class="col-md-1 ">
                      
                      <div class="col-m-6" style="padding-top: 29px;">
                        <label style="font-size:x-large" class="Color">/</label>
                     
                      </div>
                     
  
                    </div>
                   
                    <div class="col-md-4 ">
                      <div class="col-m-6">
                        <label>y</label>
  
                          <input class="form-control" type="number" [(ngModel)]="CustomerValueY" formControlName="CustomerValueY"
                          [tooltip]="fc.CustomerValueY.errors?.required ? ' الحقل مطلوب' : null" required
                          [isDisabled]="!((fc.CustomerValueY.errors?.required && fc.CustomerValueY.touched))" containerClass=""
                          [class.is-valid]="fc.CustomerValueY.valid && (fc.CustomerValueY.touched)"
                          [class.is-invalid]="fc.CustomerValueY.invalid && (fc.CustomerValueY.touched)"   name="y" id="y"
                            [ngModelOptions]="{standalone: true}">
  
  
  
                      </div>
  
                    </div>
                    
                    
                  </div>
  
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>تعليق </label>
                    <textarea class="js-example-basic-single" formControlName="Comment"
                    class="form-control"                  
                      >
                    </textarea>
                  </div>
                </div>
                <!-- </div> -->
                <br>
  
                <div class="col-12 col-md-12 mt-5">
                  <div *ngIf="update; else elseBlock">
                    <button [disabled]="disaple" class="btn btn-success pull-right" type="submit">
                      تعديل
                    </button>
                  </div>
  
                  <ng-template #elseBlock>
                    <button [disabled]="disaple" class="btn btn-success pull-right" type="submit">
                      إضافة
                    </button>
                  </ng-template>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>    