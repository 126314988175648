<div class="container-fluid">
    <div clas="row"></div>
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header" style="padding-bottom: 5px !important">
                    <div class="row">
                        <div class="col-6">
                            <h5 class="pb-2">قائمة ادوية لم يتم اعتمدها</h5>
                        </div>
                        <div class="col-6 header-titles">
                            <!-- <a routerLink="/content/admin/insert-customer" class="d-block">
                                <button appActionNew class="btn btn-primary pull-right" type="button">
                                    <span class="m-2">إدخال عميل</span>
                                    <i class="fa fa-plus"></i>
                                </button>
                            </a> -->
                        </div>
                    </div>
                </div>
               
                <div class="card-block row">
                    <div class="col-sm-12 col-lg-12 col-xl-12">
                        <div class="table-responsive">
                            <table class="table table-responsive-sm ">
                                <thead>
                                    <tr class=" " style="text-align: start !important;">
                                        <th scope="col"> </th>
                                        <th scope="col">الأسم</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of AllUnverifiedDrugsList;let x = index" class="h35 " style="text-align: start !important;">
                                        <td scope="row" class="p-2 trTableH">{{x + 1}}</td>
                                        <td class="p-2 trTableH" >{{item.title}}</td>
                                        </tr>
                                </tbody>
                            </table>
                            <div class="d-flex justify-content-center">
                                <pagination [boundaryLinks]="true" [maxSize]="20" [totalItems]="totalCount" previousText="&lsaquo;" nextText="&rsaquo;"
                                  firstText="&laquo;" lastText="&raquo;" [itemsPerPage]="pager.maxResultCount" [(ngModel)]="pageNumber"
                                  (pageChanged)="pageChanged($event)">
                                </pagination>
                              </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>




