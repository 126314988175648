import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RegisterRequestService } from '../../../../shared/API-Service/services/register-request.service';
import Swal from 'sweetalert2';
import {BaseComponent } from '../../../../shared/components/base/base.component'
import {LookupsService} from 'src/app/shared/API-Service/services/lookups.service'
@Component({
  selector: 'app-list-register-request',
  templateUrl: './list-register-request.component.html',
  styleUrls: ['./list-register-request.component.css']
})
export class ListRegisterRequestComponent extends BaseComponent implements OnInit {

  RegisterRequests:any
  RegisterRequestStatus: any;
  RegisterRequestStatusId: any;
  constructor(private LookupsService:LookupsService, private RegisterRequestService:RegisterRequestService,private _Router:Router) { super()}

  ngOnInit(): void {
    this.getRegisterRequests();
    this.GetRegisterRequestStatus();
    
  }
  GetRegisterRequestStatus(){
    this.LookupsService.GetRegisterRequestStatus().subscribe((res:any)=>{
      this.RegisterRequestStatus=res.data
    })
  }
  Search(){
    this.pageNumber=1;
    this.pager.registerRequestStatusId=this.RegisterRequestStatusId;
    this.getRegisterRequests()
  }
  pageChanged(event: any){
    this.pageNumber = event.page;// -1 * pageSize;
    this.pager.skipCount = (this.pageNumber - 1) * this.pager.maxResultCount;
    this.RegisterRequestService.GetPaged(this.pager).subscribe((res) => {
      this.RegisterRequests = res.data.items
      this.totalCount=res.data.totalCount;
    
    },(err)=>{
      console.log('their is a problem');
    },() =>{
      console.log('Completed');
    });

  }
  getRegisterRequests(){
    this.RegisterRequestService.GetPaged(this.pager).subscribe((res) => {
      this.RegisterRequests = res.data.items
      this.totalCount=res.data.totalCount;
    
    },(err)=>{
      console.log('their is a problem');
    },() =>{
      console.log('Completed');
    });
  }
  approved(registerRequestId){
    this._Router.navigate(['/content/admin/insert-customer',registerRequestId]);
  }
  Reject(registerRequestId){
    Swal.fire({
      title: 'هل تريد رفض الطلب ؟',
      text: "لن يكون لك صلاحية إعادته مره اخرى",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'الغاء',
      confirmButtonText: 'رفض الطلب !'
    }).then((result) => {
      if (result.isConfirmed) {
        this.RegisterRequestService.Reject(registerRequestId).subscribe((res) => {
          Swal.fire({
            icon: "success",
            title: "تم الرفض بنجاح",
            showConfirmButton: false,
            timer: 1500,
          });
       this.getRegisterRequests();
        },(err) => {
          Swal.fire({
            icon: 'error',
            title: 'خطأ',
            text:err.error.message    
          })
        },() => {
          console.log("completed");
        })
      }
    }) 
    }
  update(id){
    //this.PartnersService.Data.next(data);
    // this._Router.navigate(['/content/admin/insert-partner']);
    this._Router.navigate(['/content/admin/update-User',id]);
    }
  
    delete(id : number){
      Swal.fire({
        title: 'هل تريد مسح العنصر ؟',
        text: "لن يكون لك صلاحية إعادته مره اخرى",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'الغاء',
        confirmButtonText: 'امسح العنصر !'
      }).then((result) => {
        if (result.isConfirmed) {
          this.RegisterRequestService.Delete(id).subscribe((res) => {
            Swal.fire({
              icon: "success",
              title: "تم المسح بنجاح",
              showConfirmButton: false,
              timer: 1500,
            });
         this.getRegisterRequests();
          },(err) => {
            Swal.fire({
              icon: 'error',
              title: 'خطأ',
              text:err.error.message    
            })
          },() => {
            console.log("completed");
          })
        }
      }) 
    }

}
