import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MedicalMeasurementService } from 'src/app/shared/API-Service/services/medical-measurement.service';
import Swal from 'sweetalert2';
import {MedicalMeasurement} from "../../../../shared/Models/MedicalMeasurement";
import { LookupsService } from '../../../../shared/API-Service/services/lookups.service';
import { RightPanelKeyboardHelper } from '@devexpress/analytics-core/analytics-internal';
import { environment } from 'src/environments/environment.prod';
@Component({
  selector: 'app-insert-medical-measurement',
  templateUrl: './insert-medical-measurement.component.html',
  styleUrls: ['./insert-medical-measurement.component.css']
})
export class InsertMedicalMeasurementComponent implements OnInit {
  id: string;
  update: boolean;
  insertForm: FormGroup;
  MedicalMeasurementModal = new MedicalMeasurement();
  MedicalMeasurement:any;
  AllFormats: any;
  MedicalMeasurementFormdata = new FormData();
  Inputs: any[]=[]
  value:any;
  values:any[]=[];
  label: any;
  Allinputs: any[]=[];
  MaleFrom: any;
  MaleTo: any;
  MaleFromX: any;
  MaleFromY: any;

  MaleToY: any;
  MaleToX: any;

  FeMaleFrom: any;
  FeMaleTo: any;
  FeMaleFromX: any;
  FeMaleFromY: any;

  FeMaleToX: any;
  FeMaleToY: any;
  Image: any;
  imageLogo: string;
  Uploaded: boolean;
  viewPdf: boolean;
  disaple: boolean;

  constructor(private LookupsService :LookupsService,private MedicalMeasurementService:MedicalMeasurementService,private _formBuilder: FormBuilder,private router: Router,private route: ActivatedRoute) {
    this.GetAllFormats(); 
    this.initForm()
}
ngOnInit(): void {
  
  if (this.route.snapshot.paramMap.get("id")) {
    console.log(this.route.snapshot.paramMap.get("id"))
    this.id =this.route.snapshot.paramMap.get("id")
    this.MedicalMeasurementService.GetMedicalMeasurementById(this.id).subscribe(async (res:any)=>{
      console.log(res.data);
    this.MedicalMeasurement=  await res.data;
    this.imageLogo=environment.ServerImages_URL+'/'+this.MedicalMeasurement.image
    this.SelectAllFormats(this.MedicalMeasurement)
    this.initForm(this.MedicalMeasurement);
    const format = this.MedicalMeasurement.formatValue.split("/")
if(format.length>1){
  const femalfrom = this.MedicalMeasurement.femaleFromValue.split("/")
  this.FeMaleFromX=femalfrom[0];
  console.log(this.FeMaleFromX)
  this.insertForm.get("FeMaleFromX").setValue(this.FeMaleFromX)
  this.insertForm.get("FeMaleFromX").updateValueAndValidity()
  this.FeMaleFromY=femalfrom[1];
  console.log(this.FeMaleFromY)

  this.insertForm.get("FeMaleFromY").setValue(this.FeMaleFromY)
  this.insertForm.get("FeMaleFromY").updateValueAndValidity()

  const femalto = this.MedicalMeasurement.femaleToValue.split("/")
  this.FeMaleToX=femalto[0];
  this.insertForm.get("FeMaleToX").setValue(this.FeMaleToX)
  this.insertForm.get("FeMaleToX").updateValueAndValidity()

  this.FeMaleToY=femalto[1];
  this.insertForm.get("FeMaleToY").setValue(this.FeMaleToY)
  this.insertForm.get("FeMaleToY").updateValueAndValidity()


  const malefrom = this.MedicalMeasurement.maleFromValue.split("/")
  this.MaleFromX=malefrom[0];
  this.insertForm.get("MaleFromX").setValue(this.MaleFromX)
  this.insertForm.get("MaleFromX").updateValueAndValidity()


  this.MaleFromY=malefrom[1];
  this.insertForm.get("MaleFromY").setValue(this.MaleFromY)
  this.insertForm.get("MaleFromY").updateValueAndValidity()



  const maleto = this.MedicalMeasurement.maleToValue.split("/")
  this.MaleToX=maleto[0];
  this.insertForm.get("MaleToX").setValue(this.MaleToX)
  this.insertForm.get("MaleToX").updateValueAndValidity()


  this.MaleToY=maleto[1];
  this.insertForm.get("MaleToY").setValue(this.MaleToY)
  this.insertForm.get("MaleToY").updateValueAndValidity()
  console.log(this.insertForm.value)

}
else{
this.MaleFrom = this.MedicalMeasurement.maleFromValue;
this.insertForm.get("MaleFrom").setValue(this.MaleFrom)
this.insertForm.get("MaleFrom").updateValueAndValidity()

this.MaleTo=this.MedicalMeasurement.maleToValue;
this.insertForm.get("MaleTo").setValue(this.MaleTo)
this.insertForm.get("MaleTo").updateValueAndValidity()

this.FeMaleFrom =this.MedicalMeasurement.femaleFromValue;
this.insertForm.get("FeMaleFrom").setValue(this.FeMaleFrom)
this.insertForm.get("FeMaleFrom").updateValueAndValidity()

this.FeMaleTo =this.MedicalMeasurement.femaleToValue;
this.insertForm.get("FeMaleTo").setValue(this.FeMaleTo)
this.insertForm.get("FeMaleTo").updateValueAndValidity()


}





      
    })
    this.update = true;
  } else {
    this.initForm()
    this.update = false;
  }
}
GetAllFormats(){
  this.LookupsService.GetAllFormats().subscribe(res=>{
    this.AllFormats=res.data;
    console.log(this.AllFormats)
  })
}
SelectAllFormats(event){
  console.log(event,"EVEnt")
  if(event.value){
    this.Inputs = event.value.split("/")

  }
  else{
    this.Inputs = event.formatValue.split("/")

  }  
  console.log(this.Inputs);
   this.Allinputs=[];
   this.Inputs.forEach(element => {
    this.Allinputs.push({"value":element,"for":"للذكور من","text":"","type":"من"})
    this.Allinputs.push({"value":element,"for":"للذكور الى","text":"","type":"الى"})
    this.Allinputs.push({"value":element,"for":"للإناث من","text":"","type":"من"})
    this.Allinputs.push({"value":element,"for":"للإناث الى","text":"","type":"الى"})
   });
  // this.Inputs.push({value: ""});
  // this.values.push({value: ""});
   console.log(this.Allinputs)
   this.label=this.Allinputs


}
initForm(MedicalMeasurementModal?: MedicalMeasurement) {
  this.insertForm = this._formBuilder.group({
    title: [MedicalMeasurementModal?.title || '', Validators.required],
    formatId: [MedicalMeasurementModal?.formatId || '', Validators.required],
    unit: [MedicalMeasurementModal?.unit || '', Validators.required],
    image:[''],
    MaleFrom: [ ''],
    MaleTo: [ ''],
    MaleFromX: [  ''],
    MaleFromY: [ ''],
    MaleToY: [''],
    MaleToX: [ ''],
    FeMaleFrom: [ ''],
    FeMaleTo: [ ''],
    FeMaleFromX: [ ''],
    FeMaleFromY: [ ''],
    FeMaleToX: [ ''],
    FeMaleToY: [ '' ],

  });
}

get fc() {
  return this.insertForm.controls;
}
ValuesSet(){
if(this.Inputs.length>1){
  this.MaleFrom=this.insertForm.get('MaleFromX').value+'/'+this.insertForm.get('MaleFromY').value

  this.MaleTo=this.insertForm.get('MaleToX').value+'/'+this.insertForm.get('MaleToY').value;

  this.FeMaleFrom= this.insertForm.get('FeMaleFromX').value+'/'+this.insertForm.get('FeMaleFromY').value;

  this.FeMaleTo= this.insertForm.get('FeMaleToX').value+'/'+this.insertForm.get('FeMaleToY').value

  this.insertForm.get('MaleFromX').setValidators(Validators.required)
  this.insertForm.get('MaleFromY').setValidators(Validators.required)

  this.insertForm.get('MaleToX').setValidators(Validators.required)

  this.insertForm.get('MaleToY').setValidators(Validators.required)

  this.insertForm.get('FeMaleFromX').setValidators(Validators.required)

  this.insertForm.get('FeMaleFromY').setValidators(Validators.required)

  this.insertForm.get('FeMaleToX').setValidators(Validators.required)

  this.insertForm.get('FeMaleFromY').setValidators(Validators.required)


  this.insertForm.get('MaleFrom').clearValidators()
  this.insertForm.get('MaleFrom').updateValueAndValidity()

  this.insertForm.get('MaleTo').clearValidators()
  this.insertForm.get('MaleTo').updateValueAndValidity()

  this.insertForm.get('FeMaleFrom').clearValidators()
  this.insertForm.get('FeMaleFrom').updateValueAndValidity()

  this.insertForm.get('FeMaleTo').clearValidators()
  this.insertForm.get('FeMaleTo').updateValueAndValidity()

}
else{
  this.MaleFrom=this.insertForm.get('MaleFrom').value

  this.MaleTo=this.insertForm.get('MaleTo').value;

  this.FeMaleFrom= this.insertForm.get('FeMaleFrom').value;

  this.FeMaleTo= this.insertForm.get('FeMaleTo').value
 
  this.insertForm.get('MaleFrom').setValidators(Validators.required)

  this.insertForm.get('MaleTo').setValidators(Validators.required)

  this.insertForm.get('FeMaleFrom').setValidators(Validators.required)

  this.insertForm.get('FeMaleTo').setValidators(Validators.required)




  this.insertForm.get('MaleFromX').clearValidators()
  this.insertForm.get('MaleFromX').updateValueAndValidity()

  this.insertForm.get('MaleFromY').clearValidators()
  this.insertForm.get('MaleFromY').updateValueAndValidity()

  this.insertForm.get('MaleToX').clearValidators()
  this.insertForm.get('MaleToX').updateValueAndValidity()

  this.insertForm.get('MaleToY').clearValidators()
  this.insertForm.get('MaleToY').updateValueAndValidity()

  this.insertForm.get('FeMaleFromX').clearValidators()
  this.insertForm.get('FeMaleFromX').updateValueAndValidity()

  this.insertForm.get('FeMaleFromY').clearValidators()
  this.insertForm.get('FeMaleFromY').updateValueAndValidity()

  this.insertForm.get('FeMaleToX').clearValidators()
  this.insertForm.get('FeMaleToX').updateValueAndValidity()

  this.insertForm.get('FeMaleToY').clearValidators()
  this.insertForm.get('FeMaleToY').updateValueAndValidity()


}
}
Submit(){
  this.ValuesSet();
    const invalid = [];
    const controls = this.insertForm.controls;
    for (const name in controls) {
        if (controls[name].invalid) {
            invalid.push(name);
        }
    }
    console.log(invalid);
// const data={
//   "maleFromValue": this.MaleFrom,
//   "maleToValue": this.MaleTo,
//   "femaleFromValue": this.FeMaleFrom,
//   "femaleToValue": this.FeMaleTo
// }
// console.log(data);
if(!this.Image && !this.update){
  this.insertForm.get('image').setValidators(Validators.required);
  this.insertForm.get('image').updateValueAndValidity()
  Swal.fire({
    icon: "error",
    title:'من فضلك اختار الصورة'
    });
}
  if (this.insertForm.status == "VALID") {
    this.disaple=true

    if (this.update == true) {
      this.UpdateMedicalMeasurement();
    } else {
      this.InsertMedicalMeasurement();
    }
  }
   else {
    this.insertForm.markAllAsTouched();
  }


}
getLogoUrl(event: any) {
  const reader = new FileReader();
  if (event.target.files && event.target.files.length) {
    const [file] = event.target.files;
    this.Image = event.target.files[0];
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.imageLogo = reader.result as string;
        this.Uploaded=true
        if(this.Image.type=="application/pdf"){
          this.viewPdf=true;
        }
        else{
          this.viewPdf=false;
        }
      // this.logoForm?.append("image", this.Image);
    };
  }
}
UpdateMedicalMeasurement(){
  this.MedicalMeasurementFormdata.set("id", this.id);
  this.MedicalMeasurementFormdata.set("title", this.insertForm.get('title').value);
  this.MedicalMeasurementFormdata.set("formatId", this.insertForm.get('formatId').value);
  this.MedicalMeasurementFormdata.set("maleFromValue", this.MaleFrom);
  this.MedicalMeasurementFormdata.set("maleToValue", this.MaleTo);
  this.MedicalMeasurementFormdata.set("femaleFromValue", this.FeMaleFrom);
  this.MedicalMeasurementFormdata.set("femaleToValue", this.FeMaleTo);
  this.MedicalMeasurementFormdata.set("unit", this.insertForm.get('unit').value);
  this.MedicalMeasurementFormdata.set("MedicalMeasurementImage", this.Image);
  this.MedicalMeasurementService.Update(this.MedicalMeasurementFormdata).subscribe(
    (response) => {
    Swal.fire({
    icon: "success",
    title: "تم التعديل بنجاح",
    showConfirmButton: false,
    timer: 1500,
    });
    this.router.navigateByUrl("content/admin/ListMedicalMeasurement");
    this.disaple=false

  },
    (err) => {
      this.disaple=false
      Swal.fire({
        icon: "error",
        title:err.error.message
        });
    }

    );
}


InsertMedicalMeasurement(){   
  this.MedicalMeasurementFormdata.set("title", this.insertForm.get('title').value);
  this.MedicalMeasurementFormdata.set("formatId", this.insertForm.get('formatId').value);
  this.MedicalMeasurementFormdata.set("maleFromValue", this.MaleFrom);
  this.MedicalMeasurementFormdata.set("maleToValue", this.MaleTo);
  this.MedicalMeasurementFormdata.set("femaleFromValue", this.FeMaleFrom);
  this.MedicalMeasurementFormdata.set("femaleToValue", this.FeMaleTo);
  this.MedicalMeasurementFormdata.set("unit", this.insertForm.get('unit').value);
  this.MedicalMeasurementFormdata.set("MedicalMeasurementImage", this.Image);

     this.MedicalMeasurementService.Create(this.MedicalMeasurementFormdata).subscribe(
    (response) => {
    Swal.fire({
    icon: "success",
    title: "تم الحفظ بنجاح",
    showConfirmButton: false,
    timer: 1500,
    });
    this.router.navigateByUrl("content/admin/ListMedicalMeasurement");
    this.disaple=false

  },
    (err) => {
      this.disaple=false
      Swal.fire({
        icon: "error",
        title:err.error.message
        });
    }
          );
 

}

}
