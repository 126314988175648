import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
@Injectable({
  providedIn: 'root'
})
export class StatsDashboardService {
  constructor(private _HttpClient:HttpClient) { }
  GetStatistics():Observable<any>{
    return this._HttpClient.get(`${environment.Server_URL}/Dashboard/GetStatistics`);
   }

   GetGraph():Observable<any>{
    return this._HttpClient.get(`${environment.Server_URL}/Dashboard/GetGraph`);
   }
   TopNewCustomers():Observable<any>{
    return this._HttpClient.get(`${environment.Server_URL}/Dashboard/TopNewCustomers`);
   }
   InactiveCustomers(data):Observable<any>{
    return this._HttpClient.post(`${environment.Server_URL}/Dashboard/InactiveCustomers`,data);
   }

   TopNewCustomerMedicalMeasurements():Observable<any>{
    return this._HttpClient.get(`${environment.Server_URL}/Dashboard/TopNewCustomerMedicalMeasurements`);
   }

   GetCustomerMedicalMeasurementById(id):Observable<any>{
    return this._HttpClient.get(`${environment.Server_URL}/Dashboard/GetCustomerMedicalMeasurement?id=${id}`);
   }
   TopUnverifiedDrugs():Observable<any>{
    return this._HttpClient.get(`${environment.Server_URL}/Dashboard/TopUnverifiedDrugs`);
   }
   GetAllUnverifiedDrugs(data):Observable<any>{
    return this._HttpClient.post(`${environment.Server_URL}/Dashboard/GetAllUnverifiedDrugs`,data);
   }

   GetTopCustomerPrescription():Observable<any>{
    return this._HttpClient.get(`${environment.Server_URL}/Dashboard/GetTopCustomerPrescription`);
   }

   GetCustomerPrescriptionDetails(id):Observable<any>{
    return this._HttpClient.get(`${environment.Server_URL}/Dashboard/GetCustomerPrescriptionDetails?id=${id}`);
   }

   GetAddictiveDrugs():Observable<any>{
    return this._HttpClient.get(`${environment.Server_URL}/Dashboard/GetAddictiveDrugs`);
   }
   
   GetMedicalMeasurmentsList(data:any):Observable<any>{
    return this._HttpClient.post(`${environment.Server_URL}/Dashboard/GetMedicalMeasurmentsList`,data);
   }

   GetCustomersOverDistrict(data:any):Observable<any>{
    return this._HttpClient.post(`${environment.Server_URL}/Dashboard/GetCustomersOverDistrict`,data);
   }
   
   GetPrescriptionsOverDistrict(data:any):Observable<any>{
    return this._HttpClient.post(`${environment.Server_URL}/Dashboard/GetPrescriptionsOverDistrict`,data);
   }
   GetMeasurmentsOverDistrict(data:any):Observable<any>{
    return this._HttpClient.post(`${environment.Server_URL}/Dashboard/MeasurmentsOverDistrict`,data);
   }
   GetAddictiveDrugsOverDistrict(data:any):Observable<any>{
    return this._HttpClient.post(`${environment.Server_URL}/Dashboard/GetAddictiveDrugsOverDistrict`,data);
   }

   GetMedicalMeasurments(data:any):Observable<any>{
    return this._HttpClient.post(`${environment.Server_URL}/Dashboard/GetMedicalMeasurments`,data);
   }
}
