import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { VideoAlbumService } from 'src/app/shared/API-Service/services/video-album.service';
import Swal from 'sweetalert2';
import {Video} from "../../../../shared/Models/Video";
import { VideosService } from '../../../../shared/API-Service/services/videos.service';
import { DrugGroupsService } from '../../../../shared/API-Service/services/drug-groups.service';
import { DatePipe } from '@angular/common';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { AngularEditorValidateService } from 'src/app/shared/API-Service/services/angular-editor-validate.service';

@Component({
  selector: 'app-insert-video',
  templateUrl: './insert-video.component.html',
  styleUrls: ['./insert-video.component.css']
})
export class InsertVideoComponent implements OnInit {
  id: string;
  update: boolean;
  insertForm: FormGroup;
  VideoModal = new Video();
  VideoAlbums:any;
  dropdownSettings: any = {};
  Video: any;
  DrugGroups: any;
  selectedItems:any
  DrugGroupIds: any[]=[];
  DrugGroupsForEdit: any[]=[];
  disaple: boolean;

  editorConfig:AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    enableToolbar: true,
      showToolbar: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Cairo',
    fonts: [
      {class: 'cairo', name: 'Cairo'},
      {class: 'arial', name: 'Arial'},
      {class: 'times-new-roman', name: 'Times New Roman'},
      {class: 'calibri', name: 'Calibri'},
      {class: 'comic-sans-ms', name: 'Comic Sans MS'}
    ],
    toolbarHiddenButtons: [
      // ['bold']
      // ['toggleEditorMode']
      ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  }
  constructor(private AngularEditorValidateService:AngularEditorValidateService,public datepipe:DatePipe,private DrugGroupsService : DrugGroupsService,private VideosService :VideosService,private VideoAlbumService:VideoAlbumService,private _formBuilder: FormBuilder,private router: Router,private route: ActivatedRoute) { 
this.initForm()
this.dropdownSettings = {
  singleSelection: false,
  idField: 'id',
  textField: 'title',
  selectAllText: 'اختر الكل',
  unSelectAllText: 'الغاء اختر الكل',
  itemsShowLimit: 5,
  allowSearchFilter: true,
  maxHeight: 100
};
this.GetDrugGroups()
this.GetVideoAlbum();
  }

  ngOnInit(): void {
  
    if (this.route.snapshot.paramMap.get("id")) {
      this.id =this.route.snapshot.paramMap.get("id")
      this.VideosService.GetVideoById(this.id).subscribe(async (res:any)=>{
      this.Video=  await res.data;
      this.Video.date= this.datepipe.transform( this.Video.date, 'yyyy-MM-dd');
      this.Video?.drugGroupIds.forEach(element => {
        const DrugGroups=   this.DrugGroups.filter(x=>x.id==element)[0]
           this.DrugGroupsForEdit.push(DrugGroups)
         });
         this.selectedItems=this.DrugGroupsForEdit
        this.initForm(this.Video);
        
      })
      this.update = true;
    } else {
      this.initForm()
      this.update = false;
    }
  }
  GetVideoAlbum(){
    this.VideoAlbumService.Get().subscribe(res=>{
      this.VideoAlbums=res.data
    })
  }
  GetDrugGroups(){
    this.DrugGroupsService.Get().subscribe(async res=>{
      this.DrugGroups=await res.data
    })
  }
  noLeadingSpaceValidator(control: FormControl) {
    const value = control.value;
    // Check if the value starts with a space
    if (value && value.trimLeft() !== value) {
      return { leadingSpace: true };
    }
    return null;
  }
  initForm(Video?: Video) {
    this.insertForm = this._formBuilder.group({
      title: [Video?.title || '', [Validators.required,this.noLeadingSpaceValidator]],
      description: [Video?.description || '', Validators.required],
      videoUrl: [Video?.videoUrl || '', [Validators.required,Validators.pattern('^(https?|ftp)://[^\s/$.?#].[^\s]*$|^www.[^\s/$.?#].[^\s]*$')]],
      date: [Video?.date || '', Validators.required],
      videoAlbumId: [Video?.videoAlbumId || '', Validators.required],
      order: [Video?.order || '', Validators.required],
      drugGroupIds: [this.selectedItems || '', Validators.required],
    });
  }

  get fc() {
    return this.insertForm.controls;
  }

  Submit(){
    if(this.AngularEditorValidateService.AngularEditorValidate(this.insertForm.get('description'))){
      this.insertForm.get('description').setErrors({AngularEditorError:true})
      Swal.fire({
        icon: "warning",
        title:'يجب ادخال الوصف'
        });
    }
    if (this.insertForm.status == "VALID") {
      this.disaple=true

      if (this.update == true) {
        this.UpdateVideo();
      } else {
        this.InsertVideo();
      }
    }
     else {
      this.insertForm.markAllAsTouched();
    }
  

  }
  UpdateVideo(){
    this.DrugGroupIds=[]
    this.selectedItems.forEach(element => {
      this.DrugGroupIds.push(element.id);  
    }); 
    const data={
      "title": this.insertForm.get("title").value,
      "description": this.insertForm.get("description").value,
      "videoUrl": this.insertForm.get("videoUrl").value,
      "date": this.insertForm.get("date").value,
      "videoAlbumId": this.insertForm.get("videoAlbumId").value,
      "order": this.insertForm.get("order").value,
      "drugGroupIds": this.DrugGroupIds,
      "id":this.id
    }
    this.VideosService.Update(data).subscribe(
      (response) => {
      Swal.fire({
      icon: "success",
      title: "تم التعديل بنجاح",
      showConfirmButton: false,
      timer: 1500,
      });
      this.router.navigateByUrl("content/admin/ListVideo");
      this.disaple=false
    },
      (err) => {
        this.disaple=false
        Swal.fire({
          icon: "error",
          title:err.error.message
          });
      }
  
      );
  }
  InsertVideo(){
    this.DrugGroupIds=[]
    this.selectedItems.forEach(element => {
      this.DrugGroupIds.push(element.id);  
    }); 
    const data={
      "title": this.insertForm.get("title").value,
      "description": this.insertForm.get("description").value,
      "videoUrl": this.insertForm.get("videoUrl").value,
      "date": this.insertForm.get("date").value,
      "videoAlbumId": this.insertForm.get("videoAlbumId").value,
      "order": this.insertForm.get("order").value,
      "drugGroupIds": this.DrugGroupIds,
    }
    this.VideosService.Create(data).subscribe(
      (response) => {
      Swal.fire({
      icon: "success",
      title: "تم الحفظ بنجاح",
      showConfirmButton: false,
      timer: 1500,
      });
      this.router.navigateByUrl("content/admin/ListVideo");
      this.disaple=false
    },
      (err) => {
        this.disaple=false
        Swal.fire({
          icon: "error",
          title:err.error.message
          });
      }
            );
   
 
  }



}
