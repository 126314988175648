import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DistrictService } from '../../../../shared/API-Service/services/district.service';
import Swal from 'sweetalert2';
import {BaseComponent } from '../../../../shared/components/base/base.component'
@Component({
  selector: 'app-list-districts',
  templateUrl: './list-districts.component.html',
  styleUrls: ['./list-districts.component.css']
})
export class ListDistrictsComponent extends BaseComponent implements OnInit {
  Districts:any

  constructor(private DistrictService:DistrictService,private _Router:Router) { super()}
  ngOnInit(): void {
    this.getDistricts();
    
  }
  pageChanged(event: any){
    this.pageNumber = event.page;// -1 * pageSize;
    this.pager.skipCount = (this.pageNumber - 1) * this.pager.maxResultCount;
    this.DistrictService.GetPaged(this.pager).subscribe((res) => {
      this.Districts = res.data.items
      this.totalCount=res.data.totalCount;
    console.log(this.Districts);
    
    },(err)=>{
      console.log('their is a problem');
    },() =>{
      console.log('Completed');
    });

  }
  getDistricts(){
    const data={
      "maxResultCount": this.pager.maxResultCount,
      "skipCount": this.pager.skipCount,
    }
    this.DistrictService.GetPaged(data).subscribe((res) => {
      this.Districts = res.data.items
      this.totalCount=res.data.totalCount;
    console.log(this.Districts);
    
    },(err)=>{
      console.log('their is a problem');
    },() =>{
      console.log('Completed');
    });
  }
  update(id){
    console.log(id);
    //this.PartnersService.Data.next(data);
    // this._Router.navigate(['/content/admin/insert-partner']);
    this._Router.navigate(['/content/admin/update-district',id]);
    }

    delete(id : number){
      Swal.fire({
        title: 'هل تريد مسح العنصر ؟',
        text: "لن يكون لك صلاحية إعادته مره اخرى",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'الغاء',
        confirmButtonText: 'امسح العنصر !'
      }).then((result) => {
        if (result.isConfirmed) {
          this.DistrictService.Delete(id).subscribe((res) => {
            Swal.fire({
              icon: "success",
              title: "تم المسح بنجاح",
              showConfirmButton: false,
              timer: 1500,
            });
         this.getDistricts();
          },(err) => {
            Swal.fire({
              icon: 'error',
              title: 'خطأ',
              text:err.error.message    
            })
          },() => {
            console.log("completed");
          })
        }
      }) 
    }

}
